import React from 'react';
import { AdminHeader } from '../../components/molecules';
import { withRouter } from "react-router-dom";

export const AdminPanelLayout = withRouter((props) => {
   return (
      <>
         <AdminHeader />
         {props.children}
      </>
   )
});
