import styled from 'styled-components';
import { Row } from 'antd';
import { colGreen } from '../../constants/variables';
import { device } from '../../constants/device';

export const MainBookingBlock = styled(Row)`
   margin-top: 25px;
`;

export const BookingPriceBlock = styled.div`
   background: ${colGreen};
   color: #fff;
   margin: auto;
   text-align: center;

   @media ${device.laptopL} {
      margin: 0 15px 0 0;
   }

   span {
      display: inline-block;
      margin: 0 5px 0 0;

      @media ${device.laptopL} {
         display: none;
      }
   }
   
   .pl-price {
      margin: 0;
      padding: 15px;
      font-family: 'OdinRounded', sans-serif;
      font-size: 20px;
      line-height: 1;
      letter-spacing: 1px;
      display: inline-block;

      @media ${device.laptopL} {
         padding: 25px 20px;
         font-size: 40px;
         display: block;
         border-top: 2px solid #fff;
      }
   }

   .pl-time {
      padding: 15px;
      margin: 0;
      font-family: 'OdinRounded', sans-serif;
      font-size: 20px;
      line-height: 1;
      display: inline-block;

      @media ${device.laptopL} {
         padding: 20px;
         font-size: 18px;
         display: block;
      }
   }
`;