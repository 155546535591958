import styled from "styled-components";
import Slider from "react-slick";
import { Form, Row, DatePicker } from "antd";
import { device } from "../../constants/device";
import { colGreen } from '../../constants/variables';
import { Images } from "../../assets/images";

export const FormInput = styled(Form.Item)`
    font-family: 'PoppinsBold', sans-serif;
    font-size: 16px;
    height: 47px;
    margin: 10px 10px 20px !important;
    
    .ant-select-selection {
        border-color: ${colGreen};
        border-radius: 30px;
        height: 46px;
        padding-left: 62px !important;
        width: 346px !important;
    }
    
    .ant-select-selection__rendered {
        line-height: 44px;
        width: 346px !important;
    }
   
    .green-wrapper {
        position: absolute;
        left: 0;
        top: -11px;
        width: 50px;
        height: 46px;
        background-color: ${colGreen};
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    }  
      
    .ant-input-affix-wrapper {
        overflow: hidden;
        border-radius: 30px;
    }

    .ant-input {
        border-color: ${colGreen};
        border-radius: 30px;
        height: 46px;
        padding: 4px 11px 4px 62px !important;
    }

    .ant-input-prefix {
        background: ${colGreen};
        height: 46px;
        width: 50px;
        left: 0;

        .anticon {
            color: #333 !important;

            svg {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: 0;
                right: 0;
            }
        }
    }
`;

export const LogoItem = styled.span`
    background: url(${Images.Logo321}) no-repeat;
    display: block;
    height: 80px;
    width: 210px;
    background-size: contain;
    margin: 30px auto;
`;

export const LoginForm = styled(Form)`
    display: block;
    padding: 20px 0;
    border-radius: 15px;
    background: #fff;
    width: 80vw;
    text-align: center;
    margin: 0px auto;

    @media ${device.tablet} {
        padding: 20px 30px;    
    }

    @media ${device.laptop} {
        padding: 40px;
        width: 65vw;
        max-width: 900px;
    }

    .login-form-button {
        margin: 20px auto;
        min-width: 300px;
    }
`;

export const SlickSlider = styled(Slider)`
    .slick-arrow {
        background-color: #fff;
        height: 70px;
        width: 70px;

        @media ${device.laptop} {
            height: 100px;
            width: 100px;
        }
        
        &.slick-prev:before,
        &.slick-next:before {
            color: #76d9ff;
            font-family: 'PoppinsBold', sans-serif;
            font-size: 40px;
        }
        
        &.slick-prev {
            left: 0;
            border-radius: 0 50% 50% 0;
            z-index: 1;
            box-shadow: 0px 0px 30px 0px #f1f1f1;
        }
        
        &.slick-next {
            right: 0;
            border-radius: 50% 0 0 50% ;
            z-index: 1;
            box-shadow: 0px 0px 30px 0px #f1f1f1;
        }
    }
    
    .slick-dots {
        bottom: 40px;
        display: none !important;

        @media ${device.tablet} {
            display: block !important;
        }
    }
    
    .slick-dots li button:before {
        color: #e6e6e6;
        font-size: 20px;
        opacity: 1;
    }
    
    .slick-dots li.slick-active button:before {
        color: ${colGreen};
    }
`;

export const Testimonial = styled(Row)`
    text-align: center;
    margin: 50px auto 0;
    min-height: 350px;

    @media ${device.laptop} {
        margin: 100px auto 0;
        min-height: 450px;
    }
`;

export const TestimonialText = styled.p`
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 18px;
    width: 60%;
    margin: 15px auto 30px;
    line-height: 1.2;

    @media ${device.tablet} {
        font-size: 22px;
    }
`;

export const TestimonialUserInfo = styled.p`
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 14px;
    width: 70vw;
    max-width: 280px;
    min-width: 400px;
    margin: 0 auto 20px;
    line-height: 1.2;

    @media ${device.laptop} {
        font-size: 16px;
        width: 60%;
        max-width: 900px;
        margin: 0 auto 15px;
    }
    
    img {
        display: block !important;
        width: 55px;
        height: 55px;
        margin: 20px auto;

        @media ${device.laptop} {
            display: inline-block !important;
            width: 55px;
            height: 55px;
            margin-right: 30px;
        }
    }
`;

export const SliderContainer = styled.div`
    width: 100%;
`;

export const ModalButton = styled.button`
   padding: 10px 20px;
   background-color: ${colGreen};
   border: none;
   border-radius: 30px;
`;

export const ModalReturnButton = styled.button`
   padding: 10px 20px;
   background-color: #f36371;
   border: none;
   border-radius: 30px;
`;

export const DatePickerAnt = styled(DatePicker)`
    position: relative;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 50px;
        border-radius: 50% 0 0 50%;
        background-color: ${colGreen};
    }

    .ant-calendar-picker-icon {
        position: absolute;
        left: 18px;
        top: 50%;
        color: #333;
    }
`;