import React, {useCallback, useEffect} from "react";
import {Link, withRouter} from "react-router-dom";
import {useDispatch} from "redux-react-hook";


const VerifyPage = withRouter(({match, history}) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'VERIFY',
      payload: {token: match.params.token}
    })
  }, [dispatch,  match.params.token]);

  const goToLogin = useCallback(() => {
    history.push('/login')
  }, [history]);

  return (
    <div>
      <Link
        to={'/'}
      >
        <img
          style={{
            display: 'block',
            margin: '0 auto'
          }}
          src="/static/media/logo.24cddbe7.png"
          alt="logo"
        />

      </Link>
      <p style={{
        fontWeigh: 'bold',
        fontSize: 32,
        textAlign: 'center',
        color: '#383838',
        marginTop: 40
      }}>
        Thanks for verify you account
      </p>
      <button
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          border: 'none',
          borderRadius: 40,
          padding: '10px 20px',
          backgroundColor: '#b3e830'
        }}
        onClick={goToLogin}
      >
        Go to login Page
      </button>
    </div>
  )
});

export default VerifyPage
