import styled from "styled-components";
import { CheckboxButton } from '../../atoms/';
import { device } from '../../../constants/device';
// import { Button } from "../../atoms";

export const Subtitle = styled.h3`
  font-family: 'PoppinsRegular',sans-serif;
  text-align: center;
  font-size: 20px;
  padding: 5px 15px;
  margin: 0;

  @media ${device.tablet} {
    padding: 5px 25px;
  }
`;

export const CheckboxButtonCustom = styled(CheckboxButton)`
  width: 100%;

  @media ${device.tablet} {
    width: 50%;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const RoleButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  width: 100%;
  margin: 10px auto;

  @media ${device.laptop} {
    width: 60%;
  }
`;

// export const RoleButton = styled(Button)`
//   // padding: 10px 30px;
//   // background-color: #b3e830;
//   // border: none;
//   // border-radius: 30px;
//   // width: 120px;
//   // color: #fff;
//   // font-size: 12px;
//   // text-align: center;
//   // cursor: pointer;
//   margin: 0;
//   min-height: 0 !important;
// `;

export const RoleText = styled.p`
   font-family: 'PoppinsRegular',sans-serif;
   display: block;
   text-align: center;
   font-size: 16px;
   padding: 10px 15px;

   @media ${device.tablet} {
    padding: 15px 25px;
  }
`;
