import styled from "styled-components";
import { Row, Col } from "antd";
import { device } from "../../../../constants/device";
import { Images } from "../../../../assets/images";
import { colGreen } from '../../../../constants/variables';
import { ButtonLink } from "../../../atoms";

export const RowAnt = styled(Row)`
    @media ${device.laptop} {
        max-width: 1200px;
        margin: auto;
        width: 100%;
        position: relative;
    }
`;

export const BlockTitle = styled.h2`
  font-family: "OdinRounded", sans-serif;
  font-size: 26px;
  color: ${colGreen};
  line-height: 1;
  margin: 40px auto;

  @media ${device.laptop} {
    font-size: 60px;
    margin: 80px auto;
  }

  svg {
    margin-right: 40px;
  }
`;

export const SingleBlockSmall = styled(Col)`
  height: 400px;
  background: url(${Images.Paris}) no-repeat;
  background-size: cover;
  display: inline-block;
  width: 40%;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;

  @media ${device.laptopL} {
    position: absolute;
    top: -50px;
    left: -50px;
    width: 480px;
    height: 400px;
  }
`;

export const SingleBlockFullWidth = styled(Col)`
  height: 400px;
  background: url(${Images.Kyiv}) no-repeat;
  background-size: cover;
  display: inline-block;
  width: 100%;
  background-position: center;

  @media ${device.laptopL} {
    height: 400px;
    width: 100%;
  }
`;

export const LeftBlock = styled(Col)`
  height: 400px;
  background: url(${Images.Berlin}) no-repeat;
  background-size: cover;
  display: inline-block;
  width: 40%;
  background-position: center;

  @media ${device.laptopL} {
    width: 40%;
    height: 400px;
  }
`;

export const RightBlock = styled(Col)`
  height: 800px;
  background: url(${Images.London}) no-repeat;
  background-size: cover;
  display: inline-block;
  min-height: 300px;
  width: 60%;
  background-position: center;

  @media ${device.laptopL} {
    min-height: 300px;
    width: 60%;
    height: 800px;
  }
`;

export const FirstBlock = styled.div`
  background-color: #76d9ff;
  color: #fff;
  padding: 40px 30px;
  text-align: left;

  @media ${device.tablet} {
    padding: 80px 70px 22px;
  }
`;

export const Message = styled.p`
  font-family: "PoppinsBold", sans-serif;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 15px;

  @media ${device.tablet} {
    font-size: 26px;
    margin-bottom: 30px;
  }
`;

export const SubMessage = styled.p`
  font-family: "PoppinsRegular", sans-serif;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 40px;
  letter-spacing: 0.5px;

  @media ${device.tablet} {
    font-size: 18px;
    margin-bottom: 65px;
  }
`;

export const City = styled.p`
  font-family: "PoppinsBold", sans-serif;
  font-size: 18px;
  line-height: 1;
  color: ${props => props.color || "#333"};
  padding: 30px 20px;
  margin-bottom: 0;
  position: ${props => props.position || "relative"};
  right: 0;
  bottom: 0;

  @media ${device.tablet} {
    font-size: 26px;
    padding: 50px 40px;
  }
`;

// TODO start  need to ask Nikita почему-то не отрабатывают стили
export const PromoteMusicButton = styled(ButtonLink)`
  display: none;
  font-size: 16px;

  // @media ${device.tablet} {
  //   display: block;
  // }
`;

export const PromoteMusicButtonSecond = styled(ButtonLink)`
  @media ${device.tabletMax} {
    margin: 20px auto;
    display: none;
  }
`;

// TODO end 