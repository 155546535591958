import styled from "styled-components";
import { Row, Select } from 'antd';

export const RowAnt = styled(Row)`
`;

export const SelectAnt = styled(Select)`
    outline: none;

    .ant-select-selection,
    .ant-select-selection:focus {
        border: none;
        box-shadow: none;
    }
`;
