import * as React from "react";
import {useDispatch, useMappedState} from "redux-react-hook";

const useAuth = (history, noRedirect) => {
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();
  const auth = useMappedState((state) => (state.auth));

  const redirect = React.useCallback(
    (s) => {
      !noRedirect && history.push('/login');
    },
    [history, noRedirect]
  );

  if(!auth.user) {
    if(noRedirect) {
      localStorage.setItem('no-auth-redirect', 'true');
    }
    dispatch({
      type: 'GET_CURRENT_USER',
      payload: {
        token,
        redirect
      }
    });
  }
  return auth;
};
export default useAuth;
