import styled from 'styled-components';
import { device } from "../../../../constants/device";

export const MessageAreaRow = styled.div`
    display: flex;
    width: 75%;
    background-color: #fff;
    flex-direction: column;
    justify-content: space-between;
    height: 75vh,
`;

export const MessageInput = styled.div`
    height: auto;
    display: flex;
    padding: 20px;
    justify-content: space-between;
    background-color: #fff;
    border-top: 1px solid rgb(245, 245, 245);
    padding-right: 50px;
    padding-legt: 30px;
    @media ${device.tablet} {
        padding: 20px;
    }
`;

export const Messages = styled.div`
    overflow-y: scroll;
    height: 75vh;
`;
