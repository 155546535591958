import React from "react";
import {TeacherNavigation} from './TeacherNavigation';
import {StudentNavigation} from './StudentNavigation';
import {useMappedState} from "redux-react-hook";

export const Navigation = (props) => {
  const user = useMappedState(state => state.auth).user;

  return (
    user.role && user.role.role === 'teacher' ?
      <TeacherNavigation pageName={props.pageName}/> :
      <StudentNavigation pageName={props.pageName} />
  );
};
