import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "redux-react-hook";
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import {message} from "antd";

const useRegisterPage = (form, history) => {
  const [user, setUser] = React.useState({});
  const [email, setEmail] = React.useState();
  const [isShowModal, ToggleModal] = React.useState(false);
  const { t } = useTranslation();
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const {getFieldDecorator} = form;
  const dispatch = useDispatch();
  const [birthday, setBirthday] = useState(moment('01/01/2000'));

  const onBirthdayChange = useCallback((value) => {
    setBirthday(value);
    moment(value).fromNow()
  }, []);

  const validateRole = useCallback(() => {
    if(!user.role) {
      message.error(t('registration_form_text9'));
      return false;
    }

    return true;
  }, [user.role, t])

  const submitForm = useCallback((e) => {
    e.preventDefault();

    const birthDate = moment(birthday);
    const currentDate = moment();
    const yearsOld = currentDate.diff(birthDate, 'years');

    if (yearsOld < 18) {
      message.error(t('registration_form_text10'));
    } else {
      if(!user.role) {
        message.error(t('registration_form_text9'));
        return;
      }
      
      const User = form.getFieldsValue();
      delete User['confirm-password'];
      delete User['social-email'];
      User.role = user.role;
      User.age = birthday.toDate().toDateString();
      dispatch({
        type: 'REGISTER_USER',
        payload: {
          data: User,
          redirect: history.push
        }
      });

    }
  }, [dispatch, history, user.role, form, birthday, t]);
  const responseGoogle = (response) => {
    setUser({
      role: user.role,
      first_name: response.profileObj.givenName,
      last_name: response.profileObj.familyName,
      email: response.profileObj.email,
      token: response.accessToken,
      source: 'google'
    });
  };

  const responseFacebook = (response) => {
    if(!response.name) {
      message.error(t('registration_form_text11'));
      return;
    }

    setUser({
      role: user.role,
      first_name: response.name.split(' ')[0],
      last_name: response.name.split(' ')[1] || response.name.split(' ')[0],
      email: response.email || '',
      token: response.accessToken,
      facebook_id: response.id,
      source: 'facebook'
    });
    if (!response.email) {
      Toggle()
    }
  };


  useEffect(() => {
    console.log(user);
  }, [user]);

  const Toggle = useCallback(() => {
    ToggleModal(!isShowModal)
  }, [isShowModal]);

  const handleChangeEmail = useCallback(() => {
    setUser({...user, ...{email}});
    Toggle();
  }, [email, user, Toggle]);

  const handleCancel = () => {
    setUser({role: user.role});
    Toggle();
  };

  const handleChooseRole = (role)=> {
    setUser({role: role});
  };

  useEffect(() => {
    if (user.email && user.role) {
      dispatch({
        type: 'REGISTER_BY_SOCIAL',
        payload: {
          data: user,
          redirect: history.push
        }
      })
    }
  }, [user, dispatch, history]);

  const changeUserEmail = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  return {
    submitForm,
    responseGoogle,
    responseFacebook,
    user,
    getFieldDecorator,
    sliderSettings,
    handleChooseRole,
    handleChangeEmail,
    handleCancel,
    isShowModal,
    Toggle,
    changeUserEmail,
    email,
    birthday,
    onBirthdayChange,
    validateRole
  }
};

export default useRegisterPage;
