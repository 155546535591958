import React from "react";
import {
  UserMessageBlock,
  Row,
  UserPhoto,
  UserName,
  MessageWrapper,
  MessageDate,
  SecondRow
} from "./style";
import moment from "moment";

export const Message = ({message, user}) => {
  return (
    <UserMessageBlock style={{
      backgroundColor: message.unread && message.sender._id !== user? '#ececec' : 'white'
    }}>
      <Row>
        <UserPhoto src={process.env.REACT_APP_PUBLIC_FILE_URL + '/' + message.sender._id + '/'+ message.sender.avatar} alt="" />
      </Row>
      <SecondRow>
        <UserName>
          {message.sender.first_name + ' ' + message.sender.last_name}
        </UserName>
        <MessageWrapper>
          {message.message}
          <MessageDate>
            { moment().diff(moment(message.createdAt), 'days') <= 2 ?  moment(message.createdAt).fromNow() : moment(message.createdAt).format('lll')}
          </MessageDate>
        </MessageWrapper>
      </SecondRow>
    </UserMessageBlock>
  )
}
