import { all, takeLatest, put } from 'redux-saga/effects'
import service from '../../services/schedule.service';
import lessonService from '../../services/lesson.service';
import notify from "../../utilities/Notify";

function* getSchedule({payload}) {

  try {
    const schedule = yield service.getSchedule(payload);
    yield put({
      type: 'SET_SCHEDULE',
      payload: schedule
    })
  } catch (e) {
    notify.error(e)
  }

}

function* createScheduleItem({payload}) {

  try {
    const schedule = yield service.createSchedule(payload);
    yield put({
      type: 'ADD_NEW_SCHEDULE_ITEM',
      payload: schedule
    })
  } catch (e) {
    notify.error(e)
  }

}

function* createScheduleList({payload}) {

  try {
    const schedules = yield service.createScheduleList(payload);
    yield put({
      type: 'ADD_NEW_SCHEDULE_LIST',
      payload: schedules
    })
  } catch (e) {
    notify.error(e)
  }

}

function* removeScheduleItem({payload}) {

  try {
    yield service.removeScheduleItem(payload);
    yield put({
      type: 'REMOVE_SCHEDULE',
      payload: payload
    })
  } catch (e) {
    notify.error(e)
  }

}

function* cancelScheduleItem({payload}) {

  try {
    yield lessonService.cancelLessonItem(payload);
    yield put({
      type: 'REMOVE_SCHEDULE',
      payload: payload
    })
  } catch (e) {
    notify.error(e)
  }

}

function* updateScheduleItem({payload}) {

  try {
    const schedule = yield service.updateSchedule(payload, payload._id);
    yield put({
      type: 'UPDATE_SCHEDULE',
      payload: schedule
    })
  } catch (e) {
    notify.error(e)
  }

}

function* getFilteredSchedule({payload}) {
  console.log(payload);
  try {
    const schedule = yield service.getFilteredSchedule(payload);
    yield put({
      type: 'SET_SCHEDULE',
      payload: schedule
    })
  } catch (e) {
    notify.error(e)
  }

}

function* fetchDataWatcher() {
  yield takeLatest('GET_SCHEDULE', getSchedule);
  yield takeLatest('CREATE_SCHEDULE_ITEM', createScheduleItem);
  yield takeLatest('CREATE_SCHEDULE_LIST', createScheduleList);
  yield takeLatest('REMOVE_SCHEDULE_ITEM', removeScheduleItem);
  yield takeLatest('UPDATE_SCHEDULE_ITEM', updateScheduleItem);
  yield takeLatest('GET_FILTERED_SCHEDULE', getFilteredSchedule);
  yield takeLatest('CANCEL_LESSON_ITEM', cancelScheduleItem);
}

export function* scheduleSaga() {
  yield all([fetchDataWatcher()])
}
