import { all, takeEvery, put } from 'redux-saga/effects'
import lessonService from '../../services/lesson.service'
import notify from "../../utilities/Notify";

function* getAll() {
  try {
    const lessons = yield lessonService.getStudentLessons();
    yield put({
      type: 'SET_LESSONS',
      payload: lessons
    })
  } catch(e) {
    notify.error(e)
  }
}

function* fetchDataWatcher() {
  yield takeEvery('GET_STUDENT_LESSONS', getAll);
}

export function* lessonsSaga() {
  yield all([fetchDataWatcher()])
}
