import styled from 'styled-components';
import { device } from '../../../../constants/device';

// export const SideContent = styled.div`
//     width: 100%;
//     padding-top: 50px;
//     display: block;

//     @media ${device.tablet} {
//         width: 30vw;
//         display: inline-block;
//         vertical-align: top;
//         padding-top: 200px;
//     }

//     @media ${device.laptop} {
//         width: 25vw;
//     }

//     @media ${device.laptopL} {
//         width: 405px;
//         padding-top: 530px;
//         padding-right: 115px;
//     }
// `;

export const SideContent = styled.div`
    width: 100%;
    display: block;
    vertical-align: top;
    padding: 10px;

    @media ${device.tablet} {
        display: inline-block;
        width: 405px;
        padding-top: 530px;
    }

    @media ${device.laptop} {
        padding-right: 115px;
    }
`;