import React, {useCallback, useEffect} from "react";
import {
  Container,
} from "../../components";
import {
  SideContent,
  CentralContent,
  CalendarRequests
} from "./style";
import { TeacherRequestBlock } from '../../components/molecules/RequestsComponents';
import {useDispatch, useMappedState} from "redux-react-hook";
import moment from "moment";
import { useTranslation } from 'react-i18next';

export function TeachingRequestsPage() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'GET_REQUESTS_TO'
    });
  }, [dispatch]);

  const requests = useMappedState(state => state.request);

  function onChange(value, mode) {
    console.log(value, mode);
  }

  const onAccept = useCallback((_id) => {
    dispatch({
      type: 'ACCEPT_REQUEST',
      payload: _id
    })
  }, [dispatch]);

  const onDecline = useCallback((_id) => {
    dispatch({
      type: 'DECLINE_REQUEST',
      payload: _id
    })
  }, [dispatch]);

  return (

      <Container>
        <SideContent>
            <CalendarRequests onPanelChange={() => onChange()} data={requests} />
        </SideContent>
        <CentralContent>
          {
            // eslint-disable-next-line
            requests && requests.length > 0 ? requests.map((item) => {
              if (item && item._id) {
                return (
                  <TeacherRequestBlock
                    _id={item._id}
                    studentPhoto={process.env.REACT_APP_PUBLIC_FILE_URL + '/' + item.user._id + '/' + item.user.avatar}
                    studentname={item.user.first_name + item.user.last_name}
                    instrument={item.instrument.name}
                    lessonplace={item.class_type.type}
                    user_id={item.user._id}
                    datetime={moment(item.start_time).format('LLL')}
                    studentlanguage="Student Language"
                    onAccept={onAccept}
                    onDecline={onDecline}
                    key={item._id}
                  />
                )
              }
            }):
            <p style={{
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: 18
            }}>
              {t("request_page_no_request")}
            </p>
          }

        </CentralContent>
      </Container>
  );
}
