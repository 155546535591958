import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Icon } from 'antd';
import { Container, MainMenu, MainSearch } from '../..';
import {
  CONTACTS_ROUTE,
  DASHBOARD_ROUTE,
  FAQ_ROUTE,
  ROOT_ROUTE,
} from '../../../constants/routes';
import { Images } from '../../../assets/images';
import {
  Wrapper,
  HotLink,
  LinkMenu,
  Logo,
  SearchLogo,
  MenuButton,
  UserMenu,
} from './style';
import { LanguageSwitcher } from '../index';

import { ReactComponent as UserSvg } from '../../../assets/svg/user.svg';

export function Header(props) {
  const { t } = useTranslation();
  const [isShowMenu, toggleMenu] = useState(false);
  const { isShowSearch, toggleSearch } = props;

  function hideSearch() {
    toggleMenu(false);
  }

  return (
    <>
      {isShowMenu ? (
        <MainMenu
          isShowMenu={isShowMenu}
          toogleMenu={toggleMenu}
          hideSearch={hideSearch}
        />
      ) : (
        ''
      )}
      <Wrapper id="header">
        <Container>
          <Row type="flex" justify="end" className="pl-mb-3 pl-mt-05">
            <Col xs={24} md={10}>
              <HotLink>
                <LinkMenu to={FAQ_ROUTE}>{t('menu_faq')}</LinkMenu>
                <LinkMenu to={CONTACTS_ROUTE}>{t('menu_contact')}</LinkMenu>
                <LanguageSwitcher />
              </HotLink>
            </Col>
          </Row>
          <Row type="flex" justify="space-between" className="pl-pb-35">
            <Logo to={ROOT_ROUTE}>
              <img src={Images.Logo321} alt="logo" />
            </Logo>
            <SearchLogo onClick={() => toggleSearch(!isShowSearch)}>
              <Icon type="search" />
            </SearchLogo>
            <Col md={5}>
              <Row type="flex" align="middle" justify="end">
                <div className="menu">
                  <MenuButton
                    onClick={() => toggleMenu(!isShowMenu)}
                    className={
                      isShowMenu
                        ? 'hamburger hamburger--slider is-active'
                        : 'hamburger hamburger--slider'
                    }
                  >
                    <span className="hamburger-box">
                      <span className="hamburger-inner"></span>
                    </span>
                  </MenuButton>
                </div>
                <UserMenu to={DASHBOARD_ROUTE}>
                  <UserSvg />
                </UserMenu>
              </Row>
            </Col>
          </Row>
        </Container>
        <div
          className={isShowSearch ? 'show-search' : 'show-search hide-search'}
          
        >
          <MainSearch history={props.history} toogleSearch={toggleSearch}   />
          <div onClick={toggleSearch} className = {isShowSearch && 'hide'}></div>
        </div>
      </Wrapper>
    </>
  );
}
