import React, {useCallback, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {Input, Col, Row, Tooltip, Select} from "antd";
import {
  Container,
  Button as ButtonCore
} from "../../components";
import {
  UserPhoto,
  SideContent,
  CentralContent,
  TeacherSmallInfo,
  TeacherName,
  EditItems,
  ItemTitle,
  TeacherRow,
  IconAnt
} from "./style";
import { UploadAvatar } from "../../components/molecules/EditProfilePageComponents";
import {useMappedState} from "redux-react-hook";

const {Option} = Select;

const EditTeacherPage = ({onSaveChanges}) => {
  const { t } = useTranslation();
  const { TextArea } = Input;

  const {user, instruments, class_types, proficiencies, languages} = useMappedState(state => state);

  const [userData, setUserData] = useState({});

  useEffect(() => {
    setUserData({
      _id: user._id,
      first_name: user.first_name,
      last_name: user.last_name,
      metadata: {
        hour_rate: user.hour_rate,
        instruments: user.instruments.map((item) => item._id),
        languages: user.languages.map((item) => item._id),
        pupil_proficiencies: user.pupil_proficiencies,
        class_types: user.class_types.map((item) => item._id),
        proficiency_id: user.proficiency_id,
        about: user.about,
        prices: user.prices,
        title: user.title,
        philosophy: user.philosophy
      }
    })
  }, [user]);

  const onChange = useCallback((field, value, meta=false) => {
    if (meta) {
      setUserData({...userData, metadata: {...userData.metadata, [field]: value}})
    } else {
      setUserData({...userData, [field]: value})
    }
  },[userData]);

  return (
    <TeacherRow type="flex" justify="center" className="pl-pt-6">
      <Container>
        <UserPhoto>
          <div className="user-photo">
            <img src={process.env.REACT_APP_PUBLIC_FILE_URL + '/' + user._id + '/' + user.avatar} alt={user.first_name + 'avatar'}/>
          </div>
        </UserPhoto>
        <SideContent />
        <CentralContent>
          <TeacherSmallInfo type="flex">
            <TeacherName>{userData.first_name + ' ' + userData.last_name}</TeacherName>
          </TeacherSmallInfo>
          {userData && Object.keys(userData).length > 0 &&
            <form>
              <EditItems>
                <ItemTitle>{t("edit_text1")}</ItemTitle>
                <UploadAvatar/>
              </EditItems>
              <EditItems>
                <ItemTitle>{t("edit_text2")}</ItemTitle>
                <Row gutter={8}>
                  <Col span={12}>
                    <Input
                      placeholder="You Name"
                      onChange={(value) => onChange('first_name', value.target.value)}
                      value={userData.first_name}
                    />
                  </Col>
                  <Col span={12}>
                    <Input
                      placeholder="You Surname"
                      value={userData.last_name}
                      onChange={(value) => onChange('last_name', value.target.value)}
                    />
                  </Col>
                </Row>
              </EditItems>
              <EditItems>
                <ItemTitle>{t("edit_text7")}<Tooltip title={t("edit_text8")}><IconAnt
                  type="question-circle"/></Tooltip></ItemTitle>
                <Row gutter={8}>
                  <Col span={5}>
                    <Input
                      placeholder="Lesson price"
                      type="number"
                      value={userData.metadata.hour_rate}
                      onChange={(value) => onChange('hour_rate', value.target.value, true)}
                    />
                  </Col>
                </Row>
              </EditItems>
              <EditItems>
                <ItemTitle>{t("edit_text9")}</ItemTitle>
                <TextArea
                  placeholder={t("edit_text9")}
                  rows={4}
                  value={userData.metadata.about}
                  onChange={(value) => onChange('about', value.target.value, true)}
                />
              </EditItems>
              <EditItems>
                <ItemTitle>{t("edit_text10")}</ItemTitle>
                <TextArea
                  placeholder={t("edit_text10")}
                  rows={4}
                  value={userData.metadata.philosophy}
                  onChange={(value) => onChange('philosophy', value.target.value, true)}

                />
              </EditItems>
              <EditItems>
                <ItemTitle>{t("edit_text11")}</ItemTitle>
                <TextArea
                  placeholder={t("edit_text11")}
                  rows={4}
                  value={userData.metadata.prices}
                  onChange={(value) => onChange('prices', value.target.value, true)}
                />
              </EditItems>
              <EditItems>
                <ItemTitle>{t("edit_text4")}</ItemTitle>
                <Col span={24}>
                  <Select
                    mode="multiple"
                    placeholder={'Choose instruments'}
                    value={userData.metadata.instruments}
                    onChange={(value) => onChange('instruments', value, true)}
                  >
                    {
                      instruments && instruments.length > 0 && instruments.map((item) => {
                        return (
                          <Option
                            value={item._id}
                            key={item._id}
                          >{item.name}</Option>
                        )
                      })
                    }
                  </Select>
                </Col>
              </EditItems>
              <EditItems>
                <ItemTitle>{t("edit_text6")}</ItemTitle>
                <Col span={24}>
                  <Select
                    mode="multiple"
                    placeholder={'Choose type of educations'}
                    value={userData.metadata.class_types}
                    onChange={(value) => onChange('class_types', value, true)}
                  >
                    {
                      class_types && class_types.length > 0 && class_types.map((item) => {
                        return (
                          <Option
                            value={item._id}
                            key={item._id}
                          >{item.type}</Option>
                        )
                      })
                    }
                  </Select>
                </Col>
              </EditItems>
              <EditItems>
                <ItemTitle>{t("edit_text5")}</ItemTitle>
                <Col span={24}>
                  <Select
                    mode="multiple"
                    placeholder={'Choose languages'}
                    value={userData.metadata.languages}
                    onChange={(value) => onChange('languages', value, true)}
                  >
                    {
                      languages && languages.length > 0 && languages.map((item) => {
                        return (
                          <Option
                            value={item._id}
                            key={item._id}
                          >{item.language}</Option>
                        )
                      })
                    }
                  </Select>
                </Col>
              </EditItems>
              <EditItems>
                <ItemTitle>{t("edit_text17")}</ItemTitle>
                <Col span={24}>
                  <Select
                    placeholder={'Choose your skill'}
                    value={userData.metadata.proficiency_id}
                    style={{
                      width: '100%'
                    }}
                    onChange={(value) => onChange('proficiency_id', value, true)}
                  >
                    {
                      proficiencies && proficiencies.length > 0 && proficiencies.map((item) => {
                        return (
                          <Option
                            value={item._id}
                            key={item._id}
                          >
                            {item.level}
                          </Option>
                        )
                      })
                    }
                  </Select>
                </Col>
              </EditItems>
              <EditItems>
                <ItemTitle>Students levels</ItemTitle>
                <Col span={24}>
                  <Select
                    placeholder={'Choose your skill'}
                    mode="multiple"
                    style={{
                      width: '100%'
                    }}
                    value={userData.metadata.pupil_proficiencies}
                    onChange={(value) => onChange('pupil_proficiencies', value, true)}
                  >
                    {
                      proficiencies && proficiencies.length > 0 && proficiencies.map((item) => {
                        return (
                          <Option
                            value={item._id}
                            key={item._id}
                          >
                            {item.level}
                          </Option>
                        )
                      })
                    }
                  </Select>
                </Col>
              </EditItems>
              <ButtonCore
                label={t("save_button")}
                onClick={(e) => {onSaveChanges(userData, e)}}
              />
            </form>
            }
          </CentralContent>
      </Container>
    </TeacherRow>
  );
};

export default EditTeacherPage;
