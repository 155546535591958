import React, {useCallback, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {Input, Col, Row, Select} from "antd";
import {
  CentralContent,
  EditItems,
  ItemTitle,
  TeacherRow
} from "./style";
import { Button as ButtonCore } from "../../components";
import { UploadAvatar } from "../../components/molecules/EditProfilePageComponents";
import {useMappedState} from "redux-react-hook";
const {Option} = Select;

export function StudentFirstUpdatePage({onSaveChanges}) {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const [userMetadata, setUserMetadata] = useState({
    about: null,
    motivation: null,
    future_goals: null,
    hobbies: null,
    location: null,
    languages: [],
    proficiency: null
  });
  const [isFilled, setIsFilled] = useState(true);

  const onChange = useCallback((type, value) => {
    setUserMetadata({...userMetadata, [type]: value})
  }, [userMetadata]);

  useEffect(() => {
    setIsFilled(
      !userMetadata.about ||
      userMetadata.languages.length === 0 ||
      !userMetadata.future_goals ||
      !userMetadata.hobbies ||
      !userMetadata.location ||
      !userMetadata.motivation ||
      !userMetadata.proficiency
    )
  }, [userMetadata]);

  const {proficiencies, languages, locations} = useMappedState(state => state);

  return (
    <TeacherRow type="flex" justify="center">
        <CentralContent>
            <form>
                <EditItems>
                    <ItemTitle>{t("edit_text1")}</ItemTitle>
                    <UploadAvatar/>
                </EditItems>
                <EditItems>
                    <ItemTitle>{t("edit_text12")}</ItemTitle>
                    <Row gutter={8}>
                        <Col span={24}>
                          <Select
                            onSelect={(value) => onChange('location', value)}
                            placeholder={'Choose your city'}
                            style={{
                              width: '100%'
                            }}
                          >
                            {
                              locations && locations.length > 0 && locations.map((item) => {
                                return (
                                  <Option
                                    value={item._id}
                                    key={item._id}
                                  >
                                    {item.location + ' ' + item.country}
                                  </Option>
                                )
                              })
                            }
                          </Select>
                        </Col>
                    </Row>
                </EditItems>
                <EditItems >
                    <ItemTitle>{t("edit_text9")}</ItemTitle>
                    <TextArea 
                        placeholder={t("edit_text9")}
                        rows={4}
                        value={userMetadata.about}
                        onChange={(value) => onChange('about', value.target.value)}
                    />
                </EditItems>
                <EditItems >
                    <ItemTitle>{t("edit_text14")}</ItemTitle>
                    <TextArea 
                        placeholder={t("edit_text14")}
                        rows={4}
                        value={userMetadata.future_goals}
                        onChange={(value) => onChange('future_goals', value.target.value)}
                    />
                </EditItems>
                <EditItems >
                    <ItemTitle>{t("edit_text15")}</ItemTitle>
                    <TextArea 
                        placeholder={t("edit_text15")}
                        rows={4}
                        value={userMetadata.hobbies}
                        onChange={(value) => onChange('hobbies', value.target.value)}
                    />
                </EditItems>
                <EditItems >
                    <ItemTitle>{t("edit_text16")}</ItemTitle>
                    <TextArea 
                        placeholder={t("edit_text16")}
                        rows={4}
                        value={userMetadata.motivation}
                        onChange={(value) => onChange('motivation', value.target.value)}
                    />
                </EditItems>
                <EditItems>
                    <ItemTitle>{t("edit_text5")}</ItemTitle>
                    <Col span={24}>
                      <Select
                        mode="multiple"
                        onSelect={(value) => onChange('languages', value)}
                        placeholder={'Choose languages'}
                      >
                        {
                          languages && languages.length > 0 && languages.map((item) => {
                            return (
                              <Option
                                value={item._id}
                                key={item._id}
                              >{item.language}</Option>
                            )
                          })
                        }
                      </Select>
                    </Col>
                </EditItems>
                <EditItems>
                    <ItemTitle>{t("edit_text17")}</ItemTitle>
                    <Col span={24}>
                        <Select
                          onSelect={(value) => onChange('proficiency', value)}
                          placeholder={'Choose your skill'}
                          style={{
                            width: '100%'
                          }}
                        >
                          {
                            proficiencies && proficiencies.length > 0 && proficiencies.map((item) => {
                              return (
                                <Option
                                  value={item._id}
                                  key={item._id}
                                >
                                  {item.level}
                                </Option>
                              )
                            })
                          }
                        </Select>
                    </Col>
                </EditItems>
                <Row type="flex" justify="center" align="middle">
                  <ButtonCore disabled={isFilled} onClick={(e) => onSaveChanges(userMetadata, e)} label={t("save_button")}/>
                </Row>
            </form>
        </CentralContent>
    </TeacherRow>
  );
}
