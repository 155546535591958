import React from "react";
import { Row } from "antd";
import { useTranslation } from "react-i18next";
import {
   PageTitle,
   TableAnt
} from "./style";

export function AdminPanelPayment() {
  const { t } = useTranslation();
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      filters: [
        {
          text: 'Joe',
          value: 'Joe',
        },
        {
          text: 'Jim',
          value: 'Jim',
        },
        {
          text: 'Submenu',
          value: 'Submenu',
          children: [
            {
              text: 'Green',
              value: 'Green',
            },
            {
              text: 'Black',
              value: 'Black',
            },
          ],
        },
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend'],
    },
    {
      title: 'pay',
      dataIndex: 'pay',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.pay - b.pay,
    },
  ];
  
  const data = [
    {
      key: '1',
      name: 'John Brown',
      pay: 320,
    },
    {
      key: '2',
      name: 'Jim Green',
      pay: 320,
    },
    {
      key: '3',
      name: 'Joe Black',
      pay: 320,
    },
    {
        key: '4',
        name: 'John Brown',
        pay: 320,
      },
      {
        key: '5',
        name: 'Jim Green',
        pay: 320,
      },
      {
        key: '6',
        name: 'Joe Black',
        pay: 320,
      },
      {
        key: '7',
        name: 'John Brown',
        pay: 320,
      },
      {
        key: '8',
        name: 'Jim Green',
        pay: 320,
      },
      {
        key: '9',
        name: 'Joe Black',
        pay: 320,
      },
      {
        key: '10',
        name: 'John Brown',
        pay: 320,
      },
      {
        key: '11',
        name: 'Jim Green',
        pay: 320,
      },
  ];

   return (
      <Row type="flex">
         <PageTitle>
          <span>{t("admin_user_page_title")}</span>
         </PageTitle>
         <TableAnt
            columns={columns}
            dataSource={data}
            scroll={{ y: 400 }}/>
      </Row>
   );
}