import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Row } from "antd";
import { device } from "../../constants/device";
import { SideCalendar } from "../../components/molecules/RequestsComponents";

export const TeacherRow = styled(Row)`
  position: relative;
  padding-top: 0;

  @media ${device.laptopL} {
    padding-top: 100px;
  }
`;

export const SideContent = styled.div`
    width: 100%;
    display: block;
    vertical-align: top;

    @media ${device.tablet} {
        display: inline-block;
        width: 40%;
    }
`;

export const CentralContent = styled.div`
    width: 100%;
    display: block;
    padding: 10px;

    @media ${device.tablet} {
        width: 60%;
        display: inline-block;
        vertical-align: top;
        padding: 0 10px 40px;
    }
`;

export const CalendarRequests = styled(SideCalendar)`

  .ant-fullcalendar-fullscreen .ant-fullcalendar-month, 
  .ant-fullcalendar-fullscreen .ant-fullcalendar-date {
    height: 45px;
  }
`;
