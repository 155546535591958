import React from 'react';
import { BookingTimePicker } from '../../../molecules';
import { DetailsBlock } from './style';


export function BookingDetails(props) {

   return (
      <DetailsBlock>
         <BookingTimePicker onChoose={props.onChoose}/>
      </DetailsBlock>
   );
}
