import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import { Icon, Row } from 'antd';
import { Images } from "../../../assets/images";
import { colGreen } from '../../../constants/variables';

const RowAnt = styled(Row)`
  background-color: #fff;
  margin-bottom: 20px;
`;

const SliderWrapper = styled(Slider)`
width: 100%;
.slick-arrow {
  background-color: #fff;
  
  &.slick-prev,
  &.slick-next {
    top: 26px;
    
    &:before {
        color: #76d9ff;
        font-family: 'PoppinsBold', sans-serif;
        font-size: 24px;
      }
  }
  
  &.slick-prev {
    left: 90%;
    z-index: 1;
  }
  
  &.slick-next {
    right: 4%;
    z-index: 1;
  }
}

.slick-dots {
  bottom: -40px;
}

.slick-dots li button:before {
  color: #e6e6e6;
  font-size: 20px;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  color: ${colGreen};
}
`;

const BlockHeader = styled(Row)`
  width: 100%;
  padding: 20px 25px 15px;
  border-bottom: 2px solid #f9f9f9;
  p {
    font-family: 'OdinRounded', sans-serif;
    font-size: 20px;
    margin-bottom: 0;
    line-height: 1;
  }
`;

const BlockText = styled(Row)`
  font-family: 'PoppinsRegular', sans-serif;
  font-size: 18px;
  padding: 25px;
`;

const FeedbackUserInfo = styled.div`
  font-family: 'PoppinsRegular', sans-serif;
  font-size: 16px;
  line-height: 1.2;
  padding: 25px;
  
  img {
    display: inline-block !important;
    vertical-align: top;
    width: 55px;
    height: 55px;
    margin-right: 30px;
  }
`;
const FeedbackUser = styled.div`
  display: inline-block;
  p {
    display: block;
    margin-bottom: 3px;
    margin-top: 8px;
  }
`;

const RatingList = styled.div`
  display: block;
  line-height: 1;
  
  .anticon {
    margin-right: 3px;
    svg {
      height: 10px;
      width: 12px; 
    }
  } 
`;

const Feedback = styled.div`
  margin: auto;
`;

function retingList() {
    let row = [];

    const IconAnt = styled(Icon)`
    color: ${colGreen};
    height: 12px;
    width: 13px;

    &:not(:last-child) {
      margin-right: 3px;
    }
  `;

    for (let i = 0; i < 5; i++) {
        row.push(<IconAnt type="star" theme="filled" key={i} />);
    }
    return row;
}

export function FeedbackSlider(props) {
    const { t } = useTranslation();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <RowAnt type="flex">
            <SliderWrapper {...settings}>
                <Feedback>
                    <BlockHeader type="flex" justify="space-between">
                        <p>{t('feedbacks')}</p>
                    </BlockHeader>
                    <BlockText>{t('feedbacks_text')}</BlockText>
                    <FeedbackUserInfo>
                        <img src={Images.userPhoto} alt={'user-name'}/>
                        <FeedbackUser>
                            <p>{t('testimonial_user_info3')}</p>
                            <RatingList>{retingList()}</RatingList>
                        </FeedbackUser>
                    </FeedbackUserInfo>
                </Feedback>
                <Feedback>
                    <BlockHeader type="flex" justify="space-between">
                        <p>{t('feedbacks')}</p>
                    </BlockHeader>
                    <BlockText>{t('feedbacks_text')}</BlockText>
                    <FeedbackUserInfo>
                        <img src={Images.userPhoto} alt={'user-name'}/>
                        <FeedbackUser>
                            <p>{t('testimonial_user_info3')}</p>
                            <RatingList>{retingList()}</RatingList>
                        </FeedbackUser>
                    </FeedbackUserInfo>
                </Feedback>
            </SliderWrapper>
        </RowAnt>
    );
}
