import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { device } from "../../../constants/device";
import { colGreen } from '../../../constants/variables';

export const NavigationLink = styled(Link)`
  color: #fff;
  font-family: 'PoppinsRegular', sans-serif;
  font-size: 12px;
  display: inline-block;
  line-height: 0.75;
  position: relative;
  padding: 10px 5px;
  margin: 5px;

  &:hover {
    text-decoration: none;
    
    &:after {
      content: '';
      border-bottom: 2px solid #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      @media ${device.tablet} {
        display: block;
        border-bottom-width: 5px;
      }
    }
  }

  @media ${device.tablet} {
    margin: 0 10px 0 0;
    padding: 21px 0;
    margin-right: 20px;
    font-size: 14px;
  }

  @media ${device.laptop} {
    margin-right: 50px;
    font-size: 16px;
  }
  
  &.active {
    pointer-events: none;
    text-decoration: none;

    &:after {
      content: '';
      border-bottom: 2px solid #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      @media ${device.tablet} {
        display: block;
        border-bottom-width: 5px;
      }
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  min-height: 55px;
  margin: 0 auto 20px;
  padding: 0 15px;
  background: ${colGreen};
  z-index: 1;

  @media ${device.laptopL} {
    margin: auto;
    padding: 0;
    height: 55px;
    min-height: auto;
    position: absolute;
  }
`;

// export const MenuBlockButton = styled.button`
//   border: 2px solid ${colGreen};
//   color: ${colGreen};
//   padding: 6px 10px;
//   border-radius: 25px;
//   text-transform: uppercase;
//   font-family: 'PoppinsBold', sans-serif;
//   font-size: 12px;
//   background: #fff;
  
//   @media ${device.tablet} {
//     font-size: 14px;
//     line-height: 1.1;
//   }

//   @media ${device.laptop} {
//     padding: 6px 15px;
//     line-height: 1;
//   }
// `;