import { all, takeEvery } from 'redux-saga/effects'
import paymentService from '../../services/payment.service'
import notify from "../../utilities/Notify";

function* createPayment({payload}) {
  try {
    yield paymentService.createCharge(payload);
  } catch(e) {
    notify.error(e)
  }
}

function* fetchDataWatcher() {
  yield takeEvery('CREATE_CHARGE', createPayment);
}

export function* paymentSaga() {
  yield all([fetchDataWatcher()])
}
