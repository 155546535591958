import styled from 'styled-components';

export const Dialogs = styled.div`
    display: flex;
    width: 25%;
    background-color: #fff;
    flex-direction: column;
    border-right: 1px solid #f5f5f5;
    height: 75vh
`;
