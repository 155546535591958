import { all, takeEvery, put, select } from 'redux-saga/effects'
import chatService from '../../services/chat.service'
import notify from "../../utilities/Notify";

function* getRooms() {
  try {
    const data = yield chatService.getRooms();
    yield put({
      type: 'SET_ROOMS',
      payload: {
        rooms: data.data
      }
    })
  } catch(e) {
    notify.error(e)
  }
}

function* getDialog({payload}) {
  try {
    const data = yield chatService.getMessages(payload);

    const state = yield  select();

    const user = state.auth.user;

    data.data.messages.map((item) => {
      if (item.sender === user._id) {
        item.sender = {
          _id: user._id,
          first_name: user.first_name,
          last_name: user.last_name,
          avatar: user.avatar
        }
      } else {
        item.sender = {
          _id: data.data.member._id,
          first_name: data.data.member.first_name,
          last_name: data.data.member.last_name,
          avatar: data.data.member.avatar
        }
      }
      return item;
    });

    yield put({
      type: 'SET_DIALOG',
      payload: {
        dialog: {
          id: payload,
          data: data.data.messages,
          member: data.data.member
        }
      }
    })
  } catch(e) {
    notify.error(e)
  }
}

function* getUserInfo({payload, sideEffect}) {
  try {
    const data = yield chatService.getUserInfo(payload.id);
    sideEffect({
      ...data.data,
      msg: payload.message
    })

  } catch (e) {
    notify.error(e)
  }
}

function* markAsRead({payload}) {
  try {
    yield chatService.markAsRead(payload).data;
    yield put({
      type: 'GET_DIALOG',
      payload: payload
    });
  } catch (e) {
    notify.error(e)
  }
}

function* addDialog({payload}) {
  try {
    const chat = yield chatService.addDialog({data: payload});
    console.log('chat: ', chat.data);
    yield put({
      type: 'GET_DIALOG',
      payload: chat.data
    })
  } catch (e) {
    notify.error(e)
  }

}

function* fetchDataWatcher() {
  yield takeEvery('GET_ROOMS', getRooms);
  yield takeEvery('GET_DIALOG', getDialog);
  yield takeEvery('NOTIFY_NEW_MSG', getUserInfo);
  yield takeEvery('MARK_AS_READ', markAsRead);
  yield takeEvery('ADD_NEW_DIALOG', addDialog);
}

export function* messagesSaga() {
  yield all([fetchDataWatcher()])
}
