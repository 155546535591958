import styled from "styled-components";
import { device } from "../../../constants/device";
import { colGreen } from "../../../constants/variables";
import { Link } from 'react-router-dom';

export const Button = styled(Link)`
  font-family: 'PoppinsBold', sans-serif;
  font-size: 12px;
  color: ${colGreen};
  line-height: 1;
  margin: 0 auto 10px;
  background-color: #fff;
  border: 2px solid ${colGreen};
  border-radius: 25px;
  padding: 10px;
  cursor: pointer;
  min-height: 32px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  position: relative;

  @media ${device.tablet} {
    padding: 10px 20px;
  }

  svg {
    height: 12px;
    width: 12px;
    margin: 0 0 0 10px;
    padding: 3px 0 0;
  }
`;