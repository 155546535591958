import React from 'react';
import { Images } from '../../../assets/images';
import { Select } from 'antd';
import { RowAnt, SelectAnt } from "./style";
import i18n from '../../../i18n';
import { LanguageModal as LangModal } from "../../molecules";

export function LanguageSwitcher(props) {

    const { Option } = Select;

    function handleChange(value) {
        document.body.classList.remove("site-lang-" + i18n.language)
        i18n.changeLanguage(value)
        localStorage.setItem("siteLang", i18n.language)
        document.body.classList.remove("site-lang-" + localStorage.getItem("siteLang"))
    }

    return (
        <RowAnt type="flex">
            <LangModal/>
            <SelectAnt 
                defaultValue={localStorage.getItem("i18nextLng")}                 
                onChange={handleChange}
                showArrow={false}
            >
                <Option value="de"><img src={Images.GermanyFlag} alt='GermanyFlag' /></Option>
                <Option value="en"><img src={Images.EnglishFlag} alt='EnglishFlag' /></Option>
                <Option value="fr"><img src={Images.FranceFlag} alt='FranchFlag' /></Option>
                <Option value="ua"><img src={Images.UkraineFlag} alt='UkraineFlag' /></Option>
            </SelectAnt>
        </RowAnt>
    );
}
