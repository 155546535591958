import React, {useEffect} from "react";
import {
  Container,
} from "../../components";
import {
  SideContent,
  CentralContent,
  CalendarRequests
} from "./style";
import { StudentRequestBlock } from '../../components/molecules/RequestsComponents';
import {useDispatch, useMappedState} from "redux-react-hook";
import moment from "moment";
import { useTranslation } from 'react-i18next';

export function StudentRequestsPage() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const requests = useMappedState(state => state.request);

  useEffect(() => {
    dispatch({
      type: 'GET_REQUESTS_FROM'
    })
  }, [dispatch]);

  function onChange(value, mode) {
    console.log(value, mode);
  }

  useEffect(() => {
    console.log(requests)
  }, [requests])

  return (
    <Container>
      <SideContent>
          <CalendarRequests
            onPanelChange={() => onChange()}
            data={requests}
          />
      </SideContent>
      <CentralContent>
        {
          // eslint-disable-next-line
          requests && requests.length > 0 ? requests.map((item) => {
            if (item && item.teacher) {
              return (
                <StudentRequestBlock
                  studentPhoto={process.env.REACT_APP_PUBLIC_FILE_URL + '/' + item.teacher._id + '/' + item.teacher.avatar}
                  studentname={item.teacher.first_name + ' ' + item.teacher.last_name}
                  instrument={item.instrument.name}
                  lessonplace={item.class_type.type}
                  teacher_id={item.teacher._id}
                  datetime={moment(item.start_time).format('LLL')}
                  key={item._id}
                  data={item}
                />
              )
            }
          }):
          <p style={{
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: 18
          }}>
            {t("request_page_no_request")}
          </p>
        }
      </CentralContent>
    </Container>
  );
}
