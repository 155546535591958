import {Modal, Row} from "antd";
import styled from "styled-components";

export const ModalAnt = styled(Modal)`
    width: 80vw !important;
    max-height: 80vh;
    top: 20px;
    .ant-modal-body {
        height: 40vw;
        overflow: auto;
        padding: 0 20px;
    }
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const GreenButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: 20px;
  &:hover {
    color: #b3e830;
  }
`;

export const RedButton = styled.button`
  border: none;
  font-size: 20px;
  background-color: transparent;
  &:hover {
    color: red;
  }
`;
export const RowAnt = styled(Row)`
    width: 90%;
    margin: 10px 0 0;
    padding: 10px 0 0;
    
    &:not(:last-child) {
        border-top: 1px solid #e8e8e8;
    }
`;
