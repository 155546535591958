import React from "react";
import { useTranslation } from "react-i18next";
import { Input } from "antd";
import {
   BackgroundRow,
   CentralContentContainer,
   Container,
   SideContent,
   UserPhoto,
} from "../../components";
import { Images } from "../../assets/images";
import {
  RatingIconAnt,
  RowAnt,
  BlockHeader,
  BlockText,
  TutorBlockBottom,
  ButtonDashboard,
  FormItemAnt,
  RatingList
} from "./style";

function ratingList() {
  let row = [];

  for (let i = 0; i < 5; i++) {
    row.push(<RatingIconAnt type="star" theme="filled" key={i} />);
  }
  return row;
}

export function TutorBuchung() {
  const { t } = useTranslation();

  return (
    <BackgroundRow type="flex" justify="center">
      <UserPhoto>
        <img src={Images.TeacherPhoto} alt='Teacher'/>
      </UserPhoto>
      <Container>
        <SideContent />
        <CentralContentContainer>
          <RowAnt type="flex">
              <BlockHeader type="flex" justify="space-between">
                  <p>{t('tutor_block_title1')}</p>
              </BlockHeader>
              <BlockText>{t('tutor_block_text1')}</BlockText>
              <TutorBlockBottom type="flex" justify="end">
                <ButtonDashboard>
                  {t("tutor_button_text1")} &rarr;
                </ButtonDashboard>
              </TutorBlockBottom>
          </RowAnt>
          <RowAnt type="flex">
              <BlockHeader type="flex" justify="space-between">
                  <p>{t('tutor_block_title2')}</p>
              </BlockHeader>
              <BlockText>{t('tutor_block_text2')}</BlockText>
              <FormItemAnt>
                <Input placeholder={t('tutor_input_text')} />
              </FormItemAnt>
              <TutorBlockBottom type="flex" justify="space-between">
                <RatingList>{ratingList()}</RatingList>
                <ButtonDashboard>
                  {t("tutor_button_text2")} &rarr;
                </ButtonDashboard>
              </TutorBlockBottom>
          </RowAnt>
        </CentralContentContainer>
      </Container>
    </BackgroundRow>
  );
}
