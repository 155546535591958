import React, {useCallback, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Layout, Row, Form, Icon, Input} from 'antd';
import {
  FormInput,
  CheckboxRemember,
  RegistrationLink,
  LoginForm,
  ForgotLink,
  AdditionalRow,
  Terms,
  EnterViaSocial,
  FBbutton,
  Gbutton,
  AntRow,
  RegHeader,
} from './style';

import { Link } from 'react-router-dom';
import { Button as ButtonLogin } from "../../atoms";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import useLoginPage from '../../../hooks/LoginPage';
import { ROOT_TERMS, REGISTER_ROUTE } from '../../../constants/routes';
import ForgotPasswordModal from "../ForgotPasswordModal/ForgotPasswordModal";

function LoginPage({ form, history, loading }) {
  const { t } = useTranslation();
  const {
    responseFacebook,
    responseGoogle,
    submitForm,
    getFieldDecorator,
  } = useLoginPage(form, history);

  const [isShowModal, setIsShowModal] = useState(false);

  const toggleModal = useCallback(() => {
    setIsShowModal(!isShowModal)
    }, [isShowModal])
  ;


  return (
    <Layout>
      <AntRow type="flex" justify="center">
        <LoginForm onSubmit={submitForm}>
          <FormInput>
            {getFieldDecorator('email', {
              rules: [{ required: true, message: t('login_form_input_email') }],
            })(
              <Input placeholder={t('registration_form_placeholder_email')} />
            )}
          </FormInput>
          <FormInput>
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: t('login_form_input_password') },
              ],
            })(
              <Input
                type="password"
                placeholder={t('registration_form_placeholder_password')}
              />
            )}
          </FormInput>
          <Form.Item>
            <Row type="flex">
              <ButtonLogin
                type="primary"
                htmlType="submit"
                class="login-form-button"
                label={t('login_form_login_button')}
              />
              <CheckboxRemember>{t('login_form_remember')}</CheckboxRemember>
            </Row>
            <EnterViaSocial>
              <Gbutton
                clientId="454356284057-54vr4g7rab5e6g3jdp2djhq7dkg5hmmv.apps.googleusercontent.com"
                buttonText={t('login_form_login_google')}
                onSuccess={responseGoogle}
                className="social"
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
              />
              <FacebookLogin
                appId="1642454415886527"
                fields="name, email, picture"
                callback={responseFacebook}
                render={renderProps => (
                  <FBbutton onClick={renderProps.onClick} className="social">
                    <Icon
                      type="facebook"
                      style={{ fontSize: 20, marginRight: 10 }}
                    />
                    <span>{t('login_form_login_facebook')}</span>
                  </FBbutton>
                )}
              />
            </EnterViaSocial>
            <ForgotLink className="login-form-forgot" onClick={toggleModal}>
              {t('login_form_forgot_password')}?
            </ForgotLink>
          </Form.Item>
          <Form.Item type="flex" justify="start">
            <RegHeader>{t('top_menu_free_reg')}</RegHeader>
            <RegistrationLink type="flex">
              <Link to={REGISTER_ROUTE}>
                {t('top_menu_reg_for_free')}
                <Icon style={{marginLeft: '10px'}}type="arrow-right" />
              </Link>
            </RegistrationLink>
            <AdditionalRow type="flex">
              <Terms to={ROOT_TERMS}>{t('login_form_terms_conditions')}</Terms>
            </AdditionalRow>
          </Form.Item>
        </LoginForm>
      </AntRow>
      <ForgotPasswordModal isShowModal={isShowModal} toggleModal={toggleModal}/>
    </Layout>
  );
}

export const logPage = Form.create({ name: 'login_form' })(LoginPage);
