import styled from "styled-components";
import { Form, Checkbox, Row } from "antd";
import { device } from "../../constants/device";
import { Images } from "../../assets/images";
import { Link } from 'react-router-dom';
import { colGreen } from '../../constants/variables';
import {GoogleLogin} from "react-google-login";

export const FormInput = styled(Form.Item)`
    font-family: 'PoppinsBold', sans-serif;
    font-size: 16px;
    // height: 47px;
    min-width: 300px;
    text-align: center;
    .ant-input-affix-wrapper {
        overflow: hidden;
        border-radius: 30px;
    }
    .ant-form-item-control{
        line-height: inherit;
    }
    .ant-input {
        border-color: ${colGreen};
        border-radius: 30px;
        height: 46px;
        padding-left: 62px !important;
    }

    .ant-input-prefix {
        background: ${colGreen};
        height: 46px;
        width: 50px;
        left: 0;

        .anticon {
            color: #333 !important;

            svg {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: 0;
                right: 0;
            }
        }
    }
`;

export const LogoItem = styled.span`
    background: url(${Images.Logo321}) no-repeat;
    display: block;
    height: 80px;
    width: 210px;
    background-size: contain;
    margin: 0 auto 30px;
`;

export const CheckboxRemember = styled(Checkbox)`
    font-family: 'OdinRounded', sans-serif;
    font-size: 12px;

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: transparent;
        border-color: ${colGreen};

        &:after {
            border-color: #5e5e5d;
        }
    }
`;

export const RegistrationLink = styled(Row)`
    font-family: 'OdinRounded', sans-serif;
    font-size: 12px;
    color: #72726f;

    a {
        font-family: 'OdinRounded', sans-serif;
        font-size: 12px;
        color: #72726f;
    }
`;

export const LoginForm = styled(Form)`
    display: block;
    padding: 20px 0;
    border-radius: 15px;
    background: #fff;

    @media ${device.tablet} {
        padding: 20px 30px;
    }

    @media ${device.tablet} {
        padding: 40px 150px;
    }

    .login-form-button {
        margin: 20px auto;
        min-width: 300px;
    }
`;

export const ForgotLink = styled.span`
    cursor: pointer;
    font-family: 'OdinRounded', sans-serif;
    font-size: 12px;
    color: #72726f;
`;

export const AdditionalRow = styled(Row)`
`;

export const Terms = styled(Link)`
    color: #72726f;
    font-family: 'OdinRounded', sans-serif;
    font-size: 12px;
    display: block;
`;

export const SocialLink = styled.div`
    display: block;
    display: block;
    width: 100%;
    margin: auto;
    text-align: center;

    a {
        color: #72726f;

        &:not(:last-child) {
            margin: 0 10px 0 0;
        }
    }
`;

export const EnterViaSocial = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Gbutton = styled(GoogleLogin)`
    display: flex;
    padding: 0;
    background-color: white;    
    align-items: center;
    border-radius: 20px;
`;

export const FBbutton = styled.button`
    display: flex;
    align-item: center;
    cursor: pointer;
    padding: 13px 15px;
    background-color: #397ed1;
    color: #fff;
    border-radius: 3px;
    border: none;
    margin-left: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
`;

export const AntRow = styled(Row)`
    margin-top: -142px;
`;
