import { Icon, Row } from 'antd';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { device } from "../../../../constants/device";
import { colGreen } from '../../../../constants/variables';

export const RowAnt = styled(Row)`
  background-color: #fff;
  margin-bottom: 20px;
 
  &:not(:nth-child(2n+2)) {
    margin-right: 10px;
  }
`;

export const SliderWrapper = styled(Slider)`
    width: 100%;
    .slick-arrow {
        background-color: #fff;
        
        &.slick-prev,
        &.slick-next {    
            &:before {
                color: #76d9ff;
                font-family: 'PoppinsBold', sans-serif;
                font-size: 24px;
            }
        }
        
        &.slick-prev {
            left: 75%;
            z-index: 1;
            top: 27px;

            @media ${device.laptopL} {
                left: 45%;
            }
        }
        
        &.slick-next {
            right: 5%;
            z-index: 1;
            top: 27px;

            @media ${device.laptopL} {
                right: 43%;
            }
        }
    }
`;

export const BlockHeader = styled(Row)`
  width: 100%;
  padding: 20px 25px 15px;
  border-bottom: 2px solid #f9f9f9;
  
  p {
    font-family: 'OdinRounded', sans-serif;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1;
    color: ${colGreen};

    @media ${device.laptop} {
        font-size: 20px;
    }
  }
`;

export const BlockText = styled(Row)`
  font-family: 'PoppinsLight', sans-serif;
  font-size: 14px;
  padding: 25px;

  @media ${device.laptop} {
    font-size: 18px;
  }
`;

export const FeedbackUserInfo = styled.div`
  font-family: 'PoppinsRegular', sans-serif;
  font-size: 12px;
  line-height: 1.2;
  padding: 25px;

  @media ${device.laptop} {
    font-size: 16px;
  }
`;

export const FeedbackUser = styled.div`
  p {
    display: inline-block;
    margin-bottom: 0;
  }
`;

export const Feedback = styled.div`
  margin: auto;
`;

export const RatingList = styled.div`
  display: inline-block;
  line-height: 1;
  margin-left: 15px;

  .anticon {
    margin-right: 3px;
    
    svg {
      height: 10px;
      width: 12px; 
    }
  } 
`;

export const RetingItem = styled(Icon)`
  color: ${colGreen};
  height: 12px;
  width: 13px;

  &:not(:last-child) {
    margin-right: 3px;
  }
`;