import React from "react";
import {Link, withRouter} from "react-router-dom";


const RegisterThanksPage = withRouter(({match, history}) => {

  return (
    <div>
      <Link
        to={'/'}
      >
        <img
          style={{
            display: 'block',
            margin: '0 auto'
          }}
          src="/static/media/logo.24cddbe7.png"
          alt="logo"
        />

      </Link>
      <p style={{
        fontWeigh: 'bold',
        fontSize: 32,
        textAlign: 'center',
        color: '#383838',
        marginTop: 40
      }}>
        Thanks for registration! We've just sent email with instructions. Check it please
      </p>
    </div>
  )
});

export default RegisterThanksPage
