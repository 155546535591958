import {useDispatch, useMappedState} from "redux-react-hook";

import React, {useCallback, useEffect} from "react";
import EditTeacherPage from "./EditTeacherProfile";
import EditStudentPage from "./EditStudentProfile";

const EditProfilePage = () => {
  const user = useMappedState(state => state.auth).user;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'GET_INSTRUMENTS'
    });
    dispatch({
      type: 'GET_LANGUAGES'
    });
    dispatch({
      type: 'GET_PROFICIENCIES'
    });
    dispatch({
      type: 'GET_LOCATIONS'
    });
    dispatch({
      type: 'GET_CLASS_TYPES'
    })
  }, [dispatch]);

  const onSaveChanges = useCallback((data, e) => {
    e.preventDefault();
    dispatch({
      type: "UPDATE_USER",
      payload: data
    })
  }, [dispatch]);

  return (
    user && user.role_id.role === 'teacher' ?
    <EditTeacherPage onSaveChanges={onSaveChanges}/> :
    <EditStudentPage onSaveChanges={onSaveChanges}/>
  )
};

export default EditProfilePage
