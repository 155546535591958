import styled from 'styled-components';
import { Button, Col } from 'antd';
import { colDisabledButton } from '../../../../constants/variables';
import { device } from '../../../../constants/device';

export const TimePicker = styled(Col)`
   border-radius: 20px;
   
   @media ${device.laptop} {
      margin: 0 10px;
   }
`;

export const TimeList = styled.ul`
   list-style: none;
   margin: 0;
   border-left: 1px solid ${colDisabledButton};
   border-right: 1px solid ${colDisabledButton};
   &:not(:first-child):not(:last-child) {
      button {
         border-top: 1px solid ${colDisabledButton};
         border-bottom: 1px solid ${colDisabledButton};
      }
   }
`;

export const TimeButton = styled(Button)`
   width: 100%;
   height: 3rem;
   border: none;
   border-radius: 0;
   text-align: left;
   font-size: 25px;
   font-family: 'PoppinsRegular', sans-serif;
   text-align: center;

   @media ${device.laptop} {
   }
`;