import React, {useCallback, useState} from "react";
import { useTranslation } from 'react-i18next';
import { Layout, Row, Form, Icon, Input } from "antd";
import {
  LogoItem,
  FormInput,
  CheckboxRemember,
  RegistrationLink,
  LoginForm,
  ForgotLink,
  AdditionalRow,
  Terms,
  SocialLink,
  EnterViaSocial,
  FBbutton,
  Gbutton,
  AntRow
} from "./style";
import { Button as ButtonLogin } from "../../components";
import { Link } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import useLoginPage from "../../hooks/LoginPage";
import { ROOT_TERMS, REGISTER_ROUTE } from "../../constants/routes";
import ForgotPasswordModal from "../../components/molecules/ForgotPasswordModal/ForgotPasswordModal";

function LoginPage({form, history}) {

  const { t } = useTranslation();
  const {
      responseFacebook,
      responseGoogle,
      submitForm,
      getFieldDecorator
  } = useLoginPage(form, history);

  const [isShowModal, setIsShowModal] = useState(false);

  const toggleModal = useCallback(() => {
    setIsShowModal(!isShowModal)
  }, [isShowModal]);

  return (
    <Layout>
        <AntRow type="flex" justify="center">
          <LoginForm onSubmit={submitForm}>
            <Link to={'/'}><LogoItem/></Link>
            <FormInput>
              {
                getFieldDecorator('email', {
                  rules: [{ required: true, message: t("login_form_input_email") }],
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder={t('registration_form_placeholder_email')}
                />
              )}
            </FormInput>
            <FormInput>
              {
                getFieldDecorator('password', {
                  rules: [{ required: true, message: t("login_form_input_password") }],
              })(
                <Input
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder={t('registration_form_placeholder_password')}
                />
              )}
            </FormInput>
            <Form.Item>
              <Row type="flex" justify="space-between">
                <CheckboxRemember>{t('login_form_remember')}</CheckboxRemember>
                <ForgotLink className="login-form-forgot" onClick={toggleModal}>
                  {t('login_form_forgot_password')}
                </ForgotLink>
              </Row>              
              <ButtonLogin
                htmlType="submit"
                class="login-form-button"
                label={t('login_form_login_button')}
              />
              <RegistrationLink type="flex" justify="center">
                {t('login_form_question')} <Link to={REGISTER_ROUTE}> {t('login_form_registration')}</Link>
              </RegistrationLink>
            </Form.Item>

            <AdditionalRow type="flex" justify="center">
              <SocialLink>
                <Link to="#">
                  <Icon type="instagram" />
                </Link>
                <Link to="#">
                  <Icon type="facebook" />
                </Link>
              </SocialLink>
              <Terms to={ROOT_TERMS}>{t('login_form_terms_conditions')}</Terms>
            </AdditionalRow>
          </LoginForm>
          <EnterViaSocial>
            <Gbutton
              clientId="454356284057-54vr4g7rab5e6g3jdp2djhq7dkg5hmmv.apps.googleusercontent.com"
              buttonText={t('login_form_login_google')}
              onSuccess={responseGoogle}
              className="social"
              onFailure={responseGoogle}
              cookiePolicy={'single_host_origin'}
            />
            <FacebookLogin
              appId="1642454415886527"
              fields="name, email, picture"
              callback={responseFacebook}
              render={renderProps => (
                <FBbutton
                  onClick={renderProps.onClick}
                  className="social"
                >
                  <Icon type="facebook" style={{fontSize: 20, marginRight: 10}}/>
                  <span>{t('login_form_login_facebook')}</span>
                </FBbutton>
              )}
            />
          </EnterViaSocial>
          <ForgotPasswordModal isShowModal={isShowModal} toggleModal={toggleModal}/>
        </AntRow>
    </Layout>
  )
}

export const logPage = Form.create({ name: 'login_form' })(LoginPage);
