import React, {useCallback, useEffect} from "react";
import {
  Redirect,
  Switch
} from "react-router-dom";
import {
  BOOKING_ROUTE,
  DASHBOARD_ROUTE,
  TEACHER_ROUTE,
  TUTORBUCHUNG_ROUTE,
  REQUEST_PAGE,
  EDIT_ROUTE,
  CHAT_ROUTE,
  EVENT_DASHBOARD,
} from "../constants/routes";
import {
  Booking,
  Dashboard,
  User,
  TutorBuchung,
  ChatPage,
  EventDashboard,
} from "../scenes";
import {
  HeaderFooterLayout,
  LayoutContainer,
} from './Layouts';
import RequestPage from "../scenes/LessonRequestPage/RequestPage";
import FirstUpdatePage from "../scenes/FirstUpdatePage/FirstUpdatePage"
import EditProfilePage from "../scenes/EditProfilePage/EditProfilePage";
import {message} from "antd";
import useAuth from "../hooks/useAuth";


export const AppLayout = ({history}) => {

  const notifyListener = useCallback((e) => {
    if (e.detail.redirectTo) {
      history.push(e.detail.redirectTo)
    }
    message[e.detail.type](e.detail.msg);
  }, [history]);

  useEffect(() => {
    document.addEventListener('notify', notifyListener);
    return () => {
      document.removeEventListener('notify', notifyListener)
    }
  }, [history, notifyListener]);

  const auth = useAuth(history);

  return (
     auth.user &&
     <Switch>
       <LayoutContainer exact={true} path={'/fill_profile'} component={FirstUpdatePage} layout={HeaderFooterLayout} />
       {
         auth.user.filled ?
           (
             <>
               <LayoutContainer exact={true} path={EVENT_DASHBOARD} component={EventDashboard} layout={HeaderFooterLayout} />
               {/* <LayoutContainer exact={true} path={BENEFITS_ROUTE} component={Benefits} layout={HeaderFooterLayout} /> */}
               <LayoutContainer exact={true} path={BOOKING_ROUTE} component={Booking} layout={HeaderFooterLayout} />
               <LayoutContainer exact={true} path={EDIT_ROUTE} component={EditProfilePage} layout={HeaderFooterLayout} />
               <LayoutContainer exact={true} path={DASHBOARD_ROUTE} component={Dashboard} layout={HeaderFooterLayout} />
               <LayoutContainer exact={true} path={CHAT_ROUTE} component={ChatPage} layout={HeaderFooterLayout} />
               <LayoutContainer exact={true} path={REQUEST_PAGE} component={RequestPage} layout={HeaderFooterLayout} />
               <LayoutContainer exact={true} path={TEACHER_ROUTE} component={User} layout={HeaderFooterLayout} />
               <LayoutContainer exact={true} path={TUTORBUCHUNG_ROUTE} component={TutorBuchung} layout={HeaderFooterLayout} />
               {/*// <LayoutContainer exact={true} path={ROOT_PAY} component={PayPage} layout={HeaderFooterLayout} />*/}
             </>
           ) : <Redirect to={`/fill_profile`}/>
       }
      </Switch>
   );
};
