import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import {
   TimeButton,
   TimeList,
   TimePicker,
} from './style';
import { colDisabledButton } from '../../../../constants/variables';

export function BookingTimeSelect(props) {


   function displayAvailableTime(availTime) {
      return (
         <TimeList>
            {availTime.map((time, index) => <TimeItem key={time} time={time} onSelect={props.onSelect}/>)}
         </TimeList>
      )
   }
   
   function canIncrementShowedTime() {
      return !(firstShowed + 3 === props.availTime.length);
   }

   function canDecrementShowedTime() {
      return !(firstShowed === 0);
   }

   const [firstShowed, setFirstShowed] = useState(0);
   const lastShowed = firstShowed + 3;

   return (
      <TimePicker xs={24} lg={6}>
         <Button
            block
            icon='up'
            style={{
               borderRadius: '10px 10px 0 0',
               border: `1px solid ${colDisabledButton}`
            }}
            onClick={() => {
               if(canDecrementShowedTime()) {
                  setFirstShowed(firstShowed - 1)
               }
            }}
         />
         {displayAvailableTime(props.availTime.slice(firstShowed, lastShowed))}
         <Button
            block
            icon='down'
            style={{
               borderRadius: '0 0 10px 10px',
               border: `1px solid ${colDisabledButton}`,
            }}
            onClick={() => {
               if (canIncrementShowedTime()) {
                  setFirstShowed(firstShowed + 1)
               }
            }}
         />
      </TimePicker>
   )
}

function TimeItem(props) {
   return (
      <li key={props.time}>
         <TimeButton
            type='default'
            onClick={() => props.onSelect(props.time)}
         >
            {props.time}
         </TimeButton>
      </li>
   )
}

BookingTimeSelect.propTypes = {
   availTime: PropTypes.array,
};

