import { all, takeLatest, put } from 'redux-saga/effects'
import service from '../../services/user_admin.service';
import notify from "../../utilities/Notify";

function* getUsers({payload}) {
  try {
    const users = yield service.getAll(payload);
    yield put({
      type: 'SET_USERS',
      payload: users
    })
  } catch (e) {
    notify.error(e)
  }
}

function* banUser({payload}) {
  try {
    yield service.banUser(payload);
    notify.success('Success');
    yield put({
       type: 'USERS_ITEM_BAN',
       payload: {
         _id: payload,
       }
     });
  } catch (e) {
    notify.error(e)
  }
}

function* updateUser({payload}) {
  try {
    yield service.updateUser(payload);
    notify.success('Success');
  } catch (e) {
    notify.error(e)
  }
}

function* deleteUser({payload}) {
  try {
    console.log(payload);
    yield service.deleteUser(payload);
    notify.success('Success');
    yield  put({
      type: 'DELETE_USERS_ITEM',
      payload: payload._id
    })
  } catch (e) {
    notify.error(e)
  }
}

function* fetchDataWatcher() {
  yield takeLatest('GET_ALL_USERS', getUsers);
  yield takeLatest('BAN_USER', banUser);
  yield takeLatest('ADMIN_UPDATE_USER', updateUser);
  yield takeLatest('DELETE_USER', deleteUser);
}

export function* usersSaga() {
  yield all([fetchDataWatcher()])
}
