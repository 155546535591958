import { all, takeEvery, put } from 'redux-saga/effects'
import instrumentsService from '../../services/instruments.service'
import locationsService from '../../services/location.service'
import notify from "../../utilities/Notify";

function* getInstrumetns(action) {
  try {
    const instruments = yield instrumentsService.searchInstruments(action.payload);
    console.log(instruments);
    yield put({
      type: 'SET_SEARCH',
      payload: {
        instruments: instruments
      }
    })
  } catch(e) {
    notify.error(e)
  }
}

function* getLocations(action) {
  try {
    const locations = yield locationsService.searchLocation(action.payload);
    yield put({
      type: 'SET_SEARCH',
      payload: {
        locations: locations
      }
    })
  } catch(e) {
    notify.error(e)
  }
}

function* fetchDataWatcher() {
  yield takeEvery('GET_INSTRUMENTS_SEARCH', getInstrumetns);
  yield takeEvery('GET_LOCATIONS_SEARCH', getLocations);
}

export function* mainSearchSaga() {
  yield all([fetchDataWatcher()])
}
