import React from 'react';
import { Menu } from 'antd';

const { Item, SubMenu } = Menu;

export function DropDownMenu(props) {
   return (
      <Menu>
         {Object.keys(props.menu).map(key => renderMenuItem(props.menu[key]))}
      </Menu>
   );
}

function renderMenuItem(item) {
   if (typeof item === 'object') {
      const subMenuTitle = item['title'];
      delete item.title;
      return (
         <SubMenu title={subMenuTitle}>
            {Object.keys(item).map(key => renderMenuItem(item[key]))}
         </SubMenu>
      );
   }
   else return <Item>{item}</Item>
}