import React from 'react';
import { ChatRoom } from "../";
import { Dialogs } from "./style";

export const Channels = ({channels, onChooseRoom}) => {

  return (
    <Dialogs>
      {
        channels.map((item, i) => {
          return <ChatRoom
            data={item}
            key={i}
            onChooseRoom={onChooseRoom}
          />
        })
      }
    </Dialogs>
  );
}
