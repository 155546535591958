const initialState = [

];

const languagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LANGUAGES':
      return initialState.concat(action.payload);
    default:
      return state;
  }
};

export default languagesReducer;
