const notify = {
  success: (msg) => {
    const newCustomEvent = new CustomEvent('notify', {
      detail: {
        msg,
        type: 'success'
      }
    });
    document.dispatchEvent(newCustomEvent)
  },
  error: (error) => {
    const detail = {
      msg: error.response.data.message.message,
      type: 'error'
    };
    if (error.response.code === 401) {
      detail.redirectTo = 'login'
    } else if (error.response.code === 403) {
      detail.redirectTo = ''
    }

    const newCustomEvent = new CustomEvent('notify', {
      detail
    });
    document.dispatchEvent(newCustomEvent)
  },
};

export default notify;
