import { Images } from "../../../../assets/images";
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { device } from "../../../../constants/device";

export const RowAnt = styled(Row)`
    @media ${device.desktop} {
        max-width: 1200px;
        margin: auto;
        width: 100%;
        position: relative;
    }
`;

export const LeftBlock = styled(Col)`
    background: url(${Images.Unsplash2}) no-repeat;
    background-size: cover;
    display: inline-block;
    height: 200px;
    background-position: center;
    width: 100%;
    margin-top: 60px;

    @media ${device.laptop} {
        max-height: 1090px;
        min-height: 500px;
        max-width: 65.5%;
        margin-top: 275px;
        background-position: right;
    }
`;

export const RightBlock = styled(Col)`
    max-height: 1090px;
    background: url(${Images.Unsplash}) no-repeat;
    background-size: cover;
    display: none;
    height: 200px;
    background-position: center;

    @media ${device.laptop} {
        min-height: 500px;
        max-width: 33.3%;
        margin-top: 275px;
        background-position: left;
        display: inline-block;
    }
`;

export const BlockTitle = styled.h2`
    font-family: 'OdinRounded', sans-serif;
    font-size: 24px;
    color: #fff;
    padding: 20px 30px;
    line-height: 0.8;
    background-color: #76d9ff;
    width: fit-content;
    width: max-content;
    text-transform: lowercase;
    border-radius: 150px;
    margin: auto;
    position: absolute;
    top: 30px;
    z-index: 1; 

    @media ${device.laptop} {
        font-size: 50px;
        padding: 40px 60px;
        border-radius: 150px;
        top: 205px;
    }
`;

export const PromoteMusicBlock = styled.div`
    width: 100%;
    padding: 40px;
    background: #76d9fe; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #76d9fe 0%, #b3e831 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #76d9fe 0%,#b3e831 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #76d9fe 0%,#b3e831 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#76d9fe', endColorstr='#b3e831',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    position: relative;
    top: -100px;
    right: 0;

    @media ${device.laptop} {
        width: 550px;
        padding: 40px;
        position: absolute;
        top: -400px;
    }
`;

export const PromoteMusicTitle = styled.h3`
    font-family: 'OdinRounded', sans-serif;
    font-size: 34px;
    color: #fff;
    line-height: 1;
`;

export const PromoteMusicText = styled.p`
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 16px;
    color: #fff;
    line-height: 1.3;
`;

export const PromoteMusicButton = styled.button`
    font-family: 'PoppinsBold', sans-serif;
    font-size: 12px;
    color: #333;
    line-height: 0.9;
    background-color: #fff;
    border: none;
    border-radius: 25px;
    padding: 10px 30px;
    text-transform: uppercase;
    cursor: pointer;
`;