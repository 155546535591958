import React from 'react';
import {
   StepBlock,
   StepNumber,
} from './style';
import { BookingSelect, BookingDetails } from '../';

export function BookingStep(props) {

   return (
      <StepBlock
         type='flex'
         className={props.direction}
      >
         <StepNumber className={props.direction}>{props.number}</StepNumber>
         <>
            {props.displaySelect ? (
               <BookingSelect
                 placeholder={props.placeholder}
                 options={props.options}
                 onSelect={props.onSelect}
                 type={props.type}
               />
            ) : (
               <BookingDetails
                  onChoose={props.onChoose}
               />
            )}
         </>
      </StepBlock>
   )
}
