import axios from '../utilities/Axios';

export default {
  getSchedule: async ({id, start_time, end_time}) => (await axios.get(`/schedule/${id}`, {
    params: {
      start_time, end_time
    }
  })).data.data,
  createSchedule: async (data) => (await axios.post(`/schedule`, data)).data.data,
  createScheduleList: async (data) => (await axios.post(`/schedule/list`, data)).data.data,
  removeScheduleItem: async (id) => (await axios.delete(`/schedule/${id}`)).data.data,
  updateSchedule: async (data, id) => (await axios.put(`/schedule/${id}`, data)).data.data,
  getFilteredSchedule: async (data) => (await axios.get(`/schedule/filtered`, {params: data})).data.data
}

