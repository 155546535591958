import { all, takeEvery, put } from 'redux-saga/effects'
import instrumentsService from '../../services/instruments.service'
import notify from "../../utilities/Notify";

function* getAll(action) {
  try {
    const instruments= yield instrumentsService.getInstruments();
    yield put({
      type: 'SET_INSTRUMENTS',
      payload: instruments
    })
  } catch(e) {
    notify.error(e)
  }
}

function* fetchDataWatcher() {
  yield takeEvery('GET_INSTRUMENTS', getAll);
}

export function* instrumentsSaga() {
  yield all([fetchDataWatcher()])
}
