import React from "react";
import { Col, Row, Icon } from "antd";
import { Container } from "../..";
import {
  DASHBOARD_ROUTE,
  ROOT_ROUTE,
} from "../../../constants/routes";
import { Images } from "../../../assets/images";
import { Wrapper, Logo, UserMenu } from "./style";
import { withRouter } from "react-router-dom";


function checkScroll() {
  window.onscroll = function(e) {
    let header = document.getElementById("header");
    let sticky = 200;

    if (window.pageYOffset > sticky) {
      header && header.classList.add("pl-sticky");
    } else {
      header && header.classList.remove("pl-sticky");
    }
  }
}

export const HeaderLite = withRouter((props) => {
  // const logOut = useCallback((e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   localStorage.removeItem('token');
  //   props.history.push('/login')
  // }, [props]);

  return (
    <Wrapper id="header" {...checkScroll()}>
      <Container>
        <Row type="flex" justify="space-between">          
          <Logo to={ROOT_ROUTE}>
            <img src={Images.Logo321} alt='logo' />
          </Logo>      
          <Col md={5}>
            <Row type="flex" align="middle" justify="end">
              <UserMenu to={DASHBOARD_ROUTE}><Icon type="user" /></UserMenu>
              {/*<HotLink>
                {localStorage.getItem('token') && <LogoutLink onClick={logOut} type="logout" />}                
              </HotLink>*/}
            </Row>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
});
