import { Row, Table } from 'antd';
// import { device } from "../../constants/device";
import styled from "styled-components";

export const PageTitle = styled(Row)`
    width: 100%
    margin: 0;
    padding: 10px 20px;
`;

export const TableAnt = styled(Table)`
    width: 100%;
`;
