import axios from '../utilities/Axios';

export default {
  register: async (body) => (await axios.post('/register', body)).data,
  login: async (body) => (await axios.post('/login', body)).data,
  registerSocial: async (body) => (await axios.post('/register/social', body)).data,
  loginSocial: async (body) => (await axios.post('/login/social', body)).data,
  verifyUSer: async (data) => (await axios.put(`/verify`, data)).data.data,
  requestRecoverPassword: async (data) => (await axios.post(`/reset-password`, data)).data,
  recoverPassword: async (data) => (await axios.post(`/reset-password/${data.token}`, {password: data.password})).data
}
