import {withRouter} from "react-router-dom";
import React, {useCallback, useState} from "react";
import {Container} from "../../components/atoms";
import {Input} from "antd";
import Button from "antd/es/button";
import {useDispatch} from "redux-react-hook";


const RecoverPasswordPage = withRouter(({match}) => {

  const dispatch = useDispatch();

  const [newPassword, setNewPassword] = useState('');

  const changePassword = useCallback((e) => {
    e.preventDefault();
    dispatch({
      type: 'RECOVER_PASSWORD',
      payload: {
        password: newPassword,
        token: match.params.token
      }
    })
  }, [dispatch, newPassword, match.params.token]);

  const onChangeNewPassword = useCallback((value) => {
    setNewPassword(value)
  }, []);

  return (
    <Container
      style={{
        height: 450,
        paddingTop: 100
      }}>
      <p style={{
        textAlign: 'center'
      }}>Enter your new password</p>
      <Input
        value={newPassword}
        type={"password"}
        onChange={(e) => {onChangeNewPassword(e.target.value)}}
        style={{
          width: 400,
          display: 'block',
          marginTop: 10,
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      />
      <Button
        disabled={newPassword.length < 6}
        onClick={changePassword}
        style={{
          display: 'block',
          marginTop: 10,
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >Change password</Button>
    </Container>
  )

});

export default RecoverPasswordPage;
