import React from "react";
// import { Row } from "antd";
import {
    RowHeader,
    AdminLogo
} from "./style";
import { Images } from "../../../../assets/images";
import { ADMIN_PANEL_ROUTE } from "../../../../constants/routes";
import { AdminNavigation } from "../";

export function AdminHeader() {
   return (
      <RowHeader type="flex" justify="space-between">
          <AdminLogo to={ADMIN_PANEL_ROUTE}>
            <img src={Images.LogoWhite} alt="" />
          </AdminLogo>
          <AdminNavigation/>
      </RowHeader>
   );
}