import styled from 'styled-components';
import { device } from '../../../constants/device';

export const UserPhoto = styled.div`
  height: 200px;
  background-color: #fff;
  border-radius: 50%;
  border: 8px solid #fff;
  position: relative;
  left: 0;
  width: 200px;

  @media ${device.tablet} {
    height: 340px;
    border-radius: 0 50% 50% 0;
    position: absolute;
    left: 8vw;
    width: auto;
  }
  
  a {
    right: 0;
    margin: auto;
    border-radius: 50%;
    height: 185px;
    width: 185px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: block;
    
    img {
      object-fit: cover;
      transform: scale(1.2);
      width: 100%;
      height: 100%;
    }
  }
`;
