import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./constants/lang/en";
import de from "./constants/lang/de";
import fr from "./constants/lang/fr";
import ua from "./constants/lang/ua";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
const resources = {
    en: en,
    de: de,
    fr: fr,
    ua: ua
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        //lng: 'en',
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        
        fallbackLng: 'en',
    });

export default i18n;
