import styled from "styled-components";
import { Row, Checkbox, Icon } from "antd";
import { device } from "../../constants/device";
import { Container } from "../../components";

export const TeacherRow = styled(Row)`
  position: relative;
  padding: 20px 0;
  margin-top: -142px;

  @media ${device.laptopL} {
    padding: 70px 0;
  }
`;

export const CentralContent = styled(Container)`
  max-width: 300px;
  display: block;
  vertical-align: top;
  background: #fff;

  @media ${device.mobileL} {
    max-width: 350px;
  }

  @media ${device.tablet} {
    max-width: 600px;
  }

  @media ${device.laptop} {
    max-width: 900px;
  }
`;

export const ListCheck = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;

    &:not(:last-child) {
      margin: 0 15px 0 0;
    }
  }
`;

export const EditItems = styled(Row)`
  padding: 0 0 20px 0;
  margin: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid #c0c0c0;
  }
`;

export const LessonsItem = styled(Checkbox)`
  background-color: #fff;
  color: #333;
  font-family: 'PoppinsRegular', sans-serif;
  font-size: 12px;
  line-height: 0.75;

  &:hover,
  &.active {
    color: #76d9ff;
  }
`;

export const ItemTitle = styled.h3`
  font-family: 'PoppinsBold', sans-serif;
  color: #7c7c7c;
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.2;
`;

export const IconAnt = styled(Icon)`
  cursor: pointer;
  font-size: 14px;
  vertical-align: top;
  margin: 0 5px;

  &:hover {
    color: #76d9ff;
  }
`;