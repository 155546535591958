const initialState = {
  instruments: [],
  locations: [],
  locationValue: null,
  instrumentValue: null
};

const mainsearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SEARCH':
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default mainsearchReducer;
