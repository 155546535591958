import { combineReducers } from 'redux'
import authReducer from "./auth";
import proficienciesReducer from "./proficiency";
import languagesReducer from "./languages";
import teachersReducer from "./teachers";
import instrumentsReducer from "./instruments";
import messagesReducer from "./messages";
import userReducer from "./user";
import rockstarsReducer from "./rockstars";
import mainsearchReducer from "./mainSearch";
import scheduleReducer from "./schedule";
import requestsReducer from "./requests";
import lessonsReducer from "./lessons";
import locationsReducer from "./locations";
import classTypesReducer from "./class_types";
import usersReducer from "./users";
import bookingReducer from "./booking";

export default combineReducers({
  auth: authReducer,
  proficiencies: proficienciesReducer,
  languages: languagesReducer,
  teachers: teachersReducer,
  instruments: instrumentsReducer,
  messages: messagesReducer,
  user: userReducer,
  rockstars: rockstarsReducer,
  search: mainsearchReducer,
  schedule: scheduleReducer,
  request: requestsReducer,
  lessons: lessonsReducer,
  locations: locationsReducer,
  class_types: classTypesReducer,
  users: usersReducer,
  booking: bookingReducer,
});
