import {useCallback, useContext, useEffect} from "react";
import {Avatar, Button, notification} from "antd";
import React from "react";
import {useDispatch, useMappedState} from "redux-react-hook";
import WSProvider from "../providers/io";

const useMessages = () => {

  const io = useContext(WSProvider);
  const dispatch = useDispatch();
  const messages = useMappedState((state) => state.messages);

  const onChooseRoom = useCallback((chat_id) => {
    dispatch({
      type: 'GET_DIALOG',
      payload: chat_id
    });
  }, [dispatch]);

  const notify = useCallback(({first_name, last_name, _id, avatar, msg, chat_id}) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button type="primary" size="small" onClick={() => onChooseRoom(chat_id)}>
        Respond
      </Button>
    );
    notification.open({
      message: first_name + ' ' + last_name,
      description: msg,
      btn,
      key,
      icon: <Avatar src={process.env.REACT_APP_PUBLIC_FILE_URL + '/' + _id + '/' + avatar}/>,
    });
  }, [onChooseRoom]);

  useEffect(() => {
    io.removeListener('new_message');
    io.on('new_message', (data) => {
      console.log(data);
      if (messages.rooms.filter((item) => item.member._id === data.sender).length > 0) {
        if (messages.dialog.member && messages.dialog.member._id === data.sender) {
          dispatch({
            type: 'ADD_MESSAGE',
            payload: {
              unread: true,
              message: data.message,
              createdAt: new Date(),
              sender: {
                avatar: messages.dialog.member.avatar,
                first_name: messages.dialog.member.first_name,
                last_name: messages.dialog.member.last_name,
                _id: messages.dialog.member._id
              },
            }
          })
        } else {
          dispatch({
            type: 'NOTIFY_NEW_MSG',
            payload: {
              message: data.message,
              id: data.sender
            },
            sideEffect: notify
          })
        }
      } else {
        dispatch({
          type: 'GET_ROOMS',
        })
      }
    })
  }, [io, messages, dispatch, notify]);

};

export default useMessages;
