
const initialState = {
  user: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return {
        user: action.payload
      };

    case 'UPDATE_USER_DATA':
      return {
        user: {
          ...state.user,
          ...action.payload
        }
      };
    case "WS_REGISTERED":
      return {
        user: {
          ...state.user,
          ws_registration: true
        }
      };
    case "REMOVE_AUTH_DATA":
      return initialState;
    case "ADD_USER_VIDEO":
      let addVideos = [...state.user.metadata.videos];
      addVideos.push(action.payload);
      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            videos: addVideos
          }
        }
      };
    case "DELETE_USER_VIDEO":
      const deleteVideos = [...state.user.metadata.videos];
      deleteVideos.splice(action.payload, 1);
      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            videos: deleteVideos
          }
        }
      };
    default:
      return state;
  }
};

export default authReducer;
