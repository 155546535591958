import styled from 'styled-components';
import { device } from "../../../../constants/device";
import { colGreen } from '../../../../constants/variables';
import { Images } from "../../../../assets/images";

export const BlockTitle = styled.h2`
    font-family: 'PoppinsThin', sans-serif;
    font-size: 60px;
    color: ${colGreen};
    line-height: 0.8;
    margin-bottom: ${props => props.marginBottom || "30px"};
    margin-top: ${props => props.marginTop || "20px"};

    @media ${device.laptop} {
        font-size: 120px;
    }
`;

export const FirstBlock = styled.div`
    background-color: #76d9ff;
    color: #fff;
    padding: 35px 30px;
    text-align: left;
    height: 100%;

    @media ${device.laptop} {
        margin-right: 8px;
        padding: 80px 70px 22px;
    }
`;

export const Message = styled.p`
    font-family: 'PoppinsThin', sans-serif;
    font-size: 38px;
    line-height: 1.2;
    margin-bottom: 15px;

    @media ${device.laptop} {
        font-size: 48px;
        margin-bottom: 30px;
    }
`;

export const SubMessage = styled.p`
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 18px;
    line-height: 1;
    margin-bottom: 65px;
    letter-spacing: 0.5px;
`;

export const BlockImg = styled.img.attrs({
        src: Images.ConcertImg
    })`

    width: 100%;
    height: 100%;

    @media ${device.laptop} {
        margin-left: 8px;
    }
`;
