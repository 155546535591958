import styled from 'styled-components';
import { Button, Calendar, Row } from 'antd';

export const BookingCalendar = styled(Calendar)`
`;

export const AntRow = styled(Row)`
   background-color: white;
   width: 100%;
`;

export const DateButtonWrapper = styled.div`
   padding: 5px;
`;

export const DateButton = styled(Button)`
   box-shadow: none;
   color: black;
   font-family: 'OdinRounded', sans-serif;
   
   &:hover, &:focus {
      color: gray;
   }
`;
