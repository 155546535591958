import React from "react";
import {AppLayout, HeaderFooterLayout, FooterLayout, LayoutContainer} from './routes';
import {Route, BrowserRouter as Router, Switch} from "react-router-dom";
import {
  CONTACTS_ROUTE,
  FAQ_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  ROOT_ROUTE,
  TEACHERSLIST_ROUTE,
  BENEFITS_ROUTE,
  PRIVACY_ROUTE,
  DISCLAIMER_ROUTE
} from "./constants/routes";
import {Contacts, FaqPage, Home, LoginPage, RegisterPage, TeachersList, TermsPage, Benefits, PrivacyPolicy, Disclaimer} from "./scenes";
import useWsChat from "./hooks/useWsChat";
import useMessages from "./hooks/useMessages";
import VerifyPage from "./scenes/VerifyPage/VerifyPage";
import RegisterThanksPage from "./scenes/RegisterThanksPage/RegisterThanksPage";
import useNotifications from "./hooks/useNotifications";
import AdminLayout from "./routes/AdminLayout";
import { message } from 'antd';
import RecoverPasswordPage from "./scenes/RecoverPasswordPage/RecoverPasswordPage";
import ScrollToTop from "./utilities/ScrollToTopRoute";

message.config({
  top: 20,
  duration: 2,
  maxCount: 1,
});

export const App = () => {
  useWsChat();
  useMessages();
  useNotifications();
  return (
  <Router>
    <ScrollToTop>
      <Switch>
        <LayoutContainer exact={true} path={DISCLAIMER_ROUTE} component={Disclaimer} layout={HeaderFooterLayout} />
        <LayoutContainer exact={true} path={PRIVACY_ROUTE} component={PrivacyPolicy} layout={HeaderFooterLayout} />
        <LayoutContainer exact={true} path={BENEFITS_ROUTE} component={Benefits} layout={HeaderFooterLayout} />
        <LayoutContainer exact={true} path={ROOT_ROUTE} component={Home} layout={HeaderFooterLayout} />
        <LayoutContainer exact={true} path={CONTACTS_ROUTE} component={Contacts} layout={HeaderFooterLayout} />
        <LayoutContainer exact={true} path={FAQ_ROUTE} component={FaqPage} layout={HeaderFooterLayout} />
        <LayoutContainer exact={true} path={TEACHERSLIST_ROUTE} component={TeachersList} layout={HeaderFooterLayout} />
        <Route exact={true} path={LOGIN_ROUTE} component={LoginPage} />
        <LayoutContainer exact={true} path={REGISTER_ROUTE} component={RegisterPage} layout={FooterLayout}/>
        <Route exact={true} path={`/verify/:token`} component={VerifyPage} />
        <LayoutContainer exact={true} path={'/terms-page'} component={TermsPage} layout={HeaderFooterLayout} />
        <LayoutContainer exact={true} path={`/recover-password/:token`} layout={HeaderFooterLayout} component={RecoverPasswordPage} />
        <Route exact={true} path={`/thanks`} component={RegisterThanksPage} />
        <Route path="/admin" component={AdminLayout}/>
        <Route path="/" component={AppLayout}/>
      </Switch>
    </ScrollToTop>
  </Router>

 )
};


// add_header X-Frame-Options SAMEORIGIN;
// add_header X-Content-Type-Options nosniff;
// add_header X-XSS-Protection "1; mode=block";
