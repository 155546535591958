import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Icon, Row } from "antd";
import { ButtonLink, Button } from "../../components";
import { Images } from "../../assets/images";
import { device } from "../../constants/device";
import { colGreen } from '../../constants/variables';

export const SideContent = styled.div`
    width: 100%;
    display: block;
    vertical-align: top;
    padding: 10px;

    @media ${device.tablet} {
      display: inline-block;
      width: 405px;
      padding-top: 530px;
    }

    @media ${device.laptop} {
      padding-right: 115px;
    }
`;

export const CentralContent = styled.div`
    width: 100%;
    display: block;
    padding: 10px;

    @media ${device.tablet} {
      width: calc(100% - 405px);
      display: inline-block;
      vertical-align: top;
      padding-bottom: 40px;
      padding-left: 50px;
      padding-right: 10px;
    }
`;

export const List = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;

  @media ${device.tablet} {
    width: 100%;
    display: block;
  }

  p {
    font-family: 'OdinRounded', sans-serif;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 5px;

    @media ${device.tablet} {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
`;

export const TeacherSmallInfo = styled(Row)`
  background-color: rgba(255, 255, 255, 0.85);
  padding: 15px;
  margin-bottom: 20px;

  @media ${device.laptop} {
    padding: 28px 24px;
    min-height: 190px;
  }
`;

export const TeacherName = styled(Col)`
  font-family: 'OdinRounded', sans-serif;
  font-size: 36px;
  line-height: 1;

  @media ${device.laptop} {
    font-size: 56px;
  }
`;

export const Price = styled.div`
  font-size: 48px;
  font-family: 'PoppinsThin', sans-serif;
  line-height: 1;

  span {
    font-size: 14px;
    font-family: 'PoppinsRegular', sans-serif;
    display: block;
    
    @media ${device.laptop} {
      display: inline;
    }
  }
`;

export const AdditionalInfo = styled(Col)`
  text-align: right;

  @media ${device.laptop} {
    padding-top: 25px;
  }
`;

export const RatingList = styled.div`
  display: inline-block;
  vertical-align: bottom;
  margin-right: 10px;
  
  @media ${device.laptop} {
    margin-right: 25px;
  }
`;

export const LocationImage = styled.div`
  background: url(${Images.LocationIcon}) no-repeat;
  height: 20px;
  width: 17px;
  background-size: contain;
  display: inline-block;
  vertical-align: bottom;
  margin-right: 10px;
  background-position: center;
`;

export const VideoIcon = styled.div`
  background: url(${Images.CameraIcon}) no-repeat;
  height: 20px;
  width: 15px;
  background-size: contain;
  display: inline-block;
  vertical-align: bottom;
  background-position: center;
`;
// background: url(${Images[`${props => props.country || ''}` + 'Flag']}) no-repeat;
export const Country = styled.div`
  
  background: url('${props =>  Images[props.country + 'Flag'] || ''}') no-repeat;
  height: 20px;
  width: 30px;
  background-size: contain;
  display: inline-block;
  vertical-align: bottom;
  margin-left: 10px;

  @media ${device.laptop} {
    margin-left: 50px;
  }
`;

export const RowAnt = styled(Row)`
  width: 100%;

  &.pl-additional-button {
    .BookingButton {
      width: 100%;
      text-align: center;

      @media ${device.laptop} {
        width: auto;
      }
    }
    .ContactButton {
      margin: 0 auto 10px;
      min-height: 32px;
      display: inline-block;
      vertical-align: top;
      width: 100%;

      @media ${device.laptop} {
        width: auto;
        padding: 10px 16px;
      }
    }
  }
`;

export const BookingButton = styled(ButtonLink)`
  width: 100%;

  @media ${device.laptop} {
    width: auto;
  }
`;

export const ContactButton = styled(Button)`
`

export const VideoContent = styled(Row)`
  margin-top: 20px;

  @media ${device.tablet} {
    margin-top: 80px;
  }

  h3 {
    font-family: 'OdinRounded', sans-serif;
    font-size: 36px;
    line-height: 1.2;
    color: ${colGreen};
    margin-bottom: 0;

    @media ${device.laptop} {
      font-size: 56px;
    }
  }

  p {
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 20px;
    color: #333;
    margin-bottom: 0;
  }
`;

export const UserPhoto = styled.div`
  height: 200px;
  width: 200px;
  background-color: #fff;
  border-radius: 50%;
  border: 8px solid #fff;
  position: relative;
  margin-bottom: 15px;

  @media ${device.tablet} {
    border-radius: 0 50% 50% 0;
    border: 8px solid #fff;
    position: absolute;
    left: 0;
    margin-bottom: 0;
    top: 0;
  }

  @media ${device.laptop} {
    height: 340px;
    width: 340px;
  }

  .user-photo {
    right: 0;
    margin: auto;
    border-radius: 50%;
    height: 185px;
    width: 185px;
    position: relative;
    z-index: 1;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    left: 0;
    overflow: hidden;
    
    @media ${device.tablet} {
      height: 180px;
      width: 180px;
      position: absolute;
    }

    img {
      object-fit: cover;
      transform: scale(1.2);
      width: 100%;
      height: 100%;
    }
  }
`;

export const VideoSlider = styled(Slider)`
  width: 100%;
  padding-top: 30px;
  height: 500px;

  @media ${device.laptop} {
    height: auto;
  }

  .slick-list {
    height: 500px;

    @media ${device.laptop} {
      height: auto;
    }   
  }

  .slick-arrow { 
    &.slick-prev:before,
    &.slick-next:before {
      color: #76d9ff;
      font-family: 'PoppinsBold', sans-serif;
      font-size: 35px;
    }
    
    &.slick-prev {
      left: 0;
      z-index: 1;
    }
    
    &.slick-next {
      right: 15px;
      z-index: 1;
    }
  }
  
  .slick-dots {
    text-align: right;
    bottom: -30px;
  }
  
  .slick-dots li {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 20px;
    color: #333;
    font-family: "PoppinsRegular", sans-serif;
    font-size: 10px;
    line-height: 1;
    padding: 10px 0;
    text-align: center;
  }
  
  .slick-dots li.slick-active {
    background-color: #beeb5b;
  }
`;

export const TimeLineContent = styled(Row)`
  margin: 40px 0;

  h3 {
    font-family: 'OdinRounded', sans-serif;
    font-size: 36px;
    color: ${colGreen};
    margin-bottom: 30px;
    line-height: 1.2;

    @media ${device.laptop} {
      font-size: 56px;
    }
  }
`;

export const IconAnt = styled(Icon)`
  color: ${colGreen};
  height: 12px;
  width: 13px;

  &:not(:last-child) {
    margin-right: 3px;
  }
`;

export const LessonsItem = styled.div`
  background-color: #fff;
  color: #333;
  font-family: 'PoppinsRegular', sans-serif;
  font-size: 12px;
  padding: 3px 8px;
  display: inline-block;
  line-height: 0.75;
  border-radius: 25px;
  margin-bottom: 5px;

  &:hover,
  &.active {
    color: #76d9ff;
  }

  &:not(:last-child) {
    margin-right: 5px;

    @media ${device.tablet} {
      margin-right: 10px;
    }
  }

  @media ${device.tablet} {
    font-size: 14px;
    padding: 6px 15px;
    margin-bottom: 14px;
  }
`;

export const VideoBlock = styled.div`
  
  position: relative
  
  & > * {
    margin: 0px auto;
    text-align: center;
    display: block;
  }
`;
