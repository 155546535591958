import styled from "styled-components";
import { Row } from 'antd';

export const RowAnt = styled(Row)`
    background-color: #fff;
    margin-bottom: 20px;

    .events {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .events .ant-badge-status {
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
        font-size: 12px;
    }

    .notes-month {
        text-align: center;
        font-size: 28px;
    }
    
    .notes-month section {
        font-size: 28px;
    }
`;