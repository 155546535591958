import React, {useCallback, useEffect, useState} from "react";
import { Upload, Icon, message } from 'antd';
import {useDispatch, useMappedState} from "redux-react-hook";

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

export function UploadAvatar() {
  const [isLoading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const dispatch = useDispatch();
  const handleChange = useCallback((info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
    }
  }, []);

  const user = useMappedState(state => state.auth).user;

  useEffect(() => {
    if (user.avatar) {
      setImageUrl(process.env.REACT_APP_PUBLIC_FILE_URL + '/' + user._id + '/' + user.avatar)
    }
  }, [user]);

  const updateAvatar = useCallback((file) => {
    setLoading(false);
    setImageUrl('');
    dispatch({
      type: "UPLOAD_AVATAR",
      payload: file.file
    });
  }, [dispatch, setLoading]);

  const uploadButton = (
    <div>
      <Icon type={isLoading ? 'loading' : 'plus'} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={updateAvatar}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    );
}
