import styled from "styled-components";
import { Images } from "../../../assets/images";

export const Item = styled.div`
    color: #333;
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 16px;
    position: relative;
    display: block;
    line-height: 1;
`;

export const Input = styled.input`
    &:checked,
    &:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    &:checked + label,
    &:not(:checked) + label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
    }

    &:checked + label:before,
    &:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 1px;
        width: 16px;
        height: 16px;
        border: 1px solid #ddd;
        border-radius: 100%;
    }

    &:checked + label:after,
    &:not(:checked) + label:after {
        content: '';
        width: 21px;
        height: 18px;
        top: 0;
        left: 0;
        background: url(${Images.ListItemIcon}) no-repeat;
        background-size: contain;
        position: absolute;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    &:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    
    &:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
`;