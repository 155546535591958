import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactsRow } from "./style";

export function Contacts() {
    const { t } = useTranslation();

    return (
        <ContactsRow>
            <h1>{t("contacts_page_title")}</h1>
        </ContactsRow>
    );
}
