import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Row } from "antd";
import { device } from "../../constants/device";

export const TeacherRow = styled(Row)`
  position: relative;
  padding-top: 0;

  @media ${device.laptopL} {
    padding-top: 40px;
  }
`;

export const RowCalendar = styled(Row)`
  background: #fff;
  padding: 15px 20px;

  .ant-alert {
    display: none;
  }
`;

export const SideContent = styled.div`
  width: 100%;
  display: block;
  vertical-align: top;
  padding: 10px;

  @media ${device.tablet} {
    display: inline-block;
    width: 405px;
    padding-top: 530px;
  }

  @media ${device.laptop} {
    padding-right: 115px;
  }
`;

export const CentralContent = styled.div`
  width: 100%;
  display: block;
  padding: 10px;

  @media ${device.tablet} {
    width: calc(100% - 405px);
    display: inline-block;
    vertical-align: top;
    padding-bottom: 40px;
    padding-left: 50px;
    padding-right: 10px;
  }
`;



export const UserPhoto = styled.div`
  height: 200px;
  width: 200px;
  background-color: #fff;
  border-radius: 50%;
  border: 8px solid #fff;
  position: relative;
  margin-bottom: 15px;

  @media ${device.tablet} {
    border-radius: 0 50% 50% 0;
    border: 8px solid #fff;
    position: absolute;
    left: 0;
    margin-bottom: 0;
    top: 0;
  }

  @media ${device.laptop} {
    height: 340px;
    width: 340px;
  }

  @media ${device.laptopL} {
  }

  // &:after {
  //   content: '';
  //   background-color: #fff;
  //   position: absolute;
  //   z-index: 1;
  //   width: 1000px;
  //   height: 315px;
  //   display: none;
  //   right: 13vw;
  //   top: -8px;

  //   @media ${device.laptopL} {
  //     display: block;
  //     height: 340px;
  //   }
  // }

  img {
    right: 0;
    margin: auto;
    border-radius: 100%;
    height: 185px;
    position: relative;
    z-index: 1;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    left: 0;

    @media ${device.tablet} {
      height: 180px;
      position: absolute;
    }
  }
`;
