import {AdminPanelLayout, LayoutContainer} from "./Layouts";

import {
  AdminAddNewUser,
  AdminPanel,
  AdminPanelPayment,
  AdminPanelUsers,
} from "../scenes";
import React, {useCallback, useEffect} from "react";
import {
  Redirect,
  Switch
} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import {message} from "antd";
import EditUser from "../scenes/AdminUserUpdate/EditUser";
const AdminLayout = ({history}) => {

  const {user} = useAuth(history);
  const notifyListener = useCallback((e) => {
    if (e.detail.redirectTo) {
      history.push(e.detail.redirectTo)
    }
    message[e.detail.type](e.detail.msg);
  }, [history]);

  useEffect(() => {
    document.addEventListener('notify', notifyListener);
    return () => {
      document.removeEventListener('notify', notifyListener)
    }
  }, [history, notifyListener]);
  return (
    user &&
    <Switch>
      {
        user && user.role_id && user.role_id.role === 'admin' ?
          (
            <>
              <LayoutContainer exact={true} path={'/admin'} component={AdminPanel} layout={AdminPanelLayout}/>
              <LayoutContainer exact={true} path={'/admin/payment'} component={AdminPanelPayment} layout={AdminPanelLayout}/>
              <LayoutContainer exact={true} path={'/admin/users'} component={AdminPanelUsers} layout={AdminPanelLayout}/>
              <LayoutContainer exact={true} path={'/admin/user/:id'} component={EditUser} layout={AdminPanelLayout}/>
              <LayoutContainer exact={true} path={'/admin/add-new'} component={AdminAddNewUser} layout={AdminPanelLayout}/>
            </>
          )
          : <Redirect to={`/`}/>
      }
    </Switch>
  );
};

export default AdminLayout;
