import React from "react";
import { Icon } from "antd";
import {
  AMenuItem,
  MenuItem,
  MenuList
} from './style';
import { useTranslation } from "react-i18next";

export function AdminNavigation() {
    const { t } = useTranslation();

    return (
        <MenuList>
            <li><MenuItem to={'/'}><Icon type="home" />{t("admin_menu1")}</MenuItem></li>
            <li><MenuItem to={'/admin/users'}><Icon type="contacts" />{t("admin_menu2")}</MenuItem></li>
            <li><AMenuItem href={'https://dashboard.stripe.com/dashboard'}><Icon type="pie-chart" />{t("admin_menu3")}</AMenuItem></li>
        </MenuList>
   );
}
