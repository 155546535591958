import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Icon, Row } from 'antd';
import {
    DISCLAIMER_ROUTE,
    PRIVACY_ROUTE
} from "../../../constants/routes";
import { Wrapper, SiteLogo, FooterMenuLink, FooterSocialLink } from "./style";
import { ReactComponent as WhiteLogo } from '../../../assets/svg/logo-white.svg';

export function Footer() {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Row type="flex" justify="space-between" className='pl-container'>
                <SiteLogo>
                    <WhiteLogo/>
                </SiteLogo>
                <Row type="flex" justify="end" align="middle">
                    <Col className="pl-menu_footer">
                        <FooterMenuLink to={DISCLAIMER_ROUTE}>{t('footer_menu_disclaimer')}</FooterMenuLink>
                        <FooterMenuLink to={PRIVACY_ROUTE}>{t('footer_menu_chat2')}</FooterMenuLink>
                    </Col>
                    <Col className="pl-sociallist pl-ml-6">
                        <FooterSocialLink to="">
                            <Icon type="facebook" theme="filled" />
                        </FooterSocialLink>
                        <FooterSocialLink to="">
                            <Icon type="instagram" />
                        </FooterSocialLink>
                    </Col>
                </Row>
            </Row>
        </Wrapper>
    );
}
