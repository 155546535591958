import React from "react";
import { withTranslation, useTranslation } from 'react-i18next';
import {
  Form,
  Icon,
  Input,
  Modal,
  Radio,
  Select
} from "antd";
import {
  LogoItem,
  FormInput,
  LoginForm,
  Gbutton,
  FBbutton,
  EnterViaSocial,
  ModalButton,
  ModalReturnButton,
  DatePickerAnt,
  LayoutAnt,
  RowAnt,
  PageTitle,
  AntRow,
  ValRole,
  FormInputEmail
} from "./style";
import { Container, Button as RegisterButton } from "../../components/atoms";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import useRegisterPage from "../../hooks/RegisterPage";
import { ReactComponent as LogoSvg } from "../../assets/svg/colorLogo.svg";


function RegisterPage({form, history}) {
  const { Option } = Select;
  const { t } = useTranslation();
  const {
    submitForm,
    responseGoogle,
    responseFacebook,
    user,
    validateRole,
    getFieldDecorator,
    //sliderSettings,
    handleChooseRole,
    handleChangeEmail,
    handleCancel,
    isShowModal,
    changeUserEmail,
    email,
    // birthday,
    onBirthdayChange
  } = useRegisterPage(form, history);

  const dateFormat = 'MM/DD/YYYY';

  return (
    <LayoutAnt>
      <RowAnt type="flex" justify="center">
        <Container>
          <LogoItem to={'/'}><LogoSvg/></LogoItem>
          <PageTitle>{t('registration_page_title')}</PageTitle>
          {/* {!user.role ?
            <RegisterRole handleChoosRole={handleChooseRole}/>
          :
            ( */}
              {/* <> */}
                <LoginForm layout="inline" onSubmit={submitForm}>
                  {/*<RegisterRole handleChoosRole={handleChooseRole}/>*/}
                  <div>
                    <Radio.Group onChange={(e) => {handleChooseRole(e.target.value)}}>
                      <Radio value={'pupil'}>
                        Register as student
                      </Radio>
                      <Radio value={'teacher'}>
                        Register as teacher
                      </Radio>
                    </Radio.Group>
                  </div>
                  <AntRow type="flex" justify="space-between">
                    <FormInput>
                      {getFieldDecorator('first_name', {
                        rules: [{ required: true, message: t("registration_form_text1") }],
                      })(
                        <Input
                          placeholder={t("registration_form_placeholder_firstname")}
                          name={'lol'}
                          required
                        />
                      )}
                    </FormInput>
                    <FormInput>
                      {getFieldDecorator('last_name', {
                        rules: [{ required: true, message: t("registration_form_text2") }],
                      })(
                        <Input
                          placeholder={t('registration_form_placeholder_lastname')}
                          required
                        />
                      )}
                    </FormInput>
                  </AntRow>
                  <AntRow type="flex" justify="center">
                    <FormInputEmail>
                      {getFieldDecorator('email', {
                        rules: [{ required: true, message: t("registration_form_text3") }],
                      })(
                        <Input
                          type="email"
                          placeholder={t('registration_form_placeholder_email')}
                          required
                        />
                      )}
                    </FormInputEmail>
                  </AntRow>
                  <AntRow type="flex" justify="space-between">
                    <FormInput>
                      {getFieldDecorator('password', {
                        rules: [
                          { required: true, message: t("registration_form_text5") },
                          { min: 6, message: t("registration_form_text6")}
                        ],
                      })(
                        <Input
                          type="password"
                          placeholder={t('registration_form_placeholder_password')}
                          required
                        />
                      )}
                    </FormInput>
                    <FormInput>
                      {getFieldDecorator('confirm-password', {
                        rules: [{ required: true, message: t("registration_form_text7")},
                          {
                            validator(rule, value, callback) {
                              if (form.getFieldsValue().password && value && form.getFieldsValue().password !== value) {
                                callback(t("registration_form_text8"))
                              }
                            }
                          }
                        ],
                      })(
                        <Input
                          type="password"
                          placeholder={t('registration_form_placeholder_passwordconfirm')}
                          required
                          disabled={!form.getFieldsValue().password}
                        />
                      )}
                    </FormInput>
                  </AntRow>
                  <AntRow type="flex" justify="space-between">
                    <FormInput>
                      <DatePickerAnt
                        placeholder={t('registration_form_placeholder_birthdaydate')}
                        // value={birthday}
                        onChange={onBirthdayChange}
                        format={dateFormat}
                      />
                    </FormInput>
                    <FormInput>
                      {getFieldDecorator('gender', {
                        rules: [{ required: true, message: t("registration_form_text4") }],
                      })(
                        <Select placeholder={t('registration_form_placeholder_gender')}>
                          <Option value="male">male</Option>
                          <Option value="female">female</Option>
                        </Select>
                      )}
                    </FormInput>
                  </AntRow>
                  <AntRow type="flex" justify="center">
                    <Form.Item>
                      <RegisterButton
                        htmlType="submit"
                        label={t('registration_form_registration_button')}
                        class="RegisterButton"
                      />
                    </Form.Item>
                  </AntRow>
                  
                  <EnterViaSocial>
                    <ValRole onClick={validateRole}>
                      <Gbutton
                        clientId="454356284057-54vr4g7rab5e6g3jdp2djhq7dkg5hmmv.apps.googleusercontent.com"
                        buttonText={t('registration_form_registration_button_google')}
                        onSuccess={responseGoogle}
                        className="social"
                        onClick={validateRole}
                        disabled={!user.role}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                      />
                    </ValRole>
                    <FacebookLogin
                      appId="1642454415886527"
                      fields="name, email, picture"
                      callback={responseFacebook}
                      render={renderProps => (
                        <FBbutton
                          isDisabled={!user.role}
                          onClick={(e) => { validateRole() && renderProps.onClick(e) }}
                          className="social"
                        >
                          <Icon type="facebook" style={{fontSize: 20, marginRight: 10}}/>
                          <span>{t('registration_form_registration_button_facebook')}</span>
                        </FBbutton>
                      )}
                    />
                  </EnterViaSocial>
                </LoginForm>

                <Modal
                  title={t('registration_form_placeholder_enteremail')}
                  visible={isShowModal}
                  onCancel={handleCancel}
                  footer={[
                    <ModalReturnButton key="back" onClick={handleCancel}>
                      {t('registration_form_placeholder_return')}
                    </ModalReturnButton>,
                    <ModalButton key="submit" htmlType="submit" type="primary" onClick={handleChangeEmail}>
                      {t('registration_form_placeholder_submit')}
                    </ModalButton>,
                  ]}
                >
                  <Form onSubmit={handleChangeEmail}>
                    <FormInput>
                      {getFieldDecorator('social-email', {
                        rules: [
                          {required: true, message: t("registration_form_enteremail")},
                          {
                            type: 'email', message: t("registration_form_emailnotvalid")
                          }]
                      })(
                        <Input
                          prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                          type="email"
                          placeholder={t('registration_form_placeholder_email')}
                          required
                          value={email}
                          onChange={changeUserEmail}
                        />
                      )}
                    </FormInput>
                  </Form>
                </Modal>
              {/* </Row>
            )
          } */}
        </Container>
      </RowAnt>
    </LayoutAnt>
  );
}

const Register = withTranslation()(RegisterPage);

export const regPage = Form.create({ name: 'register_form' })(Register);
