import React, {useCallback, useEffect, useState} from "react";
import { Calendar, Badge } from "antd";
import {
  Container,
} from "../../components";
import {
  RowCalendar,
} from "./style";
import moment from 'moment';
import {useDispatch, useMappedState} from "redux-react-hook";
import {StudentEventModal} from "../../components/molecules/EventDashboardComponents/AddEventModal/StudentEventModal";

export function StudentEventDashboard() {

  const dispatch = useDispatch();

  const [isShowModal, setShowModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [value, setValue] = useState(moment());
  const [events, setEvents] = useState([]);

  const {lessons} = useMappedState((state) => {
    return {
      user: state.auth,
      lessons: state.lessons
    }
  });

  useEffect(() => {
    dispatch({
      type: 'GET_STUDENT_LESSONS',
      payload: {}
    })
  }, [dispatch]);

  function showModal() {
    setShowModal(true)
  }

  function hideModal() {
    setShowModal(false)
  }

  function onPanelChange (val) {
    setValue(val);
  }

  const getEventsByDate = useCallback((date) => {
    // eslint-disable-next-line
    return lessons.filter((item) => {
      if (moment(item.start_time).date() === moment(date).date() && moment(item.start_time).month() === moment(date).month()) {
        return item;
      }
    });
  }, [lessons]);

  function dateCellRender(value) {
    const listData = getEventsByDate(value).map((item) => ({
      type: !item.comleted ? 'success' : 'error',
      content: moment(item.start_time).format('LT') + ' '
        + item.instrument.name + ' '
        + item.class_type.type + ' '
    })
    );
    return (
      <ul className="events">
        {listData.map((item, index) => (
          <li key={index}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  }

  const onSelect = useCallback((val) => {
    setSelectedValue(val);
    setEvents(getEventsByDate(val));
    setValue(val);
    showModal();
  }, [getEventsByDate]);




  return (
    <Container>
      <RowCalendar>
        <Calendar
          dateCellRender={dateCellRender}
          value={value}
          mode={'month'}
          onSelect={(value) => onSelect(value)}
          onPanelChange={(value) => onPanelChange(value)}
        />
      </RowCalendar>
      {selectedValue && <StudentEventModal
        date={selectedValue}
        events={events}
        isShowModal={isShowModal}
        hideModal={hideModal}
      />}
    </Container>
  );
}
