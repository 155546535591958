import {useCallback, useContext, useEffect, useState} from "react";
import WSProvider from "../../providers/io";
import {useDispatch, useMappedState} from "redux-react-hook";


const useChatPage = () => {

  const io = useContext(WSProvider);
  const dispatch = useDispatch();
  const messages = useMappedState((state) => state.messages);
  const user = useMappedState((state) => state.auth.user);
  const [message, setMessage] = useState('');

  useEffect(() => {
    dispatch({
      type: 'GET_ROOMS'
    })
  }, [dispatch]);
  const handleChangeMessage = useCallback(({target: {value}}) => {
    setMessage(value);
  }, [setMessage]);

  const onChooseRoom = useCallback((chat_id) => {
    dispatch({
      type: 'GET_DIALOG',
      payload: chat_id
    });
  }, [dispatch]);

  const markAsRead = useCallback(() => {
    dispatch({
      type: 'MARK_AS_READ',
      payload: messages.dialog.id
    })
  }, [messages.dialog.id, dispatch]);

  const sendMessage = useCallback((message) => {
    io.emit('new_message', {
      recipient: messages.dialog.member._id,
      message: message,
    });

    dispatch({
      type: 'ADD_MESSAGE',
      payload: {
        unread: false,
        message: message,
        createdAt: new Date(),
        sender: {
          avatar: user.avatar,
          first_name: user.first_name,
          last_name: user.last_name,
          _id: user._id
        },
      }
    });
    setMessage('');
  }, [io, messages, user, setMessage, dispatch]);



  return {
    handleChangeMessage,
    message,
    setMessage,
    messages,
    markAsRead,
    sendMessage,
    onChooseRoom,
    user
  }
};

export default useChatPage
