import { all, takeEvery, put } from 'redux-saga/effects'
import classTypesService from '../../services/request.service'
import notify from "../../utilities/Notify";

function* canBook({payload}) {
  try {
    const result = yield classTypesService.canBook(payload);
    yield put({
      type: 'SET_CAN_BOOK',
      payload: {
        canBook: result.success
      }
    })
  } catch(e) {
    notify.error(e)
  }
}

function* fetchDataWatcher() {
  yield takeEvery('GET_CAN_BOOK', canBook);
}

export function* bookingSaga() {
  yield all([fetchDataWatcher()])
}
