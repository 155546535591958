import { Button } from "../../components";
import styled from "styled-components";
import {
  Icon,
  Form,
  Row,
} from "antd";
import { device } from "../../constants/device";
import { colGreen } from '../../constants/variables';

export const RatingIconAnt = styled(Icon)`
  color: ${colGreen};
  height: 16px;
  width: 16px;

  @media ${device.tablet} {
    height: 18px;
    width: 20px;
  }

  &:not(:last-child) {
    margin-right: 20px;
  }
`;

export const RowAnt = styled(Row)`
  background-color: #fff;
  margin-bottom: 20px;
`;

export const BlockHeader = styled(Row)`
  width: 100%;
  padding: 20px 25px 15px;
  border-bottom: 2px solid #f9f9f9;
  
  p {
    font-family: 'OdinRounded', sans-serif;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1;

    @media ${device.tablet} {
      font-size: 20px;
    }
  }
`;

export const BlockText = styled(Row)`
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 14px;
    padding: 25px;

    @media ${device.tablet} {
      font-size: 18px;
    }
`;

export const TutorBlockBottom = styled(Row)`
  border-top: 2px solid #f9f9f9;
  padding: 24px 30px;
  align-items: center;
  background: #fff;
  margin: 0 5px;
  width: 100%;
`;

export const ButtonDashboard = styled(Button)`
    margin: auto;

    @media ${device.tablet} {
      margin: 0;
    }
`;

export const RatingList = styled.div`
    display: inline-block;
    vertical-align: bottom;
    margin: 0 0 10px;
    text-align: center;
    width: 100%;

    @media ${device.tablet} {
      margin: 0 25px 0 0;
      text-align: left;
      width: auto;
    }
`;

export const FormItemAnt = styled(Form.Item)`
  width: 100%;
  padding: 20px 20px 0;
  border-top: 2px solid #f9f9f9;
  
  .ant-input {
    border: 2px solid #d8d8d8;
    border-radius: 20px;
    font-family: "PoppinsRegular", sans-serif;
    padding: 8px 20px;
    line-height: 1;
    font-size: 14px;
    height: auto;

    @media ${device.tablet} {
      font-size: 16px;
    }
    
    &::placeholder {
      color: #d8d8d8;
    }
  }
`;
