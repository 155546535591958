import { all, takeEvery, put, takeLatest } from 'redux-saga/effects'
import requestService from '../../services/request.service'
import notify from "../../utilities/Notify";

function* getAllTo() {
  try {
    const requests = yield requestService.getRequestsTo();
    yield put({
      type: 'SET_REQUESTS',
      payload: requests
    })
  } catch(e) {
    notify.error(e)
  }
}

function* getAllFrom() {
  try {
    const requests = yield requestService.getRequestsFrom();
    yield put({
      type: 'SET_REQUESTS',
      payload: requests
    })
  } catch(e) {
    notify.error(e)
  }
}

function* acceptRequest(action) {
  try {
    yield requestService.acceptRequest(action.payload);
    const requests = yield requestService.getRequests();
    yield put({
      type: 'SET_REQUESTS',
      payload: requests
    })
  } catch (e) {
    notify.error(e)
  }
}

function* declineRequest(action) {
  try {
    yield requestService.declineRequest(action.payload);
    const requests = yield requestService.getRequests();
    yield put({
      type: 'SET_REQUESTS',
      payload: requests
    })
  } catch (e) {
    notify.error(e)
  }
}

function* createBook(action) {
  try {
    yield requestService.createRequest(action.payload);
    yield put({
      type: 'SET_CAN_BOOK',
      payload: {
        bookSuccess: true
      }
    })
  } catch (e) {
    notify.error(e)
  }
}

function* updateRequest(action) {
  try {
    yield requestService.updateRequest(action.payload)
  } catch (e) {
    notify.error(e)
  }
}

function* fetchDataWatcher() {
  yield takeLatest('CREATE_BOOK', createBook);
  yield takeEvery('GET_REQUESTS_TO', getAllTo);
  yield takeEvery('GET_REQUESTS_FROM', getAllFrom);
  yield takeEvery('ACCEPT_REQUEST', acceptRequest);
  yield takeEvery('DECLINE_REQUEST', declineRequest);
  yield takeEvery('UPDATE_REQUEST', updateRequest);
}

export function* requestsSaga() {
  yield all([fetchDataWatcher()])
}
