import styled from 'styled-components';
import { Row } from "antd";
import { Link } from 'react-router-dom';

export const RowHeader = styled(Row)`
   margin: -142px auto 0;
   padding: 10px;
   background: #101f32;
   width: 100%;
   color: #fff;
`;

export const AdminLogo = styled(Link)`
    width: 150px;

    img {
        width: 100%;
        height: auto;
    }
`;
