import React from "react";
import { RowAnt } from "./style";

export function AdminPanel() {
   return (
      <RowAnt type="flex">
         welcome to admin panel of 321 play
      </RowAnt>
   );
}
