import styled from "styled-components";
import { Layout, Form, Row, DatePicker } from "antd";
import { device } from "../../constants/device";
import { colGreen } from '../../constants/variables';
import { Images } from "../../assets/images";
import { GoogleLogin } from "react-google-login";
import { Link } from "react-router-dom";

export const AntRow = styled(Row)`
    width: 100%;
    margin: auto;

    @media ${device.laptopL} {
        width: 80%;    
    }
`;

export const LayoutAnt = styled(Layout)`
    margin-top: -142px;
`;

export const PageTitle = styled.p`
   font-family: 'PoppinsRegular',sans-serif;
   font-size: 42px;
   color: #fff;
   text-align: center;
`;

export const RowAnt = styled(Row)`
    background:
        radial-gradient(circle, transparent 40%, green 75%),
        linear-gradient(to right, blue, red),
        url(${Images.Unsplash2}) #f9f9f9;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: auto;
    background-blend-mode: multiply, screen, normal;

    @media ${device.tablet} {
        background-size: 100%;    
    }

    padding-bottom: 50px;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 390px;
        width: 100%;
        height: 100%;
        background: #fff;
    }
`;

export const FormInput = styled(Form.Item)`
    font-family: 'PoppinsBold', sans-serif;
    font-size: 16px;
    height: 47px;
    margin: 10px 10px 20px !important;
    
    &.ant-form-item {
        width: 45%;
    }

    .ant-form-item-control-wrapper {
        width: 100%;
        display: block !important;

        .has-feedback {
            display: block;
        }
    }
    
    @media ${device.maxTablet} {
        &.ant-form-item {
            width: 100%;
        }
    }
    
    .ant-select-selection {
        border-color: ${colGreen};
        border-radius: 30px;
        height: 46px;
        padding-left: 15px;
    }
    
    .ant-select-selection__rendered {
        line-height: 44px;
        width: 100%;
    }
   
    .green-wrapper {
        position: absolute;
        left: 0;
        top: -11px;
        width: 50px;
        height: 46px;
        background-color: ${colGreen};
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    }  
      
    .ant-input-affix-wrapper {
        overflow: hidden;
        border-radius: 30px;

        .ant-input:not(:first-child) {
            padding-left: 15px;
        }
    }

    .ant-input {
        border-color: ${colGreen};
        border-radius: 30px;
        height: 46px;
        padding: 4px 15px;
    }
`;

export const FormInputEmail = styled(Form.Item)`
    font-family: 'PoppinsBold', sans-serif;
    font-size: 16px;
    height: 47px;
    margin: 10px 10px 20px !important;

    &.ant-form-item {
        width: 100%;
    }

    .ant-form-item-control-wrapper {
        width: 100%;
    }

    .ant-select-selection {
        border-color: ${colGreen};
        border-radius: 30px;
        height: 46px;
        padding-left: 62px !important;
        width: 100%;
    }

    .ant-select-selection__rendered {
        line-height: 44px;
        width: 100%;
    }

    .green-wrapper {
        position: absolute;
        left: 0;
        top: -11px;
        width: 50px;
        height: 46px;
        background-color: ${colGreen};
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    }  
    
    .ant-input-affix-wrapper {
        overflow: hidden;
        border-radius: 30px;
        
        .ant-input:not(:first-child) {
            padding-left: 15px;
        }
    }

    .ant-input {
        border-color: ${colGreen};
        border-radius: 30px;
        height: 46px;
        padding: 4px 15px;
    }
`;

export const LogoItem = styled(Link)`
    display: block;
    height: 50px;
    width: 275px;
    background-size: contain;
    margin: 30px auto;

    svg {
        width: 100%;
        height: auto;
    }
`;

export const LoginForm = styled(Form)`
    display: block;
    padding: 20px 0;
    border-radius: 15px;
    background: #fff;
    width: 90%;
    text-align: center;
    margin: 0px auto;
    padding: 5px 10px;
    box-shadow: 1px 1px 10px 2px #e3e3e3;

    @media ${device.tablet} {
        padding: 20px 30px;
        width: 80vw; 
    }

    @media ${device.laptop} {
        padding: 40px;
        width: 65vw;
        max-width: 900px;
    }

    .RegisterButton {
        margin: 20px auto;
        min-width: 300px;
    }
`;

export const EnterViaSocial = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ValRole = styled.div`
    display: inline-block;
`;

export const Gbutton = styled(GoogleLogin)`
    display: flex;
    padding: 0;
    background-color: white;    
    align-items: center;
    border-radius: 20px;

    > div {
      display: flex;
      padding: 13px 0 13px 15px !important;
    }
`;

export const FBbutton = styled.button`
    display: flex;
    align-item: center;
    cursor: pointer;
    padding: 13px 15px;
    background-color: #397ed1;
    color: #fff;
    border-radius: 3px;
    border: none;
    margin-left: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;

    ${(props) => props.isDisabled && `
      opacity: 0.6;
    `}
`;

export const ModalButton = styled.button`
   padding: 10px 20px;
   background-color: ${colGreen};
   border: none;
   border-radius: 30px;
`;

export const ModalReturnButton = styled.button`
   padding: 10px 20px;
   background-color: #f36371;
   border: none;
   border-radius: 30px;
`;

export const DatePickerAnt = styled(DatePicker)`
    position: relative;
    width: 100%;

    .ant-calendar-picker-icon {
        display: none;
    }

    @media ${device.maxTablet} {
        &.ant-calendar-picker {
            width: 100%;
        }
    }
`;
