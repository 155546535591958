import React, {useCallback, useState} from "react";
import {useDispatch, useMappedState} from "redux-react-hook";
import {TeachingRequestsPage} from "./TeachingRequestsPage";
import {StudentRequestsPage} from "./StudentRequestsPage";
import {Navigation} from "../../components/molecules";
import {TeacherRow} from "./style";
import {Switch} from "antd";
import { useTranslation } from 'react-i18next';

const RequestPage = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useMappedState(state => state.auth).user;
  const [incomingDirection, setIncomingDirection] = useState(true);

  const onSwitchChange = useCallback((checked) => {
    dispatch({
      type: 'NULL_REQUESTS'
    });
    setIncomingDirection(checked);
  }, [dispatch]);

  return (

    <TeacherRow type="flex" justify="center">
      <Navigation
        pageName="request"
      />
      {
        user && user.role_id.role === 'teacher' &&
        <div>
          <div>
            <span>{t("request_page_outgoing")}</span>
            <Switch defaultChecked onChange={onSwitchChange} checked={incomingDirection}/>
            <span>{t("request_page_incoming")}</span>
          </div>
          {
            incomingDirection ?
              <TeachingRequestsPage /> :
              <StudentRequestsPage />
          }
        </div>
      }
      {
        user && user.role_id.role === 'pupil' &&
        <StudentRequestsPage />
      }

    </TeacherRow>
  );
};

export default RequestPage;
