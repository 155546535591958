import axios from '../utilities/Axios';

export default {
  getLocations: async () => (await axios.get('/locations')).data.data,
  searchLocation: async (location) => (
    await axios.get('/locations/list',
      {
        params: {
          name: location
        }
      }
    )
  ).data.data,
}

