import React, {useEffect} from "react";

import {useDispatch, useMappedState} from "redux-react-hook";
import TeacherContent from "./Teacher";
import StudentContent from "./Student";
import {withRouter} from "react-router-dom";

export const User = withRouter(({match}) =>  {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'GET_USER',
      payload: match.params.id
    })
  }, [match, dispatch]);

  const user = useMappedState((state) => state.user);

  return (
    <>
      {
        (user && user.role && user.role.role === 'teacher')
          ? <TeacherContent data={user}/>
          : <StudentContent data={user}/>
      }
    </>
  );
}
);
