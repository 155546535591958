import React from "react";
import { Item, Input } from "./style";


export function CheckboxButton(props) {
  return (
    <Item>
      <Input type="checkbox" />
      <label htmlFor={props.id}>{props.label}</label>
    </Item>
  );
}
