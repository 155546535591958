const initialState = [

];

const locationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOCATIONS':
      return initialState.concat(action.payload);
    default:
      return state;
  }
};

export default locationsReducer;
