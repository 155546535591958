import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { Container } from '../..';
import {
  REQUEST_PAGE,
  CHAT_ROUTE,
  EVENT_DASHBOARD,
  DASHBOARD_ROUTE,
  EDIT_ROUTE
} from "../../../constants/routes";
import { Wrapper, NavigationLink } from "./style";

export function StudentNavigation(props) {
  const { t } = useTranslation();

  function classactive (page) {
    if (page === props.pageName) {
      return 'active'
    } else {
      return ''
    }
  }

  return (
    <Wrapper>
      <Container>
        <Row type="flex" justify="space-between" align="middle">
          <Col span={20}>
            <NavigationLink to={DASHBOARD_ROUTE} className={classactive('dashboard')}>{t('navigation_link1')}</NavigationLink>
            <NavigationLink to={CHAT_ROUTE} className={classactive('chat')}>{t('menu_chat')}</NavigationLink>
            <NavigationLink to={REQUEST_PAGE} className={classactive('request')}>{t('navigation_link6')}</NavigationLink>
            <NavigationLink to={EVENT_DASHBOARD} className={classactive('event')}>{t('navigation_link5')}</NavigationLink>
            <NavigationLink to={EDIT_ROUTE} className={classactive('edit')}>{t("edit_button")}</NavigationLink>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}
