import { all } from 'redux-saga/effects'
import {authSaga} from "./auth";
import {proficienciesSaga} from "./proficiency";
import {languagesSaga} from "./languages";
import {teachersSaga} from "./teachers";
import {instrumentsSaga} from "./instruments";
import {messagesSaga} from "./messages";
import {userSaga} from "./user";
import {rockstarSaga} from "./rockstars";
import {mainSearchSaga} from "./mainSearch";
import {scheduleSaga} from "./schedule";
import {requestsSaga} from "./requests";
import {lessonsSaga} from "./lessons";
import {locationsSaga} from "./locations";
import {classTypessSaga} from "./class_types";
import {usersSaga} from "./users";
import {paymentSaga} from "./payment";
import {bookingSaga} from "./booking";

export default function* rootSaga() {
  yield all([
    authSaga(),
    proficienciesSaga(),
    languagesSaga(),
    teachersSaga(),
    instrumentsSaga(),
    messagesSaga(),
    userSaga(),
    rockstarSaga(),
    mainSearchSaga(),
    scheduleSaga(),
    requestsSaga(),
    lessonsSaga(),
    locationsSaga(),
    classTypessSaga(),
    usersSaga(),
    paymentSaga(),
    bookingSaga()
  ])
}
