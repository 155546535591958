import {useCallback, useContext, useEffect} from "react";
import {Avatar, notification} from "antd";
import React from "react";
import WSProvider from "../providers/io";

const useNotifications = () => {
  const io = useContext(WSProvider);

  const notify = useCallback((first_name, last_name, _id, avatar, msg) => {
    const key = `open${Date.now()}`;

    notification.open({
      message: first_name + ' ' + last_name,
      description: msg,
      key,
      icon: <Avatar src={process.env.REACT_APP_PUBLIC_FILE_URL + '/' + _id + '/' + avatar}/>,
    });
  }, []);

  useEffect(() => {
    io.removeListener('new_request');
    io.on('new_request', (data) => {
      console.log(data);
      notify(data.first_name, data.last_name, data._id, data.avatar || ' ', 'Send request for book your lesson');
    });
    io.removeListener('confirm_request');
    io.on('confirm_request', (data) => {
      notify(data.first_name, data.last_name, data._id, data.avatar || ' ', 'Confirm your request for book');
    });
    io.removeListener('reject_request');
    io.on('reject_request', (data) => {
      notify(data.first_name, data.last_name, data._id, data.avatar || ' ', 'Reject your request for book');
    });
    io.removeListener('cancel_book');
    io.on('cancel_book', (data) => {
      notify(data.first_name, data.last_name, data._id, data.avatar || ' ', 'Cancel book your book');
    });
  }, [io, notify]);
};

export default useNotifications;
