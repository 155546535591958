import { all, takeLatest, put } from 'redux-saga/effects'
import service from '../../services/user.service';
import notify from "../../utilities/Notify";

function* getUser({payload}) {
  try {
    const user = yield service.getUser(payload);
    const comments = yield service.getComments(payload);
    yield put({
      type: 'SET_USER',
      payload: {
        ...user,
        ...comments
      }
    })
  } catch (e) {
    notify.error(e)
  }
}

function* firstUpdateUser({payload}) {
  try {
    const user = yield service.firstUpdateUser(payload.data);
    yield put({
      type: 'SET_CURRENT_USER',
      payload: user
    });
    notify.success('Success')
  } catch (e) {
    notify.error(e)
  }
}

function* updateUser({payload}) {
  try {
    const user = yield service.updateUser(payload);
    yield put({
      type: 'SET_CURRENT_USER',
      payload: user
    });
    notify.success('Success')

  } catch (e) {
    notify.error(e)
  }
}

function* uploadAvatar({payload}) {
  try {
    const data = new FormData();
    data.append('avatar', payload);
    const user = yield service.uploadUserFiles(data);
    yield put({
      type: 'UPDATE_USER_DATA',
      payload: {
        avatar: user.avatar
      }
    })
  } catch (e) {
    notify.error(e)
   }
}

function* addVideo({payload}) {
  try {
    yield service.addVideo(payload);
    yield put({
      type: 'ADD_USER_VIDEO',
      payload: payload.video
    })
  } catch (e) {
    notify.error(e)
  }
}

function* deleteVideo({payload}) {
  try {
    yield service.deleteVideo(payload);
    yield put({
      type: 'DELETE_USER_VIDEO',
      payload: payload.index
    })
  } catch (e) {
    notify.error(e)
  }
}

function* fetchDataWatcher() {
  yield takeLatest('GET_USER', getUser);
  yield takeLatest('FIRST_UPDATE_USER', firstUpdateUser);
  yield takeLatest('UPDATE_USER', updateUser);
  yield takeLatest('UPLOAD_AVATAR', uploadAvatar);
  yield takeLatest('ADD_VIDEO', addVideo);
  yield takeLatest('DELETE_VIDEO', deleteVideo);
}

export function* userSaga() {
  yield all([fetchDataWatcher()])
}
