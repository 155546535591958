import styled from 'styled-components';
import { device } from "../../../../constants/device";

export const Row = styled.div`
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 70px;
    position: relative;
    cursor: pointer;
    align-items: center;
    background-color: ${props => props.unreadMessage > 0 ? 'rgba(102, 222, 130, 0.28)' : '#fff'}
    @media ${device.tablet} {
        flex-direction: row;
        height: 70px;
        padding: 15px;
        align-items: start;
    }
`;

export const UserImage = styled.img`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
`;

export const UserName = styled.p`
    display: flex;
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 16px;
    margin: 0px;
    padding: 5px;
    text-align: center;

    @media ${device.tablet} {
        font-size: 16px;
        text-align: left;
    }
`;

export const UnreadMessage = styled.span`
    position: absolute;
    display: block;
    right: 15px;
    bottom: 25px;
    background-color: #66de82;
    font-size: 10px;
    color: #fff;
    border-radius: 20px;
    padding: 3px;
`;
