import axios from '../utilities/Axios';

export default {
  getRequestsTo: async () => (await axios.get(`/booking/to`)).data.data,
  getRequestsFrom: async () => (await axios.get(`/booking/from`)).data.data,
  acceptRequest: async (_id) => (await axios.patch(`/booking/confirm/${_id}`)).data.data,
  declineRequest: async (_id) => (await axios.patch(`/booking/reject/${_id}`)).data.data,
  createRequest: async (data) => (await axios.post(`/booking`, data)).data.data,
  updateRequest: async (data) => (await axios.put(`/booking`, data)).data.data,
  canBook: async (_id) => (await axios.get(`/booking/${_id}`)).data
}

