import React, {useCallback, useState} from "react";
import { useMappedState} from "redux-react-hook";
import {TeacherEventDashboard} from "./TeacherEventDashboard";
import {StudentEventDashboard} from "./StudentEventDashboard";
import {Navigation} from "../../components/molecules";
import {TeacherRow} from "./style";
import Switch from "antd/es/switch";
import { useTranslation } from 'react-i18next';

export function EventDashboard() {
  const { t } = useTranslation();
  const user = useMappedState(state => state.auth).user;

  const [incomingDirection, setIncomingDirection] = useState(true);

  const onSwitchChange = useCallback((checked) => {
    setIncomingDirection(checked);
  }, []);

  return (
    <TeacherRow type="flex" justify="center">
      <Navigation
        pageName="event"
      />
      {
        user && user.role_id.role === 'teacher' &&
          <div>
            <div style={{
              marginTop: 30,
            }}>
              <span>{t("request_page_outgoing")}</span>
              <Switch defaultChecked onChange={onSwitchChange} checked={incomingDirection}/>
              <span>{t("request_page_incoming")}</span>
            </div>
            {
              incomingDirection ?
                <TeacherEventDashboard /> :
                <StudentEventDashboard />
            }
          </div>
      }
      {
        user && user.role_id.role === 'pupil' &&
          <StudentEventDashboard />
      }

    </TeacherRow>
  )
}
