import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';
import {
    Container
} from '../../components';
import { RowAnt, PageTitle, CustomList } from "./style";


export function PrivacyPolicy() {
    const { t } = useTranslation();

    return (
        <RowAnt type="flex">
            <Container>
                <Row type="flex">
                    <PageTitle>{t('Privacy Policy')}</PageTitle>
                </Row>
                <Row type="flex">
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_text3')}} />
                </Row>
                <Row type="flex">
                    <h3>{t('privacy_policy_list_title1')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_list_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_list_text11')}} />
                    <CustomList>
                        <li>
                            <h4>{t('privacy_policy_list_point1')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_list_point_text1')}} />
                        </li>
                        <li>
                            <h4>{t('privacy_policy_list_point2')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_list_point_text2')}} />
                        </li>
                        <li>
                            <h4>{t('privacy_policy_list_point3')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_list_point_text3')}} />
                        </li>
                        <li>
                            <h4>{t('privacy_policy_list_point4')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_list_point_text4')}} />
                        </li>
                        <li>
                            <h4>{t('privacy_policy_list_point5')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_list_point_text5')}} />
                        </li>
                        <li>
                            <h4>{t('privacy_policy_list_point6')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_list_point_text6')}} />
                        </li>
                        <li>
                            <h4>{t('privacy_policy_list_point7')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_list_point_text7')}} />
                        </li>
                        <li>
                            <h4>{t('privacy_policy_list_point8')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_list_point_text8')}} />
                        </li>
                        <li>
                            <h4>{t('privacy_policy_list_point9')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_list_point_text9')}} />
                        </li>
                        <li>
                            <h4>{t('privacy_policy_list_point10')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_list_point_text10')}} />
                        </li>
                        <li>
                            <h4>{t('privacy_policy_list_point11')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_list_point_text11')}} />
                        </li>
                    </CustomList>

                    <h3>{t('privacy_policy_list_title2')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_list_title21')}} />
                    <CustomList>
                        <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_address1')}} /></li>
                        <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_address2')}} /></li>
                        <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_address3')}} /></li>
                        <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_address4')}} /></li>
                        <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_address5')}} /></li>
                        <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_address6')}} /></li>
                        <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_address7')}} /></li>
                    </CustomList>

                    <h3>{t('privacy_policy_cookies')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_cookies_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_cookies_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_cookies_text3')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_cookies_text4')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_cookies_text5')}} />

                    <h3>{t('privacy_policy_collection')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_collection_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_collection_text2')}} />

                    <h3>{t('privacy_policy_registration')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_registration_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_registration_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_registration_text3')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_registration_text4')}} />

                    <h3>{t('privacy_policy_subscription')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_subscription_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_subscription_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_subscription_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_subscription_text1')}} />

                    <h3>{t('privacy_policy_newsletter')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_newsletter_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_newsletter_text2')}} />                    

                    <h3>{t('privacy_policy_contact')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_contact_text1')}} />

                    <h3>{t('privacy_policy_routine')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_routine_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_routine_text2')}} />

                    <h3>{t('privacy_policy_rights')}</h3>
                    <CustomList>
                        <li>
                            <h4>{t('privacy_policy_rights_list_point1')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text1')}} />
                        </li>
                        <li>
                            <h4>{t('privacy_policy_rights_list_point2')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text2')}} />
                            <CustomList>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text21')}} /></li>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text22')}} /></li>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text23')}} /></li>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text24')}} /></li>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text25')}} /></li>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text26')}} /></li>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text27')}} /></li>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text28')}} /></li>
                            </CustomList>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text29')}} />
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text210')}} />
                        </li>
                        <li>
                            <h4>{t('privacy_policy_rights_list_point3')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text31')}} />
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text32')}} />
                        </li>
                        <li>
                            <h4>{t('privacy_policy_rights_list_point4')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text4')}} />
                            <CustomList>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text41')}} /></li>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text42')}} /></li>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text43')}} /></li>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text44')}} /></li>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text45')}} /></li>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text46')}} /></li>
                            </CustomList>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point41')}} />
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point42')}} />
                        </li>
                        <li>
                            <h4>{t('privacy_policy_rights_list_point5')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text5')}} />
                            <CustomList>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text51')}} /></li>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text51')}} /></li>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text52')}} /></li>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text53')}} /></li>
                                <li><p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text54')}} /></li>
                            </CustomList>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text55')}} />
                        </li>
                        <li>
                            <h4>{t('privacy_policy_rights_list_point6')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text6')}} />
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text61')}} />
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text62')}} />
                        </li>
                        <li>
                            <h4>{t('privacy_policy_rights_list_point7')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text7')}} />
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text71')}} />
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text72')}} />
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text73')}} />
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text74')}} />
                        </li>
                        <li>
                            <h4>{t('privacy_policy_rights_list_point8')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text8')}} />
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text81')}} />
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text82')}} />
                        </li>
                        <li>
                            <h4>{t('privacy_policy_rights_list_point9')}</h4>
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text9')}} />
                            <p dangerouslySetInnerHTML={{__html: t('privacy_policy_rights_list_point_text91')}} />
                        </li>
                    </CustomList>

                    <h3>{t('privacy_policy_protection')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_text')}} />

                    <h3>{t('privacy_policy_protection_facebook')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_facebook_text')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_facebook_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_facebook_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_facebook_text3')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_facebook_text4')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_facebook_text5')}} />

                    <h3>{t('privacy_policy_protection_getty')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_getty_text')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_getty_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_getty_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_getty_text3')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_getty_text4')}} />

                    <h3>{t('privacy_policy_protection_google_adsense')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_adsense_text')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_adsense_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_adsense_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_adsense_text3')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_adsense_text4')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_adsense_text5')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_adsense_text6')}} />

                    <h3>{t('privacy_policy_protection_google_analytics')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_analytics_text')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_analytics_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_analytics_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_analytics_text3')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_analytics_text4')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_analytics_text5')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_analytics_text6')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_analytics_text7')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_analytics_text8')}} />

                    <h3>{t('privacy_policy_protection_google_remarketing')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_remarketing_text')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_remarketing_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_remarketing_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_remarketing_text3')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_remarketing_text4')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_remarketing_text5')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_remarketing_text6')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_remarketing_text7')}} />

                    <h3>{t('privacy_policy_protection_google_plus')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_plus_text')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_plus_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_plus_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_plus_text3')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_plus_text4')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_plus_text5')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_plus_text6')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_plus_text7')}} />

                    <h3>{t('privacy_policy_protection_google_adwords')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_adwords_text')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_adwords_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_adwords_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_adwords_text3')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_adwords_text4')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_adwords_text5')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_adwords_text6')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_adwords_text7')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_google_adwords_text8')}} />

                    <h3>{t('privacy_policy_protection_istagram')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_istagram_text')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_istagram_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_istagram_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_istagram_text3')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_istagram_text4')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_istagram_text5')}} />

                    <h3>{t('privacy_policy_protection_linkedin')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_linkedin_text')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_linkedin_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_linkedin_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_linkedin_text3')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_linkedin_text4')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_linkedin_text5')}} />

                    <h3>{t('privacy_policy_protection_pinterest')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_pinterest_text')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_pinterest_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_pinterest_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_pinterest_text3')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_pinterest_text4')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_pinterest_text5')}} />

                    <h3>{t('privacy_policy_protection_twitter')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_twitter_text')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_twitter_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_twitter_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_twitter_text3')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_twitter_text4')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_twitter_text5')}} />

                    <h3>{t('privacy_policy_protection_youtube')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_youtube_text')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_youtube_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_youtube_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_youtube_text3')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_youtube_text4')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_youtube_text5')}} />

                    <h3>{t('privacy_policy_protection_klarna')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_klarna_text')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_klarna_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_klarna_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_klarna_text3')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_klarna_text4')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_klarna_text5')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_klarna_text6')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_klarna_text7')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_klarna_text8')}} />

                    <h3>{t('privacy_policy_protection_paypal')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_paypal_text')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_paypal_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_paypal_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_paypal_text3')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_paypal_text4')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_paypal_text5')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_paypal_text6')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_paypal_text7')}} />

                    <h3>{t('privacy_policy_protection_skrill')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_skrill_text')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_skrill_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_skrill_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_skrill_text3')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_skrill_text4')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_skrill_text5')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_skrill_text6')}} />

                    <h3>{t('privacy_policy_protection_payment_method')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_payment_method_text')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_payment_method_text1')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_payment_method_text2')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_payment_method_text3')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_payment_method_text4')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_payment_method_text5')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_payment_method_text6')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_payment_method_text7')}} />

                    <h3>{t('privacy_policy_protection_legal_processing')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_legal_processing_text')}} />

                    <h3>{t('privacy_policy_protection_legitimate_interests')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_protection_legitimate_interests_text')}} />

                    <h3>{t('privacy_policy_period_data')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_period_data_text')}} />

                    <h3>{t('privacy_policy_provision_personal')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_provision_personal_text')}} />

                    <h3>{t('privacy_policy_existence')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_existence_text')}} />
                    <p dangerouslySetInnerHTML={{__html: t('privacy_policy_existence_text1')}} />
                </Row>
            </Container>
        </RowAnt>
    );
}
