import { all, takeEvery, put } from 'redux-saga/effects'
import teacherService from '../../services/teacher.service'
import notify from "../../utilities/Notify";

function* getAll() {

  try {
    const query = {
      skip: 0,
      sort: {
        rate: 1
      },
      limit: 19
    };
    const teachers = yield teacherService.getTeachers(query);
    yield put({
      type: 'SET_ROCKSTARS',
      payload: teachers
    })
  } catch(e) {
    notify.error(e)
  }
}

function* fetchDataWatcher() {
  yield takeEvery('GET_ROCKSTARS', getAll);
}

export function* rockstarSaga() {
  yield all([fetchDataWatcher()])
}
