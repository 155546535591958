import styled from "styled-components";
import { Link } from "react-router-dom";
import { Icon } from "antd";
import { colLightBlueText } from "../../../constants/variables";

export const UserMenu = styled(Link)`
  margin-right: 10px;
  color: #7c7c7c;
  font-size: 20px;
`;

export const Logo = styled(Link)`
  img {
    width: 200px;
    height: auto;
  }
`;

export const Wrapper = styled.div`
  background: #fff;
  height: 60px;
  position: relative;
  padding: 10px;

  &.pl-sticky {
    top: 0;
    position: fixed;
    width: 100%;
    margin: auto;
    z-index: 99;
    box-shadow:0 2px 6px rgba(0,0,0,0.2);  
    padding: 4px 0 0;
    animation:slide-down 0.7s;
  }

  &.pl-sticky + .ant-row-flex {
    padding-top: 142px;
    transition: padding 1s ease;
  }

  @keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    } 
    100% {
        opacity: 0.9;
        transform: translateY(0);
    } 
}
`;

export const HotLink = styled.div`
  justify-content: space-around;
  display: flex;
`;

export const LogoutLink = styled(Icon)`
  font-size: 20px;
  color: #7c7c7c;

  svg:hover {
    fill: ${colLightBlueText};
  }
`;