import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { Row } from "antd";
import { device } from "../../constants/device";
import { colGreen } from '../../constants/variables';

export const LessonItem = styled.div`
  background-color: #fff;
  color: #333;
  font-family: 'PoppinsRegular', sans-serif;
  font-size: 14px;
  padding: 6px 15px;
  display: inline-block;
  line-height: 0.75;
  border-radius: 25px;
  margin-bottom: 14px;

  &:not(:last-child) {
    margin-right: 5px;

    @media ${device.tablet} {
      margin-right: 10px;
    }
  }
`;

export const SideContent = styled.div`
  width: 100%;
  display: block;
  vertical-align: top;
  padding: 0 10px;

  @media ${device.tablet} {
    display: inline-block;
    width: 35vw;
    padding: 220px 30px 0 0;
  }

  @media ${device.laptop} {
    width: 405px;
    padding: 370px 115px 0 0;
  }
`;

export const CentralContent = styled.div`
  width: 100%;
  display: block;
  vertical-align: top;
  padding: 0 10px 40px;

  @media ${device.tablet} {
    width: 65vw;
    display: inline-block;
  }

  @media ${device.laptop} {
    width: calc(100% - 405px);
  }
`;

export const List = styled.div`
  width: 100%;
  display: block;
  padding: 10px;

  @media ${device.laptopL} {
    padding: 0;
  }

  p {
    font-family: 'OdinRounded', sans-serif;
    font-size: 22px;
  }
`;

export const TeacherRow = styled(Row)`
  position: relative;
  padding-top: 0;

  @media ${device.laptopL} {
    padding-top: 100px;
  }
`;

export const UserPhoto = styled.div`
  height: 200px;
  width: 200px;
  background-color: #fff;
  border-radius: 50%;
  border: 8px solid #fff;
  position: relative;
  margin-bottom: 15px;

  @media ${device.tablet} {
    border-radius: 0 50% 50% 0;
    border: 8px solid #fff;
    position: absolute;
    left: 0;
    margin-bottom: 0;
    top: 0;
  }

  @media ${device.laptop} {
    height: 340px;
    width: 340px;
  }

  .user-photo {
    border-radius: 50%;
    height: 185px;
    width: 185px;
    right: 0;
    margin: auto;
    position: relative;
    z-index: 1;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    left: 0;
    overflow: hidden;

    @media ${device.tablet} {
      height: 180px;
      width: 180px;
      position: absolute;
    }

    img {
      width: 100%;
      height: 100%;
      margin: auto;
      object-fit: cover;
      transform: scale(1.2);
    }
  }
`;

export const Title = styled.p`
  font-family: 'PoppinsRegular', sans-serif;
  font-size: 18px;
  color: #333;
`;

export const RowAnt = styled(Row)`
  background-color: #fff;
  margin-bottom: 20px;

  &:not(:nth-child(2n+2)) {
    margin-right: 0;

    @media ${device.laptop} {
      margin-right: 10px;
    }
  }
`;

export const BlockHeader = styled(Row)`
  width: 100%;
  padding: 20px 25px 15px;
  border-bottom: 2px solid #f9f9f9;
  color: ${colGreen};

  p {
    font-family: 'OdinRounded', sans-serif;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1;
    
    @media ${device.laptop} {
      font-size: 20px;
    }
  }
`;

export const BlockSubHeader = styled(Row)`
  width: 100%;
  padding: 20px 25px 15px;
  border-bottom: 2px solid #f9f9f9;

  p {
    color: #858585;
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 12px;
    margin-bottom: 0;
    line-height: 1.2;
  }
`;

export const BlockText = styled(Row)`
  font-family: 'PoppinsRegular', sans-serif;
  font-size: 18px;
  padding: 25px;
`;

export const DateWrapper = styled.div`
  padding: 20px;
  width: 100%;
`;

export const DateDisplay = styled.p`
  font-family: 'PoppinsThin', sans-serif;
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 1;

  @media ${device.tablet} {
    font-size: 24px;
    margin-bottom: 15px;
  }

  @media ${device.laptop} {
    font-size: 36px;
  }
`;

export const PlanList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 10px;

    @media ${device.laptopL} {
      margin-bottom: 0;
    }
  }

  .pl-plan-time {
    width: 55px;
    margin-bottom: 0;
  }
  
  .pl-plan-title {
    width: 70%;
    margin-bottom: 0;

    @media ${device.tablet} {
      width: 65%;
    }

    @media ${device.laptopL} {
      width: 245px;
    }
  }

  .pl-plan-location {
    margin-right: 10px;
    
    @media ${device.laptopL} {
      margin-right: 18px;
    }

    img {
      height: 10px;
      width: 8px;
    }
  }   
`;

export const SecondTitle = styled.p`
  color: #333;
  font-family: 'PoppinsRegular', sans-serif;
  font-size: 16px;
`;

export const NotesWrapper = styled.div`
  padding: 20px;
  width: 100%;
`;

export const NotesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  .pl-note-title {
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 0;
    width: 90%;

    @media ${device.laptopL} {
      width: auto;
    }
  }

  .pl-note-status {
    line-height: 1;
    img {
      height: 10px;
      width: 11px;
    }
  }   
`;