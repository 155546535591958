import notify from "../../utilities/Notify";

import { all, takeEvery, put } from 'redux-saga/effects'
import locationService from '../../services/location.service'

function* getAll(action) {
  try {
    const locations= yield locationService.getLocations();
    yield put({
      type: 'SET_LOCATIONS',
      payload: locations
    })
  } catch(e) {
    notify.error(e)
  }
}

function* fetchDataWatcher() {
  yield takeEvery('GET_LOCATIONS', getAll);
}

export function* locationsSaga() {
  yield all([fetchDataWatcher()])
}
