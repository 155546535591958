import styled from 'styled-components';
import { Row } from "antd";
import { device } from "../../constants/device";

export const ChatRow = styled(Row)`
  position: relative;
  padding-top: 0;

  @media ${device.laptopL} {
    padding-top: 100px;
  }
`;

export const ChatContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto;
    border-top: 1px solid #f5f5f5;
`;

export const SelectDialogText = styled.p`
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 28px;
    text-align: center;
    color: #a7a7ad;
    display: inline-block;
    width: 100%;
`;

export const MessageRow = styled.div`
    display: flex;
    width: 75%;
    background-color: #fff;
    align-items: center;
`;
