export const ROOT_ROUTE = "/";
export const ADMIN_PANEL_ROUTE = "/admin";
export const ADMIN_USER_LIST_ROUTE = "/admin-users";
export const ADMIN_PAYMENT_LIST_ROUTE = "/admin-payments";
export const ADMIN_ADD_USER_ROUTE = "/admin-add-user";
export const BENEFITS_ROUTE = "/benefits";
export const PRIVACY_ROUTE = "/privacy-policy";
export const DISCLAIMER_ROUTE = "/disclaimer"; 
export const BOOKING_ROUTE = "/booking/:id";
export const CHAT_ROUTE = "/chat";
export const FORUM_ROUTE = "/forum/index.html#/";
export const CONTACTS_ROUTE = "/contacts";
export const DASHBOARD_ROUTE = "/dashboard";
export const FAQ_ROUTE = "/faq";
export const LOGIN_ROUTE = "/login";
export const TEACHER_ROUTE = "/user/:id";
export const STUDENT_ROUTE = "/student"; // переведена на TEACHER_ROUTE на rout пользователя
export const TEACHERSLIST_ROUTE = "/teacherslist";
export const TUTORBUCHUNG_ROUTE = "/tutorbuchung";
export const REGISTER_ROUTE = "/registration";
// export const ROOT_PAY = "/paypage";
export const ROOT_TERMS = "/termspage";
export const EVENT_DASHBOARD = "/event-list";
export const REQUEST_PAGE = "/request-page";
export const EDIT_ROUTE = "/edit-profile";

