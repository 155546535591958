import WSProvider from "../providers/io";
import {useCallback, useContext, useEffect} from "react";
import {useDispatch, useMappedState} from "redux-react-hook";


const useWsChat = () => {


  const io = useContext(WSProvider);


  const user = useMappedState((state) => {
    return state.auth.user
  });


  const dispatch = useDispatch();

  const register = useCallback(() => {
    if(user && !user.ws_registration) {
      io.emit('register', user._id)
    }
  }, [user, io]);


  if (user && user._id) {
    io.on('connect', register);
  }


  useEffect(() => {
    register();
  }, [register]);



  io.on('registration', () => {
    dispatch({
      type: 'WS_REGISTERED'
    })
  })


};

export default useWsChat;
