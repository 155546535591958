import React, {useCallback, useEffect, useReducer, useState} from "react";
import {Row, Icon, Tooltip, Popconfirm} from "antd";
import { useTranslation } from "react-i18next";
import {
   PageTitle,
   TableAnt,
   LinkAddUser,
   ActionLink,
   Action
} from "./style";
import { ADMIN_ADD_USER_ROUTE } from "../../constants/routes";
import {useDispatch, useMappedState} from "redux-react-hook";
import moment from "moment";

export function AdminPanelUsers() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {users, total} = useMappedState(state => state.users);
  const locations = useMappedState(state => state.locations);

  const paginationReducer = (state, action) => {
    switch (action.type) {
      case 'SET_USERS_ADMIN_PAGINATION':
        return {...state, ...action.payload};
      default:
        return state;
    }
  };

  const initialPagination = {
    pageSize: 10,
    current: 1,
    total: 10
  };

  const [pagination, paginationDispatch]= useReducer(paginationReducer, initialPagination);

  const [sort, setSort] = useState({});
  const [filters, setFilteres] = useState({});


  useEffect(() => {
    dispatch({
      type: "GET_ALL_USERS",
      payload: {
        page: pagination.current,
        filters,
        sort
      }
    })
    // eslint-disable-next-line
  }, [dispatch, pagination.current, sort, filters]);

  const deleteUser = useCallback((id) => {
    dispatch({
      type: "DELETE_USER",
      payload: id
    });
    dispatch({
      type: "GET_ALL_USERS",
      payload: {
        page: pagination.current,
        filters,
        sort
      }
    })
    // eslint-disable-next-line
  }, [dispatch, pagination.current, sort, filters]);


  useEffect(() => {
    dispatch({
      type: "GET_LOCATIONS"
    })
  }, [dispatch]);

  useEffect(() => {
    paginationDispatch({
      type: 'SET_USERS_ADMIN_PAGINATION',
      payload: {total}
    })
  }, [total]);


  const onTableChange = useCallback(({current}, filter, sort) => {
    paginationDispatch({
      type: 'SET_USERS_ADMIN_PAGINATION',
      payload: {current}
    });
    setFilteres(filter);
    if (sort && Object.keys(sort).length > 0) {
      setSort({[sort.column.key]: sort.order === 'ascend' ? 1 : -1})
    }
  }, []);

  const banUser = useCallback((_id) => {
    dispatch({
      type: 'BAN_USER',
      payload: _id
    })
  }, [dispatch]);

  const columns = [
    {
      title: 'Name',
      render: (data) => <ActionLink className={`pl-user-name ${data.ban ? 'pl-banned' : ''}`} to={`/user/${data._id}`}>{data.first_name + ' ' + data.last_name}</ActionLink>,
      key: 'name'
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role.role',
      sorter: true,
      render: (data) => <span>{data.role}</span>,
    },
    {
      title: 'Status',
      render: (data) => {
        return (
          data.status ?
            <span>{data.status.status}</span> :
            <span></span>
        )
      },
      sorter: true,
      key: 'user_metadata.status.status',
      // filters: [{ text: 'Common', value: 'common' }, { text: 'Premium', value: 'premium' }],
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      sorter: true,
      render: (data) => <span>{new moment(data).format('LL')}</span>,
    },
    {
      title: 'location',
      key: 'user_metadata.location._id',
      dataIndex: 'location',
      sorter: true,
      filters: locations.map((item) => {return({text: (item.country + ' ' + item.location), value: item._id})}),
      render: (data) => <span>{data ? data.country  + ', ' + data.location : null}</span>
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      sorter: true,
      key: 'gender',
    },
    {
      title: 'Actions',
      className: 'pl-action-button',
      render: (data) => (
        <>
          <Tooltip title={t('tooltip_edit_user')}>
            <ActionLink to={'/admin/user/' + data._id}>
              <Icon type="edit" />
            </ActionLink>
          </Tooltip>
          <Popconfirm placement="top" title={'Are you sure'} onConfirm={ () => deleteUser({_id: data._id})} okText="Yes" cancelText="No">
            <Tooltip title={t('tooltip_delete_user')}>
              <Action>
                <Icon type="delete" />
              </Action>
            </Tooltip>
          </Popconfirm>
          <Popconfirm placement="top" title={'Are you sure'} onConfirm={ () => banUser({_id: data._id})} okText="Yes" cancelText="No">
            <Tooltip title={data.ban ? t('tooltip_unban_user') : t('tooltip_ban_user')}>
              <Action className={data.ban ? 'pl-banned' : ''}>
                <Icon type="stop" />
              </Action>
            </Tooltip>
          </Popconfirm>
        </>
      )
    },
  ];


   return (
      <Row type="flex">
         <PageTitle type="flex" justify="space-between">
            <span></span>
            <LinkAddUser to={ADMIN_ADD_USER_ROUTE}><Icon type="plus" />{t("admin_add_user_link")}</LinkAddUser>
         </PageTitle>
         <TableAnt
            columns={columns}
            rowKey={user => user._id}
            dataSource={users}
            onChange={onTableChange}
            pagination={pagination}
         />
      </Row>
   );
}
