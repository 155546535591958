import {
  AdditionalInfo,
  CentralContent, ContactButton, Country, IconAnt, RatingList,
  RowAnt,
  SideContent,
  TeacherName,
  TeacherSmallInfo,
  UserPhoto,
} from "./style";
import {BackgroundRow, Container} from "../../components/atoms";
import {Col} from "antd";
import {FeedbackSlider, TextBlock} from "../../components/molecules";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useMappedState} from "redux-react-hook";
import {withRouter} from "react-router-dom";


const StudentContent = ({data, match}) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useMappedState((state) => state.auth);

  console.log(data);

  const addDialog = useCallback(() => {
    dispatch({
      type: 'ADD_NEW_DIALOG',
      payload: [user.user._id, match.params.id]
    })
  }, [dispatch, user, match]);

  function ratingList(rating) {
    let row = [];
    for (let i = 0; i < rating; i++) {
      row.push(<IconAnt type="star" theme="filled" key={i} />);
    }
    return row;
  }

  return (
    <BackgroundRow type="flex" justify="center" background={data.profile_img}>
      <Container>
        <UserPhoto>
          <div className="user-photo">
            <img src={process.env.REACT_APP_PUBLIC_FILE_URL + '/' + data._id + '/' + data.avatar} alt='Student'/>
          </div>
        </UserPhoto>
        <SideContent/>

        <CentralContent>
          <TeacherSmallInfo type="flex">
            <RowAnt type="flex" justify="space-between">
              <TeacherName span={12}>{data.first_name} {data.last_name}</TeacherName>
              <AdditionalInfo span={12}>
                <RatingList>{ratingList(data.rating)}</RatingList>
                <Country />
              </AdditionalInfo>
            </RowAnt>
            <RowAnt type="flex" justify="space-between" align="bottom">
              <Col span={12}>
              </Col>
              <Col span={12} className="pl-text-right">
                <RowAnt type="flex" justify="space-between" align="middle">
                  <ContactButton
                    onClick={addDialog}
                    disabled={user._id === match.params.id}
                    label={t("contact_button")}
                  />
                </RowAnt>
              </Col>
            </RowAnt>
          </TeacherSmallInfo>
          { data.about &&
            <TextBlock
              title={t('about_me')}
              text={data.about}
            />
          }
          {
            data.hobbies &&
            <TextBlock
              title={'My hobbies'}
              text={data.hobbies}
            />
          }
          {
            data.future_goals &&
            <TextBlock
              title={'My future goals'}
              text={data.future_goals}
            />
          }
          {
            data.motivation &&
            <TextBlock
              title={'My motivation'}
              text={data.motivation}
            />
          }
          <FeedbackSlider/>
        </CentralContent>
      </Container>
    </BackgroundRow>
  );
};

export default withRouter(StudentContent)
