import { device } from "../../../constants/device";
import { Images } from "../../../assets/images";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { Row } from "antd";

export const TopMenu = styled(Row)`
    position: fixed;
    z-index: 41;
    top: 0;
    background-color: #fff;
    right: 0;
    margin: auto;
    padding: 40px 20px;
    min-width: 400px;
    box-shadow: 1px 1px 10px 2px #e3e3e3;
    text-align: center;
    width: 100vw;
    height: 100%;

    @media ${device.tablet} {
        padding: 20px 50px;
        background: url(${Images.TopMenuBackground}) #fff no-repeat;
        background-position: 85% 10%;
        background-size: 130px;
        text-align: left;
        width: auto;
        height: 100%;
    }

    @media ${device.laptopL} {
        background-position: 85% 5%;
        padding: 40px 50px;
    }
`;

export const ListMenu = styled.div`
    // margin-bottom: 50px;
`;

export const ListSubMenu = styled.div`
    // margin-bottom: 70px;
`;

export const LoginHeader = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
`;

export const MenuLink = styled(Link)`
    display: block;
    color: #76d9ff;
    font-family: 'PoppinsBlack', sans-serif;
    font-size: 26px;
    margin-bottom: 40px;

    @media ${device.tablet} {
        font-size: 20px;
        margin-bottom: 15px;
    }
`;

export const MenuDirectLink = styled.a`
    display: block;
    color: #76d9ff;
    font-family: 'PoppinsBlack', sans-serif;
    font-size: 26px;
    margin-bottom: 40px;

    @media ${device.tablet} {
        font-size: 20px;
        margin-bottom: 15px;
    }
`;

export const SubMenuLink = styled(Link)`
    display: block;
    color: #76d9ff;
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 22px;
    margin-bottom: 20px;

    @media ${device.tablet} {
        font-size: 18px;
        margin-bottom: 12px;
    }
`;

export const MenuBlockButton = styled.button`
    border: 2px solid #76d9ff;
    color: #76d9ff;
    padding: 10px 25px;
    border-radius: 25px;
    text-transform: uppercase;
    font-family: 'PoppinsBold', sans-serif;
    font-size: 18px;
    background: transparent;
    cursor: pointer;

    @media ${device.tablet} {
        padding: 6px 15px;
        font-size: 14px;
    }
`;

export const CloseButton = styled.span`
    position: absolute;
    top: 10px;
    right: 10px;
    background: url(${Images.CloseIcon}) no-repeat;
    height: 29px;
    width: 29px;
    cursor: pointer;
`;

export const TopMenuWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 40;
`;
