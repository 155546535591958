const initialState = [];

const shceduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SCHEDULE':
      return [...initialState, ...action.payload];
    case 'ADD_NEW_SCHEDULE_ITEM':
      console.log(action.payload);
      return [...state, action.payload];
    case 'ADD_NEW_SCHEDULE_LIST':
      console.log(action.payload);
      return [...state, ...action.payload];
    case 'UPDATE_SCHEDULE':
      return [...state.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload
        } else {
          return item
        }
      })];
    case 'REMOVE_SCHEDULE':
      return state.filter((item) => item._id !== action.payload);
    default:
      return state;
  }
};

export default shceduleReducer;
