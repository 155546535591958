import React, {useCallback, useEffect, useState} from "react";
import { Calendar, Badge } from "antd";
import {
  Container,
} from "../../components";
import {
  RowCalendar,
} from "./style";
import { AddEventModal } from '../../components/molecules/EventDashboardComponents';
import moment from 'moment';
import {useDispatch, useMappedState} from "redux-react-hook";

export function TeacherEventDashboard() {

  const dispatch = useDispatch();

  const [monthRange, setMonthRange] = useState({
    start_time: moment().startOf('month').toDate(),
    end_time: moment().endOf('month').toDate(),
  });
  const [isShowModal, setShowModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [value, setValue] = useState(moment());
  const [events, setEvents] = useState([]);

  const {user, schedule} = useMappedState((state) => {
    return {
      user: state.auth,
      schedule: state.schedule
    }
  });

  useEffect(() => {
    dispatch({
      type: 'GET_SCHEDULE',
      payload: {...monthRange, id: user.user._id}
    })
  }, [dispatch, monthRange, user]);

  function showModal() {
    setShowModal(true)
  }

  function hideModal() {
    setShowModal(false)
  }

  function onPanelChange (val) {
    setMonthRange({
      start_time: moment(val).startOf('month').toDate(),
      end_time: moment(val).endOf('month').toDate(),
    });
    setValue(val);
  }

  const getEventsByDate = useCallback((date) => {
    // eslint-disable-next-line
    return schedule.filter((item) => {
      if(moment(item.start_time).date() === moment(date).date() && moment(item.start_time).month() === moment(date).month()) {
        return item;
      }
    });
  }, [schedule]);

  function dateCellRender(value) {
    const listData = getEventsByDate(value).map((item) => ({
      type: item.lesson_id ? 'success' : 'error',
      content: moment(item.start_time).format('LT') + ' '
        + item.instruments.map((instrument) => instrument.name + ' ')
        + item.class_types.map((type) => type.type + ' ')
    }));
    return (
      <ul className="events">
        {listData.map((item, index) => (
          <li key={index}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  }

  const onSelect = useCallback((val) => {
    setSelectedValue(val);
    setEvents(getEventsByDate(val));
    setValue(val);
    showModal();
  }, [getEventsByDate]);




  return (
      <Container>
        <RowCalendar>
          <Calendar
            dateCellRender={dateCellRender}
            value={value}
            mode={'month'}
            onSelect={(value) => onSelect(value)}
            onPanelChange={(value) => onPanelChange(value)}
          />
        </RowCalendar>
        {selectedValue && <AddEventModal
          date={selectedValue}
          events={events}
          isShowModal={isShowModal}
          hideModal={hideModal}
        />}
      </Container>
  );
}
