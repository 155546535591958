import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from "../../../constants/device";
import { colGreen } from '../../../constants/variables';

export const Wrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: 100%;
    height: 200px;
    margin: auto;
    padding: 0;
    min-height: 100px;
    
    background: ${colGreen}; /* Old browsers */
    background: -moz-linear-gradient(left, ${colGreen} 0%, #76d9ff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, ${colGreen} 0%,#76d9ff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, ${colGreen} 0%,#76d9ff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=${colGreen}, endColorstr='#76d9ff',GradientType=1 ); /* IE6-9 */

    @media ${device.tablet} {
        height: 375px;
    }
`;

export const SiteLogo = styled.div`
    width: 200px;
    height: auto;
    margin: 0 0 0 20px;

    svg {
        width: 100%
    }
`;

export const FooterMenuLink = styled(Link)`
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 12px;
    color: #fff;
    padding: 0;

    @media ${device.tablet} {
        font-size: 16px;
        padding: 10px;
    }
    
    &:not(:last-child) {
        margin-right: 10px;

        @media ${device.tablet} {
            margin-right: 15px;
        }
    }

    @media ${device.tablet} {
        font-size: 22px;
        padding: 0;
    }
`;

export const FooterSocialLink = styled(Link)`
    &:not(:last-child) {
        margin-right: 20px;
    }

    svg {
        fill: #fff;
        height: 28px;
        width: 28px;
        border-radius: 7px;
    }
`;
