import axios from '../utilities/Axios';

export default {
  getSelf: async () => (await axios.get('/users/self')).data.data,
  getUser: async (id) => (await axios.get('/users/user/' + id)).data.data,
  getComments: async (id) => (await axios.get('/users/comments/' + id)).data.data,
  updateUser: async (data) => (await axios.put(`/users`, data)).data.data,
  firstUpdateUser: async (data) => (await axios.put(`/users/first`, data)).data.data,
  uploadUserFiles: async (data) => (await axios.put(`/users/files`, data)).data.data,
  addVideo: async (data) => (await axios.put('/users/videos', data)).data.data,
  deleteVideo: async (data) => (await axios.delete('/users/videos/' + data.index)).data.data
}

