import styled from "styled-components";
import { Row } from 'antd';
import { device } from "../../../constants/device";

export const RowAnt = styled(Row)`
  background-color: #fff;
  border-bottom: 2px solid #f9f9f9;
  margin: 0 5px;
`;

export const BlockHeader = styled(Row)`
  width: 100%;
  padding: 20px 25px 15px;
  border-bottom: 2px solid #f9f9f9;
  
  p {
    font-family: 'OdinRounded', sans-serif;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1;

    @media ${device.tablet} {
      font-size: 20px;
    }
  }
`;

export const BlockText = styled(Row)`
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 14px;
    padding: 25px;

    @media ${device.tablet} {
      font-size: 18px;
    }
`;