import {Modal, Row, Select, Switch, TimePicker} from "antd";
import styled from "styled-components";
import { colLightBlueText, colGreen } from "../../../../constants/variables";

export const AddNewButton = styled.span`
    color: ${colLightBlueText};
    position: absolute;
    top: 32px;

    &:before {
        content: '+';
    }

    &:hover {
        color: ${colGreen};
        cursor: pointer;
    }
`;

export const ModalAnt = styled(Modal)`
    width: 80vw !important;
    max-height: 80vh;
    top: 20px;
    .ant-modal-body {
        height: 40vw;
        overflow: auto;
        padding: 0 20px;
    }
`;

export const RemoveButton = styled.button`
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
    width: 5%;
    margin: auto;

    &:hover {
        color: red;
    }
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ModalSchedule = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
`;

export const ModalCreateArea = styled.div`
    display: flex;
    padding: 20px;
    flex-direction: column;
`;

export const GreenButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: 20px;
  &:hover {
    color: #b3e830;
  }
`;

export const RedButton = styled.button`
  border: none;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    color: red;
  }
`;

export const RowAnt = styled(Row)`
    width: 90%;
    margin: 10px 0 0;
    padding: 10px 0 0;
    
    &:not(:last-child) {
        border-top: 1px solid #e8e8e8;
    }
`;

export const LessonSetupSelect = styled(Select)`
    width: 200px;
    margin: 0 10px;
`;

export const SelectTimeWrapper = styled(Row)`
    width: 100%;
`;

export const TimePickerAnt = styled(TimePicker)`
    max-width: 100px;
    margin: 0 10px;
`;

export const ModeRow = styled.div`
    display: flex;
    width: 100%;
    padding 10px;
`;

export const ModeSwitcher = styled(Switch)`
   .ant-switch-checked {
      background-color: #b3e830 !important;
   }
`;

export const ModeTitle = styled.span`
  font-weight: bold;
  padding: 0px 10px;
`;

export const ModalUpdateArea = styled.div`
  width: 400px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
`;

export const UpdateSelect = styled(Select)`
  margin: 10px;
`;

export const UpdateTimeWrapper = styled.div`
  margin: 10px auto;
`;

