import React, {useCallback, useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import {
   BackgroundRow,
   CentralContentContainer,
   Container,
   SideContent,
   UserPhoto,
} from '../../components/atoms';
import { TextBlock } from '../../components/molecules';
import {
   MainBookingBlock,
   BookingPriceBlock
} from './style';
import { BookingStep } from '../../components/molecules/BookingDetailsPicking/';
import { Modal, Col } from 'antd';
import {useDispatch, useMappedState} from "redux-react-hook";
import {withRouter} from "react-router-dom";
import moment from "moment";
import { Link } from 'react-router-dom';
import StripeCheckout from "react-stripe-checkout";
import {Button as BookingButton} from "../../components/atoms/Button/style";
import Button from "antd/es/button";


export const Booking = withRouter(({match, history}) =>  {
  const { t } = useTranslation();
   const [isShowPay, toogleModal] = useState(false);
   const dispatch = useDispatch();
  const {user, booking} = useMappedState((state) => state);
  const [bookData, setBookData] = useState({});
  const [isShowThanksModal, setIsShowThanksModal] = useState(false);

  useEffect(() => {
    setIsShowThanksModal(booking.bookSuccess)
  }, [booking.bookSuccess]);

  const [book, setBook] = useState({});

  useEffect(() => {
    if (!user._id) {
      dispatch({
        type: 'GET_USER',
        payload: match.params.id
      })
    }
    setBook({
      _id: user._id,
      start_time: moment().startOf('day').toISOString()
    });
    if (user && user._id) {
      dispatch({
        type: 'GET_CAN_BOOK',
        payload: user._id
      })
    }
  }, [dispatch, user, match.params.id]);

  const onSelect = useCallback((value, type) => {
    setBook({...book, ...{[type]: value}})
  }, [book]);

  const onToken = useCallback((token) => {
    dispatch({
      type: 'CREATE_CHARGE',
      payload: {
        tokenId : token.id,
        amount: user.hour_rate
      }
    })
  }, [dispatch, user.hour_rate]);

  useEffect(() => {
    console.log(book);
    if (book.class_type && book.instrument) {
      dispatch({
        type: "GET_FILTERED_SCHEDULE",
        payload: book
      })
    }
  }, [book, dispatch] );

  const createBook = useCallback(() => {
    dispatch({
      type: 'CREATE_BOOK',
      payload: bookData
    });
    toogleModal(!isShowPay);
  }, [dispatch, bookData, isShowPay]);

  const onChoose = useCallback((_id) => {
    setBookData({...book, schedule_id: _id})
  }, [book]);


  return (
     <BackgroundRow type="flex" justify="center">
        <UserPhoto>
          <Link to="user_link" className="user-photo">
            <img src={process.env.REACT_APP_PUBLIC_FILE_URL + '/' + user._id + '/' + user.avatar} alt='Teacher'/>
          </Link>
        </UserPhoto>
       {user && user._id && <Container>
         <SideContent/>
         <CentralContentContainer>
           <TextBlock
             title={user.first_name + ' ' + t('booking_text_title1')}
             title2={(moment().year() - moment(user.age).year()) + ' - ' + user.location.location}
             text={t('booking_text')}
           />
           <MainBookingBlock>
             <Col lg={24} xl={6}>
               <BookingPriceBlock>
                 <p className="pl-time"><span>{t('booking_text_duration')}: </span>55 {t('time_minutes')}</p>
                 <p className="pl-price"><span>{t('booking_text_price')}: </span>{user.hour_rate} €</p>
               </BookingPriceBlock>
             </Col>
             <Col lg={24} xl={18}>
               <TextBlock
                 title={t('booking_day_title')}
                 text={t('booking_day_text')}
               />
               <BookingStep
                 number={1}
                 direction='right'
                 displaySelect={true}
                 placeholder={t('booking_select_instrument')}
                 options={user.instruments}
                 onSelect={onSelect}
                 type={'instrument'}
               />
               <BookingStep
                 number={2}
                 direction='left'
                 displaySelect={true}
                 placeholder={t('booking_select_type_lesson')}
                 options={user.class_types}
                 onSelect={onSelect}
                 type={'class_type'}
               />
               <BookingStep
                 number={3}
                 direction='top'
                 displaySelect={false}
                 onChoose={onChoose}
               />
               {/*<BookingButton onClick={() => toogleModal(!isShowPay)} label={t('booking_button')} />*/}
               <BookingButton onClick={() => toogleModal(!isShowPay)}>{t('booking_button')}</BookingButton>
             </Col>
           </MainBookingBlock>

           <Modal
             title={t('booking_text_message_title')}
             visible={isShowPay}
             onCancel={() => toogleModal(!isShowPay)}
             footer={[
               <Button key="back" onClick={() => toogleModal(!isShowPay)}>
                cancel
               </Button>,
                 <>
                   {
                     booking.canBook ?
                       <Button key="book" onClick={createBook}>
                         book
                       </Button> :
                       <StripeCheckout
                         label={t('booking_button')} //Component button text
                         name="321Play" //Modal Header
                         description="Upgrade to a premium account today."
                         panelLabel={t('booking_button')} //Submit button in modal
                         amount={user.hour_rate * 100}
                         currency="EUR"
                         token={onToken}
                         stripeKey={process.env.REACT_APP_STRIPE_KEY}
                         billingAddress={false}
                         onClick={() => toogleModal(!isShowPay)}
                       />
                   }
                 </>
             ]}
           >
           <p>{t('booking_text_message')}</p>
         </Modal>
         <Modal
           title={'thanks for booking'}
           visible={isShowThanksModal}
           footer={[
             <Link to={"/dashboard"}>Go to dashboard</Link>,
             <Button onClick={() => {setIsShowThanksModal(false)}}>Close</Button>
           ]}
         >
           Thanks for booking
         </Modal>
         </CentralContentContainer>
       </Container>
       }
     </BackgroundRow>
   );
});
