import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { Col, Row } from "antd";
import {
  Container,
  DashboardFeedback,
  DashboardChart,
  DashboardMoney, Navigation
} from "../../components";
import { Images } from "../../assets/images";
import { 
  TeacherRow,
  SideContent,
  List,
  CentralContent,
  RowAnt,
  BlockHeader,
  DateWrapper,
  DateDisplay,
  PlanList,
  BlockText,
  BlockSubHeader,
  NotesWrapper,
  NotesList,
  SecondTitle,
  LessonItem,
} from "./style";
import {useMappedState} from "redux-react-hook";
import {UserPhoto} from "../../components/atoms";

export function Dashboard() {
    const { t } = useTranslation();
    const user = useMappedState(state => state.auth.user);
    console.log(user);
  return (
        <TeacherRow type="flex" justify="center">
            <Navigation
              pageName="dashboard"
            />
            <Container>
              <UserPhoto>
                <Link to="user_link" className="user-photo">
                  <img src={process.env.REACT_APP_PUBLIC_FILE_URL + '/' + user._id + '/' + user.avatar} alt='Teacher' />
                </Link>
              </UserPhoto>
              <SideContent>
                <List>
                  { user && user.role_id.role === 'teacher' && user.metadata && user.metadata.instruments && user.metadata.instruments.length > 0 &&
                    user.metadata.instruments.map((item) => {
                      return(<LessonItem key={item._id}>{item.name}</LessonItem>)
                    })
                  }
                  <div className="pl-mb-35"></div>
                </List>
              </SideContent>
              <CentralContent>
                {/*<Title>{t("dashboard_title")}</Title>*/}
                <Row type="flex">
                  <DashboardMoney/>
                </Row>
                <Row type="flex">
                  <Col xs={24} md={24} lg={12}>
                    <RowAnt type="flex">
                      <BlockHeader type="flex" justify="space-between">
                        <p>{t('next_plan')}</p>
                      </BlockHeader>
                      <DateWrapper>
                        <DateDisplay>
                          Di 17.07.19
                        </DateDisplay>
                        <PlanList>
                          <li>
                            <Row type="flex" justify="space-between">
                              <p className="pl-plan-time">15:00</p>
                              <p className="pl-plan-title">{t('next_plan_text1')}</p>
                              <Link className="pl-plan-location" to=""><img src={Images.LocationIcon} alt='location' /></Link>
                              <Link className="pl-plan-link" to=""><img src={Images.GoToIcon} alt='goto' /></Link>
                            </Row>
                          </li>
                          <li>
                            <Row type="flex" justify="space-between">
                              <p className="pl-plan-time">15:00</p>
                              <p className="pl-plan-title">{t('next_plan_text2')}</p>
                              <Link className="pl-plan-location" to=""><img src={Images.LocationIcon} alt='location' /></Link>
                              <Link className="pl-plan-link" to=""><img src={Images.GoToIcon} alt='goto' /></Link>
                            </Row>
                          </li>
                        </PlanList>
                      </DateWrapper>
                      <DateWrapper>
                        <DateDisplay>
                          Mi 18.07.19
                        </DateDisplay>
                        <PlanList>
                          <li>
                            <Row type="flex" justify="space-between">
                              <p className="pl-plan-time">09:40</p>
                              <p className="pl-plan-title">{t('next_plan_text3')}</p>
                              <Link className="pl-plan-location" to=""><img src={Images.LocationIcon} alt='location' /></Link>
                              <Link className="pl-plan-link" to=""><img src={Images.GoToIcon} alt='goto' /></Link>
                            </Row>
                          </li>
                          <li>
                            <Row type="flex" justify="space-between">
                              <p className="pl-plan-time">11:00</p>
                              <p className="pl-plan-title">{t('next_plan_text4')}</p>
                              <Link className="pl-plan-location" to=""><img src={Images.LocationIcon} alt='location' /></Link>
                              <Link className="pl-plan-link" to=""><img src={Images.GoToIcon} alt='goto' /></Link>
                            </Row>
                          </li>
                        </PlanList>
                      </DateWrapper>
                    </RowAnt>
                  </Col>
                  <Col xs={24} md={24} lg={12}>
                    <RowAnt type="flex">
                      <BlockHeader type="flex" justify="space-between">
                        <p>{t('your_notes')}</p>
                      </BlockHeader>
                      <BlockSubHeader type="flex" justify="space-between">
                        <p>{t('your_notes_text1')}</p>
                      </BlockSubHeader>
                      <NotesWrapper>
                        <DateDisplay>
                          Fr 20.07.19
                        </DateDisplay>
                        <NotesList>
                          <li>
                            <Row type="flex" justify="space-between">
                              <p className="pl-note-title">{t('your_notes_text2')}</p>
                              <Link className="pl-note-status" to=""><img src={Images.CheckIcon} alt='Check' /></Link>
                            </Row>
                          </li>
                          <li>
                            <Row type="flex" justify="space-between">
                              <p className="pl-note-title">{t('your_notes_text3')}</p>
                              <Link className="pl-note-status" to=""><img src={Images.CheckIcon} alt='Check' /></Link>
                            </Row>
                          </li>
                        </NotesList>
                      </NotesWrapper>
                    </RowAnt>
                  </Col>
                </Row>
                {/* <DashboardCalendarBlock type="flex">
                  <BlockHeader type="flex" justify="space-between">
                    <p>{t('your_calendar')}</p>
                  </BlockHeader>
                  <DashboardCalendar/>
                </DashboardCalendarBlock> */}
                <RowAnt type="flex">
                  <BlockHeader type="flex" justify="space-between">
                    <p>{t('your_development')}</p>
                  </BlockHeader>
                  <DashboardChart/>
                </RowAnt>
                <Row type="flex">
                  <Col span={12}>
                    <DashboardFeedback/>
                  </Col>
                  <Col xs={24} md={24} lg={12}>
                    <RowAnt type="flex">
                      <BlockHeader type="flex" justify="space-between">
                        <p>{t('your_cloud')}</p>
                        <SecondTitle>132 Mb</SecondTitle>
                      </BlockHeader>
                      <BlockSubHeader type="flex" justify="space-between">
                        <p>{t('your_cloud_text')}</p>
                      </BlockSubHeader>
                      <BlockText>cloud display</BlockText>
                    </RowAnt>
                  </Col>
                </Row>
              </CentralContent>
            </Container>
        </TeacherRow>
    );
}
