import {useDispatch, useMappedState} from "redux-react-hook";

import React, {useCallback, useEffect} from "react";
import EditTeacherPage from "./EditTeacher";
import EditStudentPage from "./EditStudent";
import {withRouter} from "react-router-dom";

const EditUser = withRouter(({match}) => {

  const dispatch = useDispatch();
  const user = useMappedState(state => state.user);

  useEffect(() => {
    dispatch({
      type: 'GET_USER',
      payload: match.params.id
    });
    dispatch({
      type: 'GET_INSTRUMENTS'
    });
    dispatch({
      type: 'GET_LANGUAGES'
    });
    dispatch({
      type: 'GET_PROFICIENCIES'
    });
    dispatch({
      type: 'GET_LOCATIONS'
    });
    dispatch({
      type: 'GET_CLASS_TYPES'
    });
  }, [dispatch, match.params.id]);


  const onSaveChanges = useCallback((data, e) => {
    e.preventDefault();
    dispatch({
      type: "ADMIN_UPDATE_USER",
      payload: data
    })
  }, [dispatch]);

  return (
    <>
      {user && user.role &&
        <div>
          {
            user.role.role === 'teacher' && <EditTeacherPage onSaveChanges={onSaveChanges}/>
          }
          {
            user.role.role === 'student' &&  <EditStudentPage onSaveChanges={onSaveChanges}/>
          }
        </div>
      }
    </>
  )
});

export default EditUser
