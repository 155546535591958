import { Images } from '../../assets/images';
import { Col, Row } from 'antd';
import { device } from "../../constants/device";
import styled from "styled-components";

export const BenefitsBlockWrapper = styled(Col)`
    margin-bottom: 30px;
    
    @media ${device.tablet} {
        margin-bottom: 0;
    }
`;

export const RowAnt = styled(Row)`
    background: url(${Images.Unsplash}) #f9f9f9 center top no-repeat;
    background-size: 100%;
    padding-bottom: 50px;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 390px;
        width: 100%;
        height: 100%;
        background: #f9f9f9;
    }
`;

export const BenefitsListBlock = styled.ul`
    margin: 0 5px;
    padding: 20px;
    list-style: none;
    background: #fff;
    border-bottom: 2px solid #f9f9f9;
    
    li {
        position: relative;
        padding-left: 30px;
        margin-bottom: 20px;
        color: #666;
        font-size: 16px;
        font-family: 'OdinRounded', sans-serif;
        line-height: 1.2;

        @media ${device.tablet} {
            font-size: 20px;
            padding-left: 45px;
        }

        &:before {
            content: '';
            position: absolute;
            margin: auto;
            bottom: 0;
            top: 0;
            left: 0;
            background: url(${Images.ListItemIcon}) center no-repeat;
            display: block;
            height: 18px;
            width: 18px;
            background-size: contain;

            @media ${device.tablet} {
                height: 23px;
                width: 22px;
            }
        }
    }
`;

export const PageTitle = styled.h2`
    text-align: center;
    color: #ffff00;
    font-size: 36px;
    font-family: 'OdinRounded', sans-serif;
    width: 100%;
    line-height: 1;
    margin-top: 60px;
    margin-bottom: 250px;

    @media ${device.tablet} {
        font-size: 46px;
        margin-top: 100px;
        margin-bottom: 40px;
    }
`;

export const BenefitBlockBottom = styled.div`
    padding: 24px 30px;
    text-align: right;
    background: #fff;
    margin: 0 5px;
`;
