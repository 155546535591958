import React from 'react';
import { Channels, MessageArea } from "../../components/molecules/ChatComponents";
import useChatPage from "../../hooks/ChatPage";
import {Navigation} from "../../components/molecules";
import { MessageRow, SelectDialogText, ChatRow, ChatContainer } from "./style";
import { useTranslation } from "react-i18next";
import {Container} from "../../components/atoms";

export function ChatPage(props) {
  const { t } = useTranslation();

  const {
    handleChangeMessage,
    message,
    messages,
    markAsRead,
    sendMessage,
    onChooseRoom,
    user
  } = useChatPage();

  return (
    <ChatRow type="flex" justify="center">
      <Navigation
        pageName="chat"
      />
        <Container>
          <ChatContainer>
            {
              messages.rooms &&
              <Channels
                channels={messages.rooms}
                onChooseRoom={onChooseRoom}
              />
            }
            {
              messages.dialog && messages.dialog.id ?
                <MessageArea
                  messages={messages.dialog.data}
                  member={messages.dialog.member}
                  message={message}
                  handleChangeMessage={handleChangeMessage}
                  sendMessage={sendMessage}
                  readMessage={markAsRead}
                  user={user}
                /> :
                <MessageRow>
                  <SelectDialogText>
                    {t("chat_select_dialog")}
                  </SelectDialogText>
                </MessageRow>
            }
          </ChatContainer>
        </Container>
    </ChatRow>
  );
}
