import styled from "styled-components";
import { Row, Col } from "antd";
import { device } from "../../constants/device";

export const ShowHideSidebarmobile = styled(Col)`
`;

export const MenuButton = styled.span`
  height: 14px;
  display: block;
  cursor: pointer;
  margin: 20px 20px 0px;
  position: relative;
  color: #00a2e0;
  padding-left: 10px;
  width: 100%;
  line-height: 1;

  &:after {
    content: "+";
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.2s ease;
  }

  &.pl-open-filters {
    color: #76d9ff;
    &:after {
      transform: rotate(45deg);
    }
  }
`;

export const SideContent = styled.div`
  width: 100vw;
  background: #fff;
  padding: 10px 10px 50px;
  display: none;

  @media ${device.tablet} {
    width: 30vw;
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    background: #fff;
    padding: 20px 20px 100px;
    border-radius: 0 0 170px 170px;
  }

  @media ${device.laptop} {
    width: 340px;
    margin-right: 60px;
    padding: 50px 40px 150px;
  }
`

export const CentralContent = styled.div`
  width: 100vw;
  display: inline-block;
  vertical-align: top;
  padding: 20px 0;

  @media ${device.tablet} {
    width: 65vw;
    padding: 40px 0;
  }

  @media ${device.laptop} {
    width: calc(100% - 405px);
  }
`;

export const List = styled.ul`
  width: 100%;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const TeacherRow = styled(Row)`
  .pl-sidebar-filters {
    display: none;

    @media ${device.laptop} {
      display: block;
    }
  }

  &.pl-open-mobile {

    .pl-open {
      display: block !important;
      width: 100%
      position: absolute;
      top: 50px;
      border-radius: 0;
      height: 100%;
      overflow: hidden;

      .ant-slider-mark {
        .ant-slider-mark-text {
          left: 90% !important;
        }
        .ant-slider-mark-text-active {
          left: 10% !important;
        }
      }
    }
  }
`;

export const FilterBlock = styled.div`
  padding: 0 0 30px;

  p {
    font-size: 20px;
    font-family: 'OdinRounded', sans-serif;
    color: #333;
  }

  .ant-slider-mark-text {
    font-size: 12px;
    width: 100%;
    font-family: 'PoppinsRegular', sans-serif;
  }
`;

export const Loader = styled.p`
  width: 100%;
  font-size: 18px;
  text-align: center;
  font-family: 'OdinRounded', sans-serif;
  color: #333;

  &.hide {
    display: none;
  }
`;