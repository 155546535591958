import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from '../../../../constants/device';

export const Usertitle = styled.span`
    font-family: 'PoppinsBold', sans-serif;
    font-size: 14px;
    margin-top: 10px;
    color: #76d9ff;
    text-transform: capitalize;

    @media ${device.tablet} {
        font-size: 16px;     
    }

    @media ${device.laptop} {
        font-size: 18px;     
    }
`
    
export const Teacher = styled(Link)`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media ${device.tablet} {
        margin-bottom: 50px;     
    }

    @media ${device.laptop} {
        margin-bottom: 70px;     
    }

    .user-photo {
        width: 82px;
        height: 82px;
        border-radius: 50%;
        overflow: hidden;

        @media ${device.tablet} {
            width: 150px;
            height: 150px;    
        }

        @media ${device.laptop} {
            width: 225px;
            height: 225px;    
        }
    }

    img {
        width: 100%;
        height: 100%;
        margin: auto;
        object-fit: cover;
        transform: scale(1.2);
    }
`