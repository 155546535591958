import React from 'react';
import { RowAnt, BlockHeader, BlockText } from "./style";

export function BenefitsTextBlock(props) {
    return (
        <RowAnt type="flex">
            <BlockHeader type="flex" justify="space-between">
                <p>{props.title}</p>
            </BlockHeader>
            <BlockText>{props.text}</BlockText>
        </RowAnt>
    );
}
