import React, {useCallback, useState} from 'react';
import { useTranslation } from "react-i18next";
import {
  RowAnt,
  BlockHeader,
  InfoWrapper,
  LinkProfile
} from './style';
import {Button, Col, Input, Modal} from 'antd';
import {useDispatch, useMappedState} from "redux-react-hook";
import StripeCheckout from "react-stripe-checkout";

export function DashboardMoney() {
  const { t } = useTranslation();
  const [isOpenModal, setOpenModal] = useState(false);
  const [amount, setAmount] = useState(0);

  const dispatch = useDispatch();

  const toggleModal = useCallback(() => {
    setOpenModal(!isOpenModal)
  }, [isOpenModal]);

  const {user} = useMappedState(state => state.auth);

  const handleChangeAmount = useCallback((value) => {
    setAmount(value)
  }, []);

  const onToken = useCallback((token) => {
    dispatch({
      type: 'CREATE_CHARGE',
      payload: {
        tokenId : token.id,
        amount: amount
      }
    })
  }, [dispatch, amount]);

  return (
    <RowAnt type="flex" className="pl-mb-3">
      <BlockHeader type="flex" justify="space-between">
        <p>{t('money_information')}</p>
      </BlockHeader>
      <InfoWrapper>
        <RowAnt type="flex">
          <Col xs={24} lg={20}>
            <p>{t("money_account")}</p>
            <span>{user.wallet_id.balance / 100} €</span>
          </Col>
          <Col xs={24} lg={4}>
            <RowAnt type="flex" justify="space-between">
              <LinkProfile onClick={toggleModal}>{t('money_replenish')}</LinkProfile>
              <LinkProfile disabled onClick={toggleModal}>{t('money_withdraw')}</LinkProfile>
              <Modal
                title={t('money_replenish')}
                visible={isOpenModal}
                onCancel={toggleModal}
                footer={[
                  <StripeCheckout
                    label={t('money_replenish')} //Component button text
                    name="321 Play" //Modal Header
                    description="Upgrade to a premium account today."
                    panelLabel={t('booking_button')} //Submit button in modal
                    amount={amount * 100}
                    currency="EUR"
                    token={onToken}
                    stripeKey={process.env.REACT_APP_STRIPE_KEY}
                    billingAddress={false}
                    disabled={(amount * 100) === 0}
                  />,
                  <Button onClick={toggleModal}>Cancel</Button>
                ]}
              >
                <Input
                  onChange={(e) => handleChangeAmount(e.target.value)}
                  type={'number'}
                  placeholder={'enter the amount you want to replenish'}
                />
              </Modal>
            </RowAnt>
          </Col>
        </RowAnt>
      </InfoWrapper>
    </RowAnt>
  );
}
