import React from 'react';
import { RowAnt } from './style';
import { useTranslation } from "react-i18next";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';

export function DashboardChart() {
    const { t } = useTranslation();

    const data = [
        {
          name: t("month_Aug"), uv: 4000, pv: 2400, amt: 2400,
        },
        {
          name: t("month_Sep"), uv: 3000, pv: 1398, amt: 2210,
        },
        {
          name: t("month_Okt"), uv: 2000, pv: 9800, amt: 2290,
        },
        {
          name: t("month_Nov"), uv: 2780, pv: 3908, amt: 2000,
        },
        {
          name: t("month_Dez"), uv: 1890, pv: 4800, amt: 2181,
        },
        {
          name: t("month_Jan"), uv: 2390, pv: 3800, amt: 2500,
        },
        {
          name: t("month_Feb"), uv: 3490, pv: 4300, amt: 2100,
        },
        {
            name: t("month_Mrz"), uv: 4000, pv: 2400, amt: 2400,
        },
        {
            name: t("month_Apr"), uv: 3000, pv: 1398, amt: 2210,
        },
        {
            name: t("month_Mai"), uv: 2000, pv: 9800, amt: 2290,
        },
        {
            name: t("month_Jun"), uv: 2780, pv: 3908, amt: 2000,
        },
        {
            name: t("month_Jul"), uv: 1890, pv: 4800, amt: 2181,
        }
      ];

    return (
        <RowAnt type="flex">
            <ResponsiveContainer width='100%' aspect={3.0/1.5}>
                <LineChart
                    height={300}
                    data={data}
                    margin={{
                        top: 5, right: 30, left: 0, bottom: 5,
                    }}
                >
                    <XAxis dataKey="name" />
                    <YAxis tick={false} />
                    <Tooltip />
                    <Legend verticalAlign="top" align="right" height={36}/>
                    <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                </LineChart>
            </ResponsiveContainer>
        </RowAnt>
    );
}
