import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { Row } from "antd";
import Slider from "react-slick";
import { device } from "../../../../constants/device";
import { colGreen } from '../../../../constants/variables';

export const EduIcon = styled.span`
    width: 55px;
    height: 32px;
    display: block;
    margin: 80px auto 60px;

    svg {
        width: 100%;
    }
`;

export const SlickSlider = styled(Slider)`
    .slick-arrow {
        background-color: #fff;
        height: 70px;
        width: 70px;

        @media ${device.laptop} {
            height: 100px;
            width: 100px;
        }
        
        &.slick-prev:before,
        &.slick-next:before {
            color: #76d9ff;
            font-family: 'PoppinsBold', sans-serif;
            font-size: 40px;
        }
        
        &.slick-prev {
            left: 0;
            border-radius: 0 50% 50% 0;
            z-index: 1;
            box-shadow: 0px 0px 30px 0px #f1f1f1;
        }
        
        &.slick-next {
            right: 0;
            border-radius: 50% 0 0 50% ;
            z-index: 1;
            box-shadow: 0px 0px 30px 0px #f1f1f1;
        }
    }
    
    .slick-dots {
        bottom: -40px;
    }
    
    .slick-dots li button:before {
        color: #e6e6e6;
        font-size: 20px;
        opacity: 1;
    }
    
    .slick-dots li.slick-active button:before {
        color: ${colGreen};
    }
`;

export const Testimonial = styled(Row)`
    text-align: center;
    margin: 50px auto 0;
    min-height: 350px;

    @media ${device.laptop} {
        margin: 100px auto 0;
        min-height: 450px;
    }
`;

export const TestimonialText = styled.p`
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 18px;
    width: 60%;
    margin: 15px auto 30px;
    line-height: 1.2;

    @media ${device.tablet} {
        font-size: 22px;
    }
`;

export const TestimonialUserInfo = styled.p`
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 14px;
    width: 70vw;
    max-width: 280px;
    min-width: 400px;
    margin: 0 auto 20px;
    line-height: 1.2;

    @media ${device.laptop} {
        font-size: 16px;
        width: 60%;
        max-width: 900px;
        margin: 0 auto 15px;
    }
    
    img {
        display: block !important;
        width: 55px;
        height: 55px;
        margin: 20px auto;

        @media ${device.laptop} {
            display: inline-block !important;
            width: 55px;
            height: 55px;
            margin-right: 30px;
        }
    }
`;

export const SliderWrapper = styled(Row)`
    background: #f8f8f8;
    position: relative;
    width: 100%;
`;

export const SliderContainer = styled.div`
    width: 100%;
`;

export const PageTitle = styled.h2`
    font-family: 'OdinRounded', sans-serif;
    font-size: 36px;
    color: ${colGreen};
    line-height: 0.8;
    margin-bottom: 10px;
    text-align: center;

    @media ${device.laptop} {
        font-size: 60px;
        margin-bottom: 30px;
    }
`;