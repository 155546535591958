import { all, takeEvery, put, select } from 'redux-saga/effects'
import teacherService from '../../services/teacher.service'
import notify from "../../utilities/Notify";

function* getAll() {

  try {
    const state = yield select();
    const query = {...state.teachers.filters, skip: state.teachers.skip, sort: state.teachers.sort, limit: state.teachers.limit};
    const teachers = yield teacherService.getTeachers(query);
      yield put({
        type: 'SET_TEACHERS',
        payload: teachers
      })
  } catch(e) {
    notify.error(e)
  }
}

function* getMore() {

  try {
    const state = yield select();
    const query = {...state.teachers.filters, skip: state.teachers.skip, sort: state.teachers.sort, limit: state.teachers.limit};
    const teachers = yield teacherService.getTeachers(query);
    yield put({
      type: 'SET_MORE_TEACHERS',
      payload: teachers
    })
  } catch(e) {
    notify.error(e)
  }
}

function* getCount() {

  try {
    const count = yield teacherService.getCount();
    yield put({
      type: 'SET_TEACHERS_TOTAL',
      payload: count
    })
  } catch (e) {

  }

}

function* fetchDataWatcher() {
  yield takeEvery('GET_TEACHERS', getAll);
  yield takeEvery('GET_MORE', getMore);
  yield takeEvery('GET_TEACHERS_COUNT', getCount);
}

export function* teachersSaga() {
  yield all([fetchDataWatcher()])
}
