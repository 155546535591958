import ReactDOM from "react-dom";
import 'index.css';
import "antd/dist/antd.css";

import { App } from "./App";

import * as serviceWorker from "./serviceWorker";
import { StoreContext } from 'redux-react-hook'
import {store} from './store/index'
import React from "react";
import WSProvider from "./providers/io";
import io from "socket.io-client";


const AppContainer = (
  <StoreContext.Provider value={store}>
    <WSProvider.Provider value={io.connect(process.env.REACT_APP_BASE_SOCKET)}>
      <App/>
    </WSProvider.Provider>
  </StoreContext.Provider>
);

ReactDOM.render(AppContainer, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
