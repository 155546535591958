import {useCallback, useEffect} from "react";
import {useDispatch, useMappedState} from "redux-react-hook";

const useTeacherList = () => {
  const sortType = [
    { id: 'rating', item: 'Rating' },
    { id: 'hour_rate', item: 'Price' },
    // { id: 3, item: 'Distance' }
  ];

  const dispatch = useDispatch();

  const Languages = useMappedState((state) => (state.languages));
  const Proficiencies = useMappedState((state) => (state.proficiencies));
  const Instruments = useMappedState((state) => (state.instruments));


  const teachers = useMappedState((state) => {
    return state.teachers ;
  });



  useEffect(() => {
    dispatch({
      type: 'GET_TEACHERS',
    })
  }, [teachers.filters, teachers.sort, dispatch]);

  useEffect(() => {
    if (teachers.skip > 0) {
      dispatch({
        type: 'GET_MORE',
      })
    }
  }, [teachers.skip, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'GET_LANGUAGES'
    });
    dispatch({
      type: 'GET_PROFICIENCIES'
    });
    dispatch({
      type: 'GET_INSTRUMENTS'
    });
  }, [dispatch]);

  const handleChangeFilters = useCallback((groupName, value) => {
    if (groupName === 'languageList') {
      if (value === 'All languages') {
        dispatch({
          type: "SET_NULL_FILTERS",
          payload: {
            type: 'array',
            fieldType: 'one_of',
            key: "languages.language"
          }
        })
      } else {
        dispatch({
          type: 'SET_FILTERS',
          payload: {
            array: {
              one_of: {
                "languages.language": [value]
              }
            }
          }
        })
      }

    } else if (groupName === 'levelList') {
      if (value === 'All levels') {
        dispatch({
          type: "SET_NULL_FILTERS",
          payload: {
            type: 'item',
            fieldType: 'condition',
            key: 'proficiencies.level'
          }
        })
      } else {
        dispatch({
          type: 'SET_FILTERS',
          payload: {
            item: {
              condition: {
                "proficiencies.level": value
              }
            }
          }
        })
      }
    }  else if (groupName === 'instrumentList') {
      if (value === 'All instruments') {
        dispatch({
          type: "SET_NULL_FILTERS",
          payload: {
            type: 'array',
            fieldType: 'one_of',
            key: 'instruments.name'
          }
        })
      } else {
        dispatch({
          type: 'SET_FILTERS',
          payload: {
            array: {
              one_of: {
                'instruments.name': [value]
              }
            }
          }
        })
      }
    }
  }, [dispatch]);

  const handleChangeSort = useCallback((groupName, type, value) => {
    if (value === 'none') {
      dispatch({
        type: 'SET_NULL_SORT',
        payload: type
      })
    } else {
      let sort = {};
      sort[type] = value;
      dispatch({
        type: 'SET_SORT',
        payload: sort
      })
    }
  }, [dispatch]);

  // const Languages = useMappedState((state) => (state.languages));
  // const Proficiencies = useMappedState((state) => (state.proficiencies));
  // const Instruments = useMappedState((state) => (state.instruments));

  const isShowSearchList = false;

  const loadFunc = useCallback((page) => {
    if (page * teachers.limit + teachers.skip === teachers.data.length) {
      dispatch({
        type: "SET_SKIP",
        payload: {
          skip: teachers.skip + teachers.limit
        }
      })
    }
  }, [dispatch, teachers.data, teachers.skip, teachers.limit]);

  return {
    loadFunc,
    sortType,
    handleChangeSort,
    Languages,
    Proficiencies,
    Instruments,
    handleChangeFilters,
    isShowSearchList,
    teachers,
  }
};

export default useTeacherList;
