import {Input, Modal} from "antd";
import React, {useCallback, useState} from "react";
import {useDispatch} from "redux-react-hook";
import {ModalButton} from "./style";
import notify from "../../../utilities/Notify";

const ForgotPasswordModal = ({toggleModal, isShowModal}) => {
  const dispatch = useDispatch();
  const [recoverEmail, setRecoverEmail] = useState('');

  const onChangeRecoverEmail = useCallback((value) => {
    setRecoverEmail(value)
  }, []);


  const sendRecoverLink = useCallback(() => {
    dispatch({
      type: 'REQUEST_FOR_RECOVER_PASSWORD',
      payload: {email: recoverEmail}
    });
    toggleModal();
    notify.success('We send email on your address with instructions')
  }, [dispatch, recoverEmail, toggleModal]);

  return (
    <Modal
      title="Forgot password?"
      visible={isShowModal}
      onCancel={toggleModal}
      footer={[
        <ModalButton key="back" onClick={toggleModal}>
          Cancel
        </ModalButton>,
        <ModalButton
          key="submit"
          disabled={recoverEmail.length < 8}
          type="primary"
          onClick={sendRecoverLink}
        >
          Send
        </ModalButton>,
      ]}
    >
      <p>Enter your email and we will send you recover link</p>
      <Input
        value={recoverEmail}
        onChange={(e) => {onChangeRecoverEmail(e.target.value)}}
        placeholder={"enter your email address"}
        type={"email"}
      />
    </Modal>
  )
};

export default ForgotPasswordModal;

