import styled from "styled-components";
import { Images } from "../../../assets/images";
import {
    Button,
    Form,
    Row,
} from 'antd';
import { device } from "../../../constants/device";
import { colGreen } from '../../../constants/variables';

export const RowAnt = styled(Row)`
  background-color: #fff;
  margin-bottom: 20px;
`;

export const BlockHeader = styled(Row)`
  width: 100%;
  padding: 20px 25px 15px;
  border-bottom: 2px solid #f9f9f9;
  
  p {
    font-family: 'OdinRounded', sans-serif;
    font-size: 20px;
    margin-bottom: 0;
    line-height: 1;
  }
`;

export const BlockText = styled(Row)`
  font-family: 'PoppinsRegular', sans-serif;
  font-size: 18px;
  padding: 25px;
`;

export const SecondTitle = styled.p`
  color: #d8d8d8;
  font-family: 'OdinRounded', sans-serif;
  font-size: 18px;
`;

export const AddComment = styled(Form)`
  border-top: 2px solid #f9f9f9;
  border-bottom: 2px solid #f9f9f9;
  width: 100%;
  padding: 30px 25px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  
  .ant-form-item-control-wrapper {
    width: 100%;
  }
  
  .ant-btn {
    border: 2px solid ${colGreen};
    border-radius: 20px;
    font-family: "PoppinsBold", sans-serif;
    padding: 10px 20px;
    line-height: 1;
    font-size: 14px;
    height: auto;
    text-transform: uppercase;
    color: ${colGreen};
    min-height: 45px;
  }
`;

export const Additional = styled(Row)`
  width: 100%;
  padding: 10px 25px;
`;

export const LikeButton = styled(Button)`
  background: url(${Images.HeartImageBlack}) no-repeat;
  background-position: left center;
  padding-left: 45px;
  border: none;
  font-family: "OdinRounded", sans-serif;
  font-size: 16px;
  line-height: 1;
  
  &:hover,
  &:active,
  &:focus {
    background: url(${Images.HeartImageBlack}) no-repeat;
    background-position: left center;
  }
`;

export const MailButton = styled(Button)`
  background: url(${Images.EmailIconBlack}) no-repeat;
  background-position: left center;
  padding-left: 47px;
  border: none;
  font-family: "OdinRounded", sans-serif;
  font-size: 16px;
  line-height: 1;
  
  &:hover,
  &:active,
  &:focus {
    background: url(${Images.EmailIconBlack}) no-repeat;
    background-position: left center;
  }
`;

export const TagButton = styled(Button)`
  background: url(${Images.TagIconBlack}) no-repeat;
  background-position: left center;
  padding-left: 38px;
  border: none;
  font-family: "OdinRounded", sans-serif;
  font-size: 16px;
  line-height: 1;
  
  &:hover,
  &:active,
  &:focus {
    background: url(${Images.TagIconBlack}) no-repeat;
    background-position: left center;
  }
`;

export const FormItemAnt = styled(Form.Item)`
  width: 100%;
  margin-right: 0 !important;
  margin-bottom: 20px !important;

  @media ${device.laptopL} {
    width: 80%;
    margin-bottom: 0;
  }
  
  .ant-input {
    border: 2px solid #d8d8d8;
    border-radius: 20px;
    font-family: "PoppinsRegular", sans-serif;
    padding: 8px 20px;
    line-height: 1;
    font-size: 16px;
    height: auto;
    
    &::placeholder {
      color: #d8d8d8;
    }
  }
`;
