import { Modal, Row } from "antd";
import styled from "styled-components";
// import { colLightBlueText, colGreen } from "../../../../constants/variables";

export const ModalAnt = styled(Modal)`
    .ant-modal-body {
        max-height: 350px;
        padding: 0 20px;
    }
`;

export const RequestInfo = styled(Row)`
    // color: #d7d7d7;
    font-size: 12px;
    font-family: 'PoppinsRegular', sans-serif;
    span {
        margin: 0 10px 0 0;
        
        b {
            font-family: 'PoppinsBold', sans-serif;
        }
    }
`;

export const EditItems = styled(Row)`
    width: 100%;
    padding: 5px 0;
    margin: 5px 0;
`;