import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';
import {
    Container
} from '../../components';
import { RowAnt, PageTitle } from "./style";


export function Disclaimer() {
    const { t } = useTranslation();

    return (
        <RowAnt type="flex">
            <Container>
                <Row type="flex">
                    <PageTitle>{t('disclaimer_page_title')}</PageTitle>
                </Row>
                <Row type="flex">
                    <h3>{t('disclaimer_contents')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('disclaimer_contents_text')}} />

                    <h3>{t('disclaimer_referrals')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('disclaimer_referrals_text')}} />

                    <h3>{t('disclaimer_copyright')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('disclaimer_copyright_text')}} />

                    <h3>{t('disclaimer_legal')}</h3>
                    <p dangerouslySetInnerHTML={{__html: t('disclaimer_legal_text')}} />
                </Row>
            </Container>
        </RowAnt>
    );
}
