import styled from 'styled-components';
import { Row, Col } from 'antd';
import { device } from "../../../../constants/device";
import { Images } from "../../../../assets/images";
import { colGreen, colLightBlueText } from '../../../../constants/variables';
import { ButtonLinkGreen } from "../../../atoms";

export const RowAnt = styled(Row)`
    @media ${device.desktop} {
        max-width: 1200px;
        margin: auto;
        width: 100%;
        position: relative;
    }
`;

export const HowBlock = styled(Col)`
    display: inline-block;
    position: relative;
    min-height: auto;
    padding: 20px;
    vertical-align: top;
    margin-top: 15px;

    @media ${device.tablet} {
        min-height: 410px;
        margin-top: 30px;
    }
`;

export const HowBlockTitle = styled.h3`
    font-family: 'PoppinsBold', sans-serif;
    font-size: 18px;
    color: ${colGreen};
    width: 80%;
    line-height: 1.4;
    margin-top: 7px;
    margin-bottom: 0;
    min-height: auto;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;

    @media ${device.tablet} {
        display: block;
        min-height: 60px;
        margin-top: 10px;
        margin-left: 0;
    }

    @media ${device.laptopL} {
        font-size: 24px;
        width: 60%;
        min-height: 90px;
    }
`;

export const HowBlockText = styled.p`
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 14px;
    color: #333;
    line-height: 1.3;

    @media ${device.laptop} {
        font-size: 16px;
    }
`;

// TODO start  need to ask Nikita почему-то не отрабатывают стили
export const HowBlockLink = styled(ButtonLinkGreen)`
    position: relative;
    bottom: 0;
    left: 0;
    
    @media ${device.tablet} {
        position: absolute;
        left: 20px;
    }
`;
// TODO end 

export const HowBlockIcon = styled.span`
    border: 2px solid ${colLightBlueText};
    border-radius: 50%;
    height: 35px;
    width: 35px;
    position: relative;
    display: inline-block;
    left: 0;

    @media ${device.tablet} {
        display: block;
    }

    @media ${device.laptop} {
        left: -23px;
    }

    svg {
        width: 60%;
        height: 60%;
        fill: ${colLightBlueText};
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
    }

    i {
        font-size: 26px;
    }
`;

export const LeftBlock = styled(Col)`
    margin-right: 0;
    background: ${colLightBlueText};
    display: block;
    min-height: auto;
    width: 100%;
    position: relative;
    padding: 20px;
    z-index: 1;

    @media ${device.tablet} {
        display: table;
        min-height: 250px;
        width: 65.5%;
        padding: 50px;
        margin-right: 8px;
        margin-bottom: 100px;
    }
    
    &:before {
        content: '';
        width: 64px;
        height: 63px;
        position: absolute;
        top: -40px;
        left: 0;
        background: url(${Images.Lamp}) no-repeat;
        
        @media ${device.tablet} {
            left: -32px;
            top: -32px;
        }
    }
`;

export const RightBlock = styled(Col)`
    background: url(${Images.Unsplash2}) no-repeat;
    background-size: cover;
    display: block;
    min-width: 100%;
    background-position: center;
    min-height: 300px;
    position: relative;
    right: 0;
    z-index: 0;
    top: -25%;

    @media ${device.tablet} {
        display: inline-block;
        top: -25%;
        min-height: 440px;
        position: absolute;
        min-width: 670px;
        background-position: left;
    }
`;

export const LeftBlockText = styled.p`
    font-family: 'PoppinsThin', sans-serif;
    font-size: 34px;
    color: #fff;
    margin: 0;
    line-height: 1.2;
    letter-spacing: 1px;
    display: block;
    vertical-align: middle;
    text-align: center;
    width: 100%;

    @media ${device.tablet} {
        font-size: 70px;
        text-align: left;
        display: table-cell;
    }
`;