import { all, takeEvery, put } from 'redux-saga/effects'
import languagesService from '../../services/languages.service'
import notify from "../../utilities/Notify";

function* getAll(action) {
  try {
    const languages= yield languagesService.getLanguages();
    yield put({
      type: 'SET_LANGUAGES',
      payload: languages
    })
  } catch(e) {
    notify.error(e)
  }
}

function* fetchDataWatcher() {
  yield takeEvery('GET_LANGUAGES', getAll);
}

export function* languagesSaga() {
  yield all([fetchDataWatcher()])
}
