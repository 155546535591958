import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { Container } from "../../../atoms";
import {
   BlockTitle,
   SingleBlockSmall,
   City,
   LeftBlock,
   RightBlock,
   SingleBlockFullWidth,
   PromoteMusicButton,
   FirstBlock,
   Message,
   SubMessage,
   PromoteMusicButtonSecond,
   RowAnt
} from "./style";
import { ReactComponent as CameraSvg } from "../../../../assets/svg/camera.svg";

export function Freedom() {
  const { t } = useTranslation();

  return (
    <RowAnt type="flex" justify="space-between" className="pl-mb-10">
      <Container>
        <BlockTitle>
          <CameraSvg fill="#76d9ff" />
          {/* <img src={Images.CameraIcon} alt='Camera' /> */}
          {t("freedom_title")}
        </BlockTitle>
      </Container>
        <Container>
            <Row type="flex" justify="start" align="bottom">
                <SingleBlockSmall span={12}>
                    <City color="#fff" position="absolute">
                        Paris
                    </City>
                </SingleBlockSmall>
                <LeftBlock span={12}>
                    <City>Berlin</City>
                </LeftBlock>
                <RightBlock span={12}>
                    <City>London</City>
                </RightBlock>
            </Row>
        </Container>
      <Container>
        <SingleBlockFullWidth span={12}>
          <City>Kyiv</City>
        </SingleBlockFullWidth>
      </Container>
        <Container>
            <Row
                type="flex"
                justify="space-between"
                align="middle"
            >
              <Col xs={24} md={12}>
                  <PromoteMusicButton label={t("learn_more")} link="#" />
              </Col>
              <Col xs={24} md={12}>
                  <FirstBlock>
                      <Message>{t("freedom_block_message")}</Message>
                      <SubMessage>{t("freedom_block_submessage")}</SubMessage>
                      <PromoteMusicButtonSecond label={t("learn_more")} link="#" />
                  </FirstBlock>
              </Col>
            </Row>
        </Container>
    </RowAnt>
  );
}
