import React, {useCallback, useState} from 'react';
import PropTypes  from 'prop-types';
import moment from 'moment';
import { Badge, Icon } from 'antd';
import { BookingTimeSelect } from '../../../molecules';
import {
   AntRow,
   BookingCalendar,
   DateButton,
   DateButtonWrapper,
} from './style';
import {
   colGreen,
} from '../../../../constants/variables';
import {useMappedState} from "redux-react-hook";

export function BookingTimePicker(props) {

   const [date, setDate] = useState(moment());

   const schedule = useMappedState((state => state.schedule));
   const [isTimePickerActive, setIsTimePickerActive] = useState(false);

   const getEventsByDate = useCallback((date) => {
      // eslint-disable-next-line
      return schedule.filter((item) => {
         if(moment(item.start_time).date() === moment(date).date() && moment(item.start_time).month() === moment(date).month()) {
            return item;
         }
      });
   }, [schedule]);

   const getAvailTime = useCallback(() => {
      return getEventsByDate(date).map((item) => {
         return moment(item.start_time).format('HH:mm')
      })
   }, [date, getEventsByDate]);


   const onClick = useCallback((date) => {
      setIsTimePickerActive(true);
      setDate(date);
   }, []);

   const onSelect = useCallback((str) => {
      // eslint-disable-next-line
      const choosen = getEventsByDate(date).filter((item) => {
         if (moment(item.start_time).format('HH:mm') === str) {
            return item
         }
      });
      console.log(choosen);
      props.onChoose(choosen[0]._id)
   },[date, getEventsByDate, props]);

   function dateCellRender(value) {
      const listData = getEventsByDate(value);
      const badgeIcon = listData.length > 0 ?<Icon type="check" /> : null;
      return (
          <DateButtonWrapper>
             <Badge count={badgeIcon} style={{backgroundColor: `${colGreen}`, borderRadius: '50%'}}>
             <DateButton
                shape='circle'
                size='large'
                style={{
                border: listData.length > 0? `3px solid ${colGreen}` : 'none',
                backgroundColor: listData.length > 0 ?
                `${colGreen}` :
                'white',
                }}
                disabled={listData.length === 0}
                onClick={() => onClick(value)}
             >
             {value.format('D')}
             </DateButton>
             </Badge>
          </DateButtonWrapper>
      );
   }


   return (
      <AntRow
         type='flex'
         align='middle'
         justify='start'
      >
         <BookingCalendar
            fullscreen={false}
            dateFullCellRender={dateCellRender}
            defaultValue={date}
            headerRender={() => null}
         />
         {isTimePickerActive &&
            <BookingTimeSelect
              availTime={getAvailTime()}
              onSelect={onSelect}
            />
         }
      </AntRow>
   );
}

BookingTimePicker.propTypes = {
   bookedDates: PropTypes.array,
};

