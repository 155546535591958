import styled from 'styled-components';
import { device } from '../../../constants/device';
import { colGreen } from '../../../constants/variables';
import { AutoComplete, Button, Icon } from 'antd';

export const Title = styled.h2`
  text-align: center;
  font-size: 2em;
  font-family: 'OdinRounded', sans-serif;
  color: #fff;
  line-height: 1.2;
  width: 100%;
  margin: auto;

  @media ${device.tablet} {
    font-size: 3.7em;
  }

  @media ${device.laptop} {
    font-size: 4.2em;
    width: 90%;
  }
`;

export const Row = styled.div`
  background: ${colGreen};
  position: relative;
  padding-top: 50px;
  padding-bottom: 25px;

  &:before {
      background: ${colGreen};
      content: '';
      position: absolute;
      top: -12px;
      right: 0;
      left: 0;
      margin: auto;
      height: 50px;
      width: 85px;
      border-radius: 50%;
  }

  @media ${device.tablet} {
    padding-top: 100px;
    padding-bottom: 40px;
  }
`;

export const Wrapper = styled.div`
  border-radius: 50px;
  border: 3px solid #fff;
  min-height: 160px;
  display: flex;
  align-items: center;
  padding: 0;
  overflow: hidden;
  margin: auto;
  width: 100%;
  max-width: 90vw;
  position: relative;
  flex-direction: column;

  @media ${device.mobileS} {
    margin-top: 25px;
  }

  @media ${device.tablet} {
    margin-top: 50px;
    padding: 0 50px;
    flex-direction: row;
    padding: 0 10px;
    min-height: 90px;
  }

  .pl-select-instrument:after {
    display: none !important;
  }
`;

export const SearchDropdown = styled(AutoComplete)`
  color: #fff !important;
  font-family: 'PoppinsBold', sans-serif;
  font-size: 16px;
  min-width: 150px;
  position: relative;
  padding-right: 0;
  display: block;
  margin: 10px 0;

  @media ${device.tablet} {
    min-width: 250px;
    margin: 0;
    padding-right: 20px;
  }

  @media ${device.laptop} {
    min-width: 300px;
    margin: 0;
    padding-right: 20px;
  }
  
  &:after {
    content: '';
    height: 54px;
    width: 3px;
    position: absolute;
    right: 0;
    top: 50%;
    bottom: 50%;
    margin: auto;
    background-color: #fff;
    display: none;

    @media ${device.tablet} {
      display: block;
    }
  }

  .ant-select-selection__placeholder {
    color: #fff;
  }

  .ant-select-search__field {
    border: none
  }

  .ant-select-selection {
    background-color: transparent;
    border: none;
    
    .ant-select-arrow svg {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid #fff;
    }
  }
  
  &.ant-select-open .ant-select-selection,
  &.ant-select-focused .ant-select-selection {
    box-shadow: none;
  }
`;

export const SubmitSearch = styled(Button)`
  margin-left: auto;
  border: none;
  background-color: #fff !important;
  padding: 5px;
  height: 50px;
  width: 100%;
  transform: translateY(0);
  border-radius: 0 0 55px 55px;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: -4px;

  @media ${device.tablet} {
    height: 92px;
    width: 180px;
    border-radius: 0 55px 55px 0;
    transform: translateY(0);
    transform: translateX(0);
    right: -3px;
  }
`;

export const IconSearch = styled(Icon)`
  color: ${colGreen};
  font-size: 28px;
  font-weight: bold;

  @media ${device.tablet} {
    font-size: 44px;
  }
`;
