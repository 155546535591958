import React, {useCallback, useState} from 'react';
import {Calendar, Badge} from 'antd';
import { RowAnt } from "./style";
import moment from "moment";
import {RequestModal} from "../RequestModal/RequestModal";

export function SideCalendar({data}) {

    const [requests, setRequsts] = useState([]);
    const [selectedValue, setSelectedValue] = useState(moment());
    const [isShowModal, setShowModal] = useState(false);
    function showModal() {
        setShowModal(true)
    }

    function hideModal() {
        setShowModal(false)
    }
    const getEventsByDate = useCallback((date) => {
        // eslint-disable-next-line
        return data.filter((item) => {
            if(moment(item.start_time).date() === moment(date).date() && moment(item.start_time).month() === moment(date).month()) {
                return item;
            }
        });
    }, [data]);

    const onSelect = (val) => {
        setSelectedValue(moment(val));
        setRequsts(getEventsByDate(val));
        showModal();
    };

    function dateCellRender(value) {
        const count = getEventsByDate(value).length;
        return (
          <div>
              {count && count > 0 ?
                <p>
                  <Badge
                    status={"error"}
                    text={count}
                  />
                </p> : ''
              }
          </div>
        )
    }

    return (
        <RowAnt type="flex">
            <Calendar dateCellRender={dateCellRender} onSelect={onSelect}/>
            <RequestModal
              date={selectedValue}
              data={requests}
              isShowModal={isShowModal}
              hideModal={hideModal}
            />
        </RowAnt>
    );
}
