import { all, takeEvery, put } from 'redux-saga/effects'
import authService from '../../services/auth.service';
import userService from  '../../services/user.service';
import { message } from 'antd';
import notify from "../../utilities/Notify";

function* login(action) {
  try {
     const token = yield authService.login(action.payload.data);
     localStorage.setItem('token', token.token);
     action.payload.redirect('/');
  } catch(e) {
    console.log(e.response);
    message.error(e.response.data.message.message);
  }
}

function* register(action) {
  try {
    yield authService.register(action.payload.data);
    action.payload.redirect('/thanks');
    notify.success('you successfully registered')
  } catch(e) {
    message.error(e.response.data.message.message);
  }
}

function* registerSocial(action) {

  try {
    yield authService.registerSocial(action.payload.data);
    action.payload.redirect('/thanks');
    notify.success('you successfully registered')
  } catch(e) {
    message.error(e.response.data.message.message);
  }

}

function* loginSocial(action) {

  try {
    const token = yield authService.loginSocial(action.payload.data);
    localStorage.setItem('token', token.token);
    action.payload.redirect('/');
  } catch(e) {
    message.error(e.response.data.message.message || e.response.data.message.name);
  }

}


function* getCurrentUser({payload: {redirect}}) {
  try {
    const user = yield userService.getSelf();
    yield put({
      type: 'SET_CURRENT_USER',
      payload: {
        ...user
      }
    })
  } catch(e) {
    redirect();
    if(e.response.data.message) {
      message.error(e.response.data.message.message);
    }
  }
}

function* verifyAccount({payload}) {
  try {
    yield authService.verifyUSer(payload)
  } catch (e) {
    message.error(e.response.data.message.message);
  }
}

function* requestForRecoverPassword({payload}) {
  console.log(payload);
  try {
    yield authService.requestRecoverPassword(payload)
  } catch (e) {
    message.error(e.response.data.message.message);
  }
}

function* recoverPassword({payload}) {
  console.log(payload);
  try {
    yield authService.recoverPassword(payload);
    message.success('password was successfully changed')
  } catch (e) {
    message.error(e.response.data.message.message);
  }
}


function* fetchDataWatcher() {
  yield takeEvery('LOGIN_USER', login);
  yield takeEvery('LOGIN_BY_SOCIAL', loginSocial);
  yield takeEvery('REGISTER_USER', register);
  yield takeEvery('REGISTER_BY_SOCIAL', registerSocial);
  yield takeEvery('GET_CURRENT_USER', getCurrentUser);
  yield takeEvery('VERIFY', verifyAccount);
  yield takeEvery('REQUEST_FOR_RECOVER_PASSWORD', requestForRecoverPassword);
  yield takeEvery('RECOVER_PASSWORD', recoverPassword);
}

export function* authSaga() {
  yield all([fetchDataWatcher()])
}
