import React from "react";
import styled from "styled-components";
import { Images } from "../../../assets/images";

const Item = styled.p`
    color: #333;
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 16px;
    position: relative;
    display: block;
    line-height: 1;

    &:not(:last-child) {
        margin-bottom: 10px;
    }


    [type="checkbox"]:checked,
    [type="checkbox"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type="checkbox"]:checked + label,
    [type="checkbox"]:not(:checked) + label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
    }

    [type="checkbox"]:checked + label:before,
    [type="checkbox"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 1px;
        width: 16px;
        height: 16px;
        border: 1px solid #ddd;
        border-radius: 100%;
    }

    [type="checkbox"]:checked + label:after,
    [type="checkbox"]:not(:checked) + label:after {
        content: '';
        width: 21px;
        height: 18px;
        top: 0;
        left: 0;
        background: url(${Images.ListItemIcon}) no-repeat;
        background-size: contain;
        position: absolute;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    
    [type="checkbox"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
`;


export function CheckButton(props) {
  return (
    <Item>
        <input type="checkbox" id={props.id} name={props.nameGroup} onChange={props.onChange} checked={props.checked}/>
        <label htmlFor={props.id}>{props.label}</label>
    </Item>
  );
}
