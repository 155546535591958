import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GreenButton,
  LessonSetupSelect,
  ModalAnt,
  ModalContainer,
  ModalCreateArea,
  ModalSchedule,
  ModalUpdateArea,
  ModeRow,
  ModeSwitcher,
  ModeTitle,
  RedButton,
  SelectTimeWrapper,
  TimePickerAnt,
  UpdateSelect,
  UpdateTimeWrapper
} from "./style";
import { useDispatch, useMappedState } from "redux-react-hook";
import { Table, Tooltip, Button, Icon, Popconfirm } from "antd";
import Divider from "antd/es/divider";
import moment from "moment";

export function AddEventModal(props) {
  const { t } = useTranslation();

  const user = useMappedState((state) => state.auth);

  const classTypes = user.user.metadata.class_types;
  const instruments = user.user.metadata.instruments;
  const format = 'HH:mm';
  const { Option } = LessonSetupSelect;
  const [mode, setMode] = useState(true);

  const [startTime, setStartTime] = useState(moment(props.date).hours(8).minutes(30));
  const [endTime, setEndTime] = useState(moment(props.date).hours(8).minutes(30).add(50, 'm'));

  useEffect(() => {
    setStartTime(moment(props.date).hours(8).minutes(30));
    setEndTime(moment(props.date).hours(8).minutes(30).add(50, 'm'));
  }, [props.date]);

  const [Instruments, setInstruments] = useState([]);
  const [ClassTypes, setClassTypes] = useState([]);

  const [updateItem, setUpdateItem] = useState();

  const onchangeStartTime = useCallback((e) => {
    setStartTime(moment(e));
    setEndTime(moment(e).add(50, 'm'))
  }, []);

  const onChangeEndTime = useCallback((e) => {
    setEndTime(moment(e));
  },[]);

  const disabledEndHours = useCallback(() => {
    let hourArray = [];
    for (let i = 0; i < startTime.hours() + 1; i++) {
      hourArray.push(i)
    }
    return hourArray
  },[startTime]);

  const disableUpdateHours = useCallback((val) => {
    let hourArray = [];
    for (let i = 0; i < startTime.hours() + 1; i++) {
      if (i !== val) {
        hourArray.push(i)
      }
    }
    return hourArray
  },[startTime]);

  const dispatch = useDispatch();

  const onModeChange = useCallback(() => {
    setMode(!mode);
  }, [mode]);

  const handleChangeInstrument = useCallback((values) => {
    console.log(values);
    setInstruments(values)
  }, []);

  const handleChangeClassTypes = useCallback((values) => {
    setClassTypes(values)
  }, []);

  const handleChangeUpdate = useCallback((value, field) => {
    setUpdateItem({...updateItem, ...{[field]: value}})
  }, [updateItem]);

  const cancelUpdate = useCallback(() => {
    setUpdateItem(null);
  }, []);

  const removeScheduleItem = useCallback((_id) => {
    console.log(_id);
    dispatch({
      type: 'REMOVE_SCHEDULE_ITEM',
      payload: _id
    });
  }, [dispatch]);

  const cancelLessonItem = useCallback((_id) => {
    console.log(_id);
    dispatch({
      type: 'CANCEL_LESSON_ITEM',
      payload: _id
    })
  }, [dispatch]);

  const updateScheduleItem = useCallback(() => {
    dispatch({
      type: 'UPDATE_SCHEDULE_ITEM',
      payload: updateItem
    });
    cancelUpdate();
  }, [updateItem, dispatch, cancelUpdate]);



  const chooseUpdateItem = useCallback((item) => {
    const newItem = {
      _id: item._id,
      start_time: item.start_time,
      class_types: item.class_types.map((type) => type._id),
      instruments: item.instruments.map((inst) => inst._id)
    };
    setUpdateItem(newItem);
  }, []);

  const createNewScheduleItem = useCallback(() => {
    dispatch({
      type: 'CREATE_SCHEDULE_ITEM',
      payload: {
        instruments: Instruments,
        class_types: ClassTypes,
        start_time: startTime.toDate()
      }
    })
  }, [dispatch, Instruments, ClassTypes, startTime]);


  const bulkCreateNewScheduleItems = useCallback(() => {
    const hours = endTime.hours() - startTime.hours();
    const array = [];
    for (let i =0; i < hours; i++) {
      array.push({
        instruments: Instruments,
        class_types: ClassTypes,
        start_time: startTime.add(1, 'h').toDate()
      })
    }
    dispatch({
      type: 'CREATE_SCHEDULE_LIST',
      payload: array
    })
  },[dispatch, Instruments, ClassTypes, startTime, endTime]);

  // eslint-disable-next-line
  const events = useMappedState((state) => state.schedule).filter((item) => {
    if (moment(item.start_time).month() === moment(props.date).month()
      && moment(item.start_time).date() === moment(props.date).date()) {
      return item
    }
  });



  const columns = [
    {
      title: t('request_page_start_time'),
      dataIndex: 'start_time',
      key: 'start_time',
      render: item => (
        <>
          {moment(item).format('LT')}
        </>
      )
    },
    {
      title: t('request_page_end_time'),
      dataIndex: 'start_time',
      key: 'end_time',
      render: item => (
        <>
          {moment(item).add(50, 'm').format('LT')}
        </>
      )
    },
    {
      title: t('request_page_instruments'),
      dataIndex: 'instruments',
      key: 'instruments',
      render: items => (
        <>
        {items.map((item, index) => {
          return (
            <span key={index}>
              {item.name}
              {index !== items.length - 1 && <Divider type="vertical" />}
            </span>
          );
          })
        }
        </>
      )
    },
    {
      title: t('request_page_class_type'),
      dataIndex: 'class_types',
      key: 'class_types',
      render: items => (
        <>
        {items.map((item, index) => {
          return (

            <span key={index}>
              {item.type}
              {index !== items.length - 1 && <Divider type="vertical" />}
            </span>
          );
        })
        }
        </>
      )
    },
    {
      title: t('request_page_status'),
      dataIndex: 'lesson_id',
      key: 'status',
      render: (item) => (
        <>{item ? 'Booked': 'Available'}</>
      )

    },

    {
      title: t('request_page_action'),
      key: 'action',
      render: (item) => {
        return (
          <>
            {moment(props.date) >= moment().hours(0).minutes(0) &&
              <>
                <GreenButton
                  disable={moment(item.start_time) < moment() ||  item.lesson_id}
                  onClick={() => chooseUpdateItem(item)}
                >
                  <Icon type="edit"/>
                </GreenButton>
                <Divider type="vertical"/>
                {
                  item.lesson_id ?
                    <Popconfirm
                      placement="top"
                      title={t('Are you sure to decline book?')}
                      onConfirm={() => cancelLessonItem(item.lesson_id)}
                      okText={t('decline_yes')} cancelText={t('decline_no')}
                    >
                      <Tooltip title={t('request_decline')}>
                        <RedButton>
                          <Icon type="close"/>
                        </RedButton>
                      </Tooltip>
                    </Popconfirm>
                  :
                    <Popconfirm
                      placement="top"
                      title={t('Are you sure to decline book?')}
                      onConfirm={() => removeScheduleItem(item._id)}
                      okText={t('decline_yes')} cancelText={t('decline_no')}
                    >
                      <Tooltip title={t('request_cancel')}>
                        <RedButton>
                          <Icon type="delete"/>
                        </RedButton>
                      </Tooltip>
                    </Popconfirm>
                }
              </>
            }
          </>
      )},
    },
  ];

  const disabledHours = useCallback(() => {
      return events.map((item) => {
        return moment(item.start_time).hours();
      })
    },
    [events]
  );

  return (
    <ModalAnt
        title={props.date.format('LL').toString()}
        visible={props.isShowModal}
        onOk={() => props.hideModal(!props.isShowModal)}
        onCancel={() => props.hideModal(!props.isShowModal)}
        okText={t("customize_lesson_modal_ok")}
        cancelText={t("customize_lesson_modal_cancel")}
    >
      {
        updateItem ?
          <ModalUpdateArea>
            <UpdateSelect
              mode="multiple"
              placeholder={t('booking_select_instrument')}
              value={updateItem.instruments}
              onChange={(val) => handleChangeUpdate(val, 'instruments')}
            >
              {instruments.map((item, index) => {
                return <Option key={index} value={item._id}>{item.name}</Option>
              })}
            </UpdateSelect>
            <UpdateSelect
              mode="multiple"
              value={updateItem.class_types}
              placeholder={t('booking_select_type_lesson')}
              onChange={(val) => handleChangeUpdate(val, 'class_types')}
            >
              {
                classTypes.map((item, index) => {
                  return <Option key={index} value={item._id}>{item.type}</Option>
                })
              }
            </UpdateSelect>
            <UpdateTimeWrapper>
              {t(' from')}
              {
                console.log(updateItem.start_time)
              }
              <TimePickerAnt
                value={moment(updateItem.start_time)}
                disabledHours={disableUpdateHours}
                onChange={(e) => handleChangeUpdate(e, 'start_time')}
                format={format}
                minuteStep={30}
              />
              {t('to')}
              <TimePickerAnt format={format} value={moment(updateItem.start_time).add(50, 'm')} disabled/>
            </UpdateTimeWrapper>
            <Button
              onClick={updateScheduleItem}
            >
              Save
            </Button>
            <br/>
            <Button
              type={'danger'}
              onClick={cancelUpdate}
            >
              Cancel
            </Button>
          </ModalUpdateArea> :
          <ModalContainer>
            {moment(props.date) >= moment().hours(23).minutes(59) && <ModalCreateArea>
              <ModeRow>
                <ModeTitle style={{
                  marginLeft: 'auto',
                }}>Single</ModeTitle>
                <ModeSwitcher onChange={onModeChange}/>
                <ModeTitle>Multiple</ModeTitle>
              </ModeRow>
              {mode ?
                <ModeRow>
                  <LessonSetupSelect
                    mode="multiple"
                    placeholder={t('booking_select_instrument')}
                    onChange={handleChangeInstrument}
                  >
                    {instruments.map((item, index) => {
                      return <Option key={index} value={item._id}>{item.name}</Option>
                    })}
                  </LessonSetupSelect>
                  <LessonSetupSelect
                    mode="multiple"
                    placeholder={t('booking_select_type_lesson')}
                    onChange={handleChangeClassTypes}
                  >
                    {
                      classTypes.map((item, index) => {
                        return <Option key={index} value={item._id}>{item.type}</Option>
                      })
                    }
                  </LessonSetupSelect>
                  <SelectTimeWrapper>
                    <span>{t('select_lesson_time')}</span>
                    {t(' from')}
                    <TimePickerAnt value={startTime} disabledHours={disabledHours} onChange={onchangeStartTime}
                                   format={format} minuteStep={30}/>
                    {t('to')}
                    <TimePickerAnt format={format} value={endTime} disabled/>
                    <Button
                      onClick={createNewScheduleItem}
                      disabled={Instruments.length === 0 && ClassTypes.length === 0}
                    >
                      Save
                    </Button>
                  </SelectTimeWrapper>
                </ModeRow> :
                <ModeRow>
                  <LessonSetupSelect
                    mode="multiple"
                    placeholder={t('booking_select_instrument')}
                    onChange={handleChangeInstrument}
                  >
                    {instruments.map((item, index) => {
                      return <Option key={index} value={item._id}>{item.name}</Option>
                    })}
                  </LessonSetupSelect>
                  <LessonSetupSelect
                    mode="multiple"
                    placeholder={t('booking_select_type_lesson')}
                    onChange={handleChangeClassTypes}
                  >
                    {
                      classTypes.map((item, index) => {
                        return <Option key={index} value={item._id}>{item.type}</Option>
                      })
                    }
                  </LessonSetupSelect>
                  <SelectTimeWrapper>
                    <span>{t('select_lesson_time')}</span>
                    {t(' from')}
                    <TimePickerAnt value={startTime} disabledHours={disabledHours} onChange={onchangeStartTime}
                                   format={format} minuteStep={30}/>
                    {t('to')}
                    <TimePickerAnt format={format} value={endTime} onChange={onChangeEndTime}
                                   disabledHours={disabledEndHours} minuteStep={30}/>
                    <Button
                      onClick={bulkCreateNewScheduleItems}
                      disabled={Instruments.length === 0 && ClassTypes.length === 0}
                    >
                      Save
                    </Button>
                  </SelectTimeWrapper>
                </ModeRow>
              }
            </ModalCreateArea>
            }
            <ModalSchedule>
              <Table columns={columns} dataSource={events} />
            </ModalSchedule>
          </ModalContainer>
      }
    </ModalAnt>
  );
}
