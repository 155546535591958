import React from 'react';
import { useTranslation } from "react-i18next";
import { Images } from '../../../assets/images';
import {
    RatingIconAnt,
    RowAnt,
    BlockHeader,
    TeacherName,
    Rating,
    SecondTitle,
    TeacherFunction,
    TeacherCountry,
    BlockText,
    FirstBlock,
    TeacherPhoto,
    SecondBlock,
    TeacherText,
    TeacherAdditional,
    HireButton,
    Additional,
    ThirdBlock,
    TeacherPrice,
    LessonTime
} from "./style";
import {Link} from "react-router-dom";

function retingList(rating) {
    let row = [];
  
    for (let i = 0; i < rating; i++) {
      row.push(<RatingIconAnt type="star" theme="filled" key={i} />);
    }
    return row;
  }



export function TeacherBlock(props) {
    const { t } = useTranslation();
    return (
        <RowAnt type="flex">
            <BlockHeader type="flex">
                <Link to={`/user/${props._id}`}>
                    <TeacherName>{props.title}</TeacherName>
                </Link>
                {props.rating &&
                    <Rating>{retingList(props.rating)}</Rating>
                }

                {props.title2 &&
                    <SecondTitle>{props.title2}</SecondTitle>
                }
                
                <TeacherFunction>
                    <img src={Images.LocationIcon} alt="location icon" />
                    <img src={Images.CameraIcon} alt="camera icon" />
                </TeacherFunction>
                <TeacherCountry>
                    <img src={props.country} alt="teacher country" />
                </TeacherCountry>
            </BlockHeader>
            <BlockText type="flex" justify="space-between">
                <FirstBlock>
                    <TeacherPhoto to={`/user/${props._id}`}>
                        <img src={props.teacherPhoto} alt="teacherPhoto" />
                    </TeacherPhoto>
                </FirstBlock>
                <SecondBlock type="flex" justify="space-between">
                    <TeacherText>
                        {props.text}
                    </TeacherText>
                    <TeacherAdditional type="flex" justify="space-between">
                        <HireButton to={'/booking/' + props._id}>
                            {t("teacher_list_button_text")} &rarr;
                        </HireButton>
                        {/*<Additional>*/}
                            {/*Entfernung: {props.distance} km*/}
                        {/*</Additional>*/}
                        <Additional>
                            {props.city}, {props.age} {t("age")}
                        </Additional>
                    </TeacherAdditional>
                </SecondBlock>
                <ThirdBlock type="flex">
                    <TeacherPrice>
                        {props.price} €
                    </TeacherPrice>
                    <LessonTime>
                        55 Minutes
                    </LessonTime>
                </ThirdBlock>
            </BlockText>
        </RowAnt>
    );
}
