import { all, takeEvery, put } from 'redux-saga/effects'
import classTypesService from '../../services/classTypes.service'
import notify from "../../utilities/Notify";

function* getAll(action) {
  try {
    const class_types = yield classTypesService.getClassTypes();
    yield put({
      type: 'SET_CLASS_TYPES',
      payload: class_types
    })
  } catch(e) {
    notify.error(e)
  }
}

function* fetchDataWatcher() {
  yield takeEvery('GET_CLASS_TYPES', getAll);
}

export function* classTypessSaga() {
  yield all([fetchDataWatcher()])
}
