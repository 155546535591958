import { Row, Checkbox, Button } from 'antd';
import styled from "styled-components";
import { device } from "../../../../constants/device";
import { colGreen } from '../../../../constants/variables';
import { Link } from "react-router-dom";

export const BlockText = styled(Row)`
    background-color: #fff;
    font-family: 'PoppinsRegular', sans-serif;
    padding: 20px;
    align-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    animation: fadeIn .5s ease-in


    @keyframes fadeIn {
        from {
            opacity: 0
        }
        
        to {
            opacity: 1
        }
    } 
`;

export const FirstBlock = styled.div`
    width: 15%;
    max-width: 110px;
    min-width: 102px;
`;

export const SecondBlock = styled(Row)`
    width: 50%;
    padding: 0 10px;

    @media ${device.laptop} {
        width: 70%;
    }
`;

export const ThirdBlock = styled(Row)`
    width: 10%;
    justify-content: center;
    align-content: center;
    min-width: 65px;
    flex-direction: column;
`;

export const StudentPhoto = styled(Link)`
    display: inline-block;

    img {
        width: 102px;
        height: 102px;
        border-radius: 50%;
    }
`;

export const StudentName = styled.h3`
    font-family: 'PoppinsRegular', sans-serif;
    color: #333;
    margin-bottom: 10px;
    line-height: 1;
`;

export const OtherInfo = styled(Row)`
    // color: #d7d7d7;
    font-size: 12px;
    font-family: 'PoppinsRegular', sans-serif;
    order: 1;

    @media ${device.laptop} {
        order: 2;
    }

    span {
        margin: 0 10px 0 0;
        
        b {
            font-family: 'PoppinsBold', sans-serif;
        }
    }
`;

export const SearchCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    background-color: transparent;
    border-color: ${colGreen};
    border-radius: 25px;
    border-width: 3px;
    height: 28px;
    width: 28px;
  }
  
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #f7ef0a;
    border-width: 3px;
  }
  
  .ant-checkbox-inner::after {
    top: 25%;
    left: 24%;
    width: 10px;
    height: 23px;
  }
`;

export const CancelCheckbox = styled(Button)`
    outline: none;
    background: transparent;
    font-size: 26px;
    margin-top: 10px;
    color: red;
    border: 3px solid red;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    display: block;
    padding: 0;

    i {
        svg {
            display: none;
        }
    }

    &:hover {
        i svg {
            display: inline-block;
            position: relative;
            top: -7px;
            left: -2px;
        }
    }
`;