const initialState = {
  data: [],
  filters: {
    item: {
      condition: {}
    },
    array: {
      one_of: {}
    }
  },
  sort: null,
  skip: 0,
  limit: 5,
  total: 0
};

const teachersReducer = (state = initialState, action) => {
  const newState = {...state, ...{}};
  switch (action.type) {
    case 'SET_TEACHERS':
      return {
        ...state,
        data: action.payload
      };
    case 'SET_MORE_TEACHERS':
      return {
        ...state,
        data: [
          ...state.data,
          ...action.payload
        ]
      };

    case 'SET_FILTERS':
      const array = {one_of: {...state.filters.array.one_of,  ...(action.payload.array ? {...action.payload.array.one_of} : {})}};
      const item = {
        condition: {
          ...state.filters.item.condition,
          ...(action.payload.item && action.payload.item.condition ? {...action.payload.item.condition} : {})
        }
      };
      const filters = {
        array,
        item: item
      };
      return {
        ...state,
        filters,
        skip: 0
      };

    case 'SET_NULL_FILTERS':
      const removeField = state.filters[action.payload.type][action.payload.fieldType];
      delete removeField[action.payload.key];
      return {
        ...state,
        filters: {
          ...state.filters,
          ...state.filters[action.payload.type][action.payload.fieldType] = removeField
        }
      };
    case 'SET_SORT':
      return {
        ...state,
        sort: {
          ...state.sort,
          ...action.payload
        }
      };
    case 'SET_NULL_SORT':
      const newSort = {...state.sort};
      delete newSort[action.payload];
      return {
        ...state,
        sort: {
          ...newSort
        }
      };
    case 'SET_SKIP':
      newState.skip = action.payload.skip;
      return newState;
    case 'SET_TEACHERS_TOTAL':
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
};

export default teachersReducer;
