import styled from 'styled-components';
import { device } from '../../../../constants/device';

// export const CentralContentContainer = styled.div`
//     width: 100%;
//     display: block;
//     padding: 0 20px 40px;

//     @media ${device.tablet} {
//         width: 65vw;
//         display: inline-block;
//         vertical-align: top;
//         padding: 0 0 40px;
//     }

//     @media ${device.laptop} {
//         width: 70vw;
//     }

//     @media ${device.laptopL} {
//         width: calc(100% - 405px);
//     }
// `;

export const CentralContentContainer = styled.div`
    width: 100%;
    display: block;
    padding: 10px;

    @media ${device.tablet} {
        width: calc(100% - 405px);
        display: inline-block;
        vertical-align: top;
        padding-bottom: 40px;
        padding-left: 50px;
        padding-right: 10px;
    }
`;
