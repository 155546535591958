const initialState = [

];

const requestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_REQUESTS':
      return initialState.concat(action.payload);
    case 'NULL_REQUESTS':
      return initialState;
    default:
      return state;
  }
};

export default requestsReducer;
