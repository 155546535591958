import React from 'react';
import { Row } from 'antd';
import { useTranslation } from "react-i18next";
import { Container } from "../../../../components/atoms";
import {
    BlockTitle,
    LeftBlock,
    RightBlock,
    PromoteMusicBlock,
    PromoteMusicTitle,
    PromoteMusicText,
    PromoteMusicButton,
    RowAnt
} from "./style";

export function OurMission() {
    const {t} = useTranslation();

    return (
        <RowAnt type="flex" justify="space-between" className="pl-pb-2">
            <Container>
                <BlockTitle>{t('our_mission')}</BlockTitle>
            </Container>
            <LeftBlock span={16} xs={24} md={16}></LeftBlock>
            <RightBlock span={8} xs={24} md={8}></RightBlock>
            <Container>
                <PromoteMusicBlock>
                    <PromoteMusicTitle>{t('our_mission_title')}</PromoteMusicTitle>
                    <PromoteMusicText>{t('our_mission_text')}</PromoteMusicText>
                    <Row type="flex" justify="end">
                        <PromoteMusicButton>{t('learn_more')} &rarr;</PromoteMusicButton>
                    </Row>
                </PromoteMusicBlock>
            </Container>
        </RowAnt>
    );
}
