import {useDispatch} from "redux-react-hook";
import {useCallback, useEffect, useState} from "react";

const useLoginPage = (form, history) => {
  const dispatch = useDispatch();
  const {getFieldDecorator} = form;
  const [user, setUser] = useState();

  const submitForm = useCallback((e) => {
    e.preventDefault();
    dispatch({
      type: 'LOGIN_USER',
      payload: {
        data: form.getFieldsValue(),
        redirect: history.push
      }
    });
  }, [dispatch, form, history]);

  const responseGoogle = (response) => {
    setUser({
      email: response.profileObj.email,
      token: response.accessToken,
      source: 'google'
    });
  };
  const responseFacebook = (response) => {
    setUser({
      token: response.accessToken,
      facebook_id: response.id,
      source: 'facebook'
    });
  };

  useEffect(() => {
    if (user) {
      dispatch({
        type: 'LOGIN_BY_SOCIAL',
        payload: {
          data: user,
          redirect: history.push
        }
      });
    }

  }, [dispatch, history.push, user]);

  return {
    responseFacebook,
    responseGoogle,
    submitForm,
    user,
    setUser,
    getFieldDecorator
  }
};

export default useLoginPage;
