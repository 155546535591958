import React from 'react';
import { Footer } from '../../components/molecules';
import {withRouter} from "react-router-dom";

export const FooterLayout = withRouter((props) => {
   return (
      <div className="pl-singleFooter">
         {props.children}
         <Footer />
      </div>
   )
});
