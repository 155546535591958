const initialState = {
  rooms: [],
  dialog: {}
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ROOMS':
      return {...state, ...action.payload};
    case 'SET_DIALOG':
      return {...state, ...action.payload};
    case 'ADD_MESSAGE':
      return {
        ...state,
        dialog: {
          ...state.dialog,
          data: [
            ...state.dialog.data,
            action.payload
          ]
        }
      };

    case 'ADD_DIALOG':
      return {
        ...state,
        rooms: [...state.rooms, action.payload]
      };

    default:
      return state;
  }
};

export default messagesReducer;
