import React from 'react';
import { DropDownMenu } from '../../../atoms';
import { CustomizedDropdown } from './style';

export function CustomDropdown(props) {

   return (
      <CustomizedDropdown
         trigger={props.trigger}
         overlay={<DropDownMenu menu={props.overlay} />}
      />
   );
}

CustomizedDropdown.defaultProps = {
   trigger: 'click',
   overlay: null,
};