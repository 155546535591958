import React, {useCallback, useEffect, useState} from 'react';
import { Footer, Header } from '../../components/molecules';
import {withRouter} from "react-router-dom";

export const HeaderFooterLayout = withRouter((props) => {
  const [isShowSearch, setSearchOpen] = useState();
  useEffect(() => {
      setSearchOpen(false);
  }, [props.children]);


  const toggleSearch = useCallback(() => {
    setSearchOpen(!isShowSearch);
  }, [isShowSearch]);

   return (
      <>
         <Header isShowSearch={isShowSearch} toggleSearch={toggleSearch}/>
         {props.children}
         <Footer/>
      </>
   )
});
