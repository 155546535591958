import React from "react";
import { withTranslation, useTranslation } from 'react-i18next';
import {Layout, Row, Form, Icon, Input, Modal} from "antd";
import {
  LogoItem,
  FormInput,
  LoginForm,
  SlickSlider,
  TestimonialUserInfo,
  TestimonialText,
  Testimonial,
  SliderContainer,
  ModalButton,
  ModalReturnButton,
  DatePickerAnt
} from "./style";
import {
  Container,
  Button as ButtonLogin
} from "../../components/atoms";
import { Images } from "../../assets/images";
import RegisterRole from "../../components/molecules/RegisterRole/RegisterRole";
import useRegisterPage from "../../hooks/RegisterPage";
import { Link } from "react-router-dom";


function AdminAddNewUser({form, history}) {
  const { t } = useTranslation();
  const {
    submitForm,
    user,
    getFieldDecorator,
    sliderSettings,
    handleChooseRole,
    handleChangeEmail,
    handleCancel,
    isShowModal,
    changeUserEmail,
    email,
    // birthday,
    onBirthdayChange
  } = useRegisterPage(form, history);

  const dateFormat = 'MM/DD/YYYY';

  return (
    <Layout>
      <Row type="flex" justify="center" style={{flexDirection: 'column'}}>
        <Link to={'/'}><LogoItem/></Link>
        {!user.role ?
          <RegisterRole handleChoosRole={handleChooseRole}/>
        :
          (
            <>
              <LoginForm layout="inline" onSubmit={submitForm}>
                <FormInput>
                  {getFieldDecorator('first_name', {
                    rules: [{ required: true, message: t("registration_form_text1") }],
                  })(
                    <Input
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder={t("registration_form_placeholder_firstname")}
                      name={'lol'}
                      required
                    />
                  )}
                </FormInput>
                <FormInput>
                  {getFieldDecorator('last_name', {
                    rules: [{ required: true, message: t("registration_form_text2") }],
                  })(
                    <Input
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder={t('registration_form_placeholder_lastname')}
                      required
                    />
                  )}
                </FormInput>
                <FormInput>
                  {getFieldDecorator('email', {
                    rules: [{ required: true, message: t("registration_form_text3") }],
                  })(
                    <Input
                      prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="email"
                      placeholder={t('registration_form_placeholder_email')}
                      required
                    />
                  )}
                </FormInput>
                <FormInput>
                  {getFieldDecorator('gender', {
                    rules: [{ required: true, message: t("registration_form_text4") }],
                  })(
                    <Input
                      prefix={<Icon type="eye" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder={t('registration_form_placeholder_gender')}
                      required
                    />
                  )}
                </FormInput>
                <FormInput>
                  {getFieldDecorator('password', {
                    rules: [
                      { required: true, message: t("registration_form_text5") },
                      { min: 6, message: t("registration_form_text6")}
                    ],
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder={t('registration_form_placeholder_password')}
                      required
                    />
                  )}
                </FormInput>
                <FormInput>
                  {getFieldDecorator('confirm-password', {
                    rules: [{ required: true, message: t("registration_form_text7")},
                      {
                        validator(rule, value, callback) {
                          if (form.getFieldsValue().password && value && form.getFieldsValue().password !== value) {
                            callback(t("registration_form_text8"))
                          }
                        }
                      }
                    ],
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder={t('registration_form_placeholder_passwordconfirm')}
                      required
                      disabled={!form.getFieldsValue().password}
                    />
                  )}
                </FormInput>
                <FormInput>
                  <DatePickerAnt
                    placeholder={t('registration_form_placeholder_birthdaydate')}
                    // value={birthday}
                    onChange={onBirthdayChange}
                    format={dateFormat}
                  />
                </FormInput>
                <br/>
                <Form.Item>
                  <ButtonLogin
                    htmlType="submit"
                    class="login-form-button" 
                    label={t('registration_form_registration_button')}
                  />
                </Form.Item>
              </LoginForm>

              <Modal
                title={t('registration_form_placeholder_enteremail')}
                visible={isShowModal}
                onCancel={handleCancel}
                footer={[
                  <ModalReturnButton key="back" onClick={handleCancel}>
                    {"t('registration_form_placeholder_return')"}
                  </ModalReturnButton>,
                  <ModalButton key="submit" htmlType="submit" type="primary" onClick={handleChangeEmail}>
                    {"t('registration_form_placeholder_submit')"}
                  </ModalButton>,
                ]}
              >
                <Form onSubmit={handleChangeEmail}>
                  <FormInput>
                    {getFieldDecorator('social-email', {
                      rules: [
                        {required: true, message: t("registration_form_enteremail")},
                        {
                          type: 'email', message: t("registration_form_emailnotvalid")
                        }]
                    })(
                      <Input
                        prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="email"
                        placeholder={t('registration_form_placeholder_email')}
                        required
                        value={email}
                        onChange={changeUserEmail}
                      />
                    )}
                  </FormInput>
                </Form>
              </Modal>
            </>
          )
        }
      </Row>
      <Row type="flex" justify="center">
        <SliderContainer>
          <SlickSlider {...sliderSettings}>
            <Testimonial type="flex" justify="center">
              <Container>
                <TestimonialText>{t('testimonial_text1')}</TestimonialText>
                <TestimonialUserInfo><img src={Images.userPhoto} alt={'user-name'}/>{t('testimonial_user_info1')}</TestimonialUserInfo>
              </Container>
            </Testimonial>
            <Testimonial type="flex" justify="center">
              <Container>
                <TestimonialText>{t('testimonial_text2')}</TestimonialText>
                <TestimonialUserInfo><img src={Images.userPhoto} alt={'user-name'}/>{t('testimonial_user_info2')}</TestimonialUserInfo>
              </Container>
            </Testimonial>
            <Testimonial type="flex" justify="center">
              <Container>
                <TestimonialText>{t('testimonial_text3')}</TestimonialText>
                <TestimonialUserInfo><img src={Images.userPhoto} alt={'user-name'}/>{t('testimonial_user_info3')}</TestimonialUserInfo>
              </Container>
            </Testimonial>
          </SlickSlider>
        </SliderContainer>
      </Row>
    </Layout>
  );
}

const RegisterNewUser = withTranslation()(AdminAddNewUser);

export const addNewUser = Form.create({ name: 'register_form' })(RegisterNewUser);
