import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { Icon, Row } from 'antd';
import { colGreen } from '../../../constants/variables';
import Slider from "react-slick";
import {Link} from "react-router-dom";

const RowAnt = styled(Row)`
  background-color: #fff;
  margin-bottom: 20px;
`;

const SliderWrapper = styled(Slider)`
width: 100%;
.slick-arrow {
  background-color: #fff;
  
  &.slick-prev,
  &.slick-next {
    top: 26px;
    
    &:before {
        color: #76d9ff;
        font-family: 'PoppinsBold', sans-serif;
        font-size: 24px;
      }
  }
  
  &.slick-prev {
    left: 90%;
    z-index: 1;
  }
  
  &.slick-next {
    right: 4%;
    z-index: 1;
  }
}

.slick-dots {
  bottom: -40px;
}

.slick-dots li button:before {
  color: #e6e6e6;
  font-size: 20px;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  color: ${colGreen};
}
`;

const BlockText = styled(Row)`
  font-family: 'PoppinsRegular', sans-serif;
  font-size: 18px;
  padding: 25px;
`;

const FeedbackUserInfo = styled.div`
  font-family: 'PoppinsRegular', sans-serif;
  font-size: 16px;
  line-height: 1.2;
  padding: 25px;
  
  img {
    display: inline-block !important;
    vertical-align: top;
    width: 55px;
    height: 55px;
    margin-right: 30px;
  }
`;
const FeedbackUser = styled.div`
  display: inline-block;
  p {
    display: block;
    margin-bottom: 3px;
    margin-top: 8px;
  }
`;

const RatingList = styled.div`
  display: block;
  line-height: 1;
  
  .anticon {
    margin-right: 3px;
    svg {
      height: 10px;
      width: 12px; 
    }
  } 
`;

const Feedback = styled.div`
  margin: auto;
`;

function ratingList(mark) {
  let row = [];

  const IconAnt = styled(Icon)`
    color: ${colGreen};
    height: 12px;
    width: 13px;

    &:not(:last-child) {
      margin-right: 3px;
    }
  `;

  for (let i = 0; i < mark; i++) {
    row.push(<IconAnt type="star" theme="filled" key={i} />);
  }
  return row;
}

export function FeedbackItem(props) {

  const {
    first_name,
    last_name,
    mark,
    comment,
    _id,
    avatar
  } = props.data;

  return (
    <RowAnt>
      <SliderWrapper>
        <Feedback>
          <BlockText>{comment}</BlockText>
          <FeedbackUserInfo>
            <Link to="/">
              <img src={process.env.REACT_APP_PUBLIC_FILE_URL + '/' + _id + '/' + avatar} alt={'user-name'}/>
            </Link>
            <FeedbackUser>
              <Link style={{
                color: '#383838'
              }}>
                <p>{first_name + ' ' + last_name}</p>
              </Link>
              <RatingList>{ratingList(mark)}</RatingList>
            </FeedbackUser>
          </FeedbackUserInfo>
        </Feedback>
      </SliderWrapper>
    </RowAnt>

  );
}
