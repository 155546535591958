import React from "react";
import { Row, UserImage, UserName, UnreadMessage } from "./style";

export const ChatRoom = ({data, onChooseRoom}) => {
  return (
    <Row
      style={{
        ...(data.unreadMessage > 0 ? {backgroundColor: 'rgba(102, 222, 130, 0.28)'} : {backgroundColor: '#fff'}) 
      }}
      onClick={() => {onChooseRoom(data._id)}}
    >
      <UserImage src={process.env.REACT_APP_PUBLIC_FILE_URL + '/' + data.member._id + '/'+ data.member.avatar} alt="user photo" />
      <UserName>
        {data.member.first_name + '  ' + data.member.last_name}
      </UserName>
      {data.unreadMessage > 0 &&
        <UnreadMessage>{data.unreadMessage}</UnreadMessage>
      }
    </Row>
  )
}
