import styled from "styled-components";
import { Row, Select, Modal } from 'antd';

export const RowAnt = styled(Row)`
`;

export const SelectAnt = styled(Select)`
    outline: none;

    .ant-select-selection,
    .ant-select-selection:focus {
        border: none;
        box-shadow: none;
    }

    .ant-select-selection__rendered {
        padding: 0 20px;
    }
`;

export const ModalText = styled.p`
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    padding: 20px;
    width: 100%;
    text-align: center;
    font-family: 'OdinRounded', sans-serif;
`;

export const ModalAnt = styled(Modal)`
    .ant-modal-footer {
        .ant-btn:first-child {
            display: none;
        }
    }
`;
