import { all, takeEvery, put } from 'redux-saga/effects'
import proficiencyService from '../../services/proficiency.service'
import notify from "../../utilities/Notify";

function* getAll(action) {
  try {
    const proficiencies = yield proficiencyService.getProficiencies();
    yield put({
      type: 'SET_PROFICIENCIES',
      payload: proficiencies
    })
  } catch(e) {
    notify.error(e)
  }
}

function* fetchDataWatcher() {
  yield takeEvery('GET_PROFICIENCIES', getAll);
}

export function* proficienciesSaga() {
  yield all([fetchDataWatcher()])
}
