import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { ItemUser } from "../../HomeComponents";
import { Container } from "../../../atoms";
import { BlockTitle } from "./style";
import { REGISTER_ROUTE } from "../../../../constants/routes";
import { ButtonLink } from "../../../atoms";

export function Rockstar({rockstars}) {
    const { t } = useTranslation();

    return (
        <Row type="flex" className='pl-userlist pl-text-center' justify="center">
            <Container>
                <BlockTitle>{t('rockstar_title')}</BlockTitle>
                <Row type="flex" justify="space-around">
                    {
                        rockstars && rockstars.map((item, index) => {
                            return (
                              <Col xs={7} sm={5} md={6} key={index}>
                                  <ItemUser rockstar={item}/>
                              </Col>
                            )
                        })
                    }
                </Row>
                <ButtonLink label={t("register_button")} link={REGISTER_ROUTE}/>
            </Container>
        </Row>
    );
}
