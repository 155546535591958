import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import {
    BenefitsTextBlock,
    Container
} from '../../components';
import { RowAnt, PageTitle, BenefitsListBlock, BenefitBlockBottom, BenefitsBlockWrapper } from "./style";
import { ButtonLink } from "../../components/atoms";
import { REGISTER_ROUTE } from "../../constants/routes";


export function Benefits() {
    const { t } = useTranslation();

    return (
        <RowAnt type="flex">
            <Container>
                <Row type="flex">
                    <PageTitle>{t('benefits_title')}</PageTitle>
                </Row>
                <Row type="flex">
                    <BenefitsBlockWrapper xs={24} md={12}>
                        <BenefitsTextBlock 
                            title={t('benefits_block_title1')}
                            text={t('benefits_block_text1')}
                        />
                        <BenefitsListBlock>
                            <li>{t('benefits_block_point1')}</li>
                            <li>{t('benefits_block_point2')}</li>
                            <li>{t('benefits_block_point3')}</li>
                            <li>{t('benefits_block_point4')}</li>
                            <li>{t('benefits_block_point5')}</li>
                            <li>{t('benefits_block_point6')}</li>
                        </BenefitsListBlock>
                        <BenefitBlockBottom>
                            <ButtonLink label={t("benefits_register_teacher")} link={REGISTER_ROUTE}/>
                        </BenefitBlockBottom>
                    </BenefitsBlockWrapper>
                    <Col xs={24} md={12}>
                        <BenefitsTextBlock 
                            title={t('benefits_block_title2')}
                            text={t('benefits_block_text2')}
                        />
                        <BenefitsListBlock>
                            <li>{t('benefits2_block_point1')}</li>
                            <li>{t('benefits2_block_point2')}</li>
                            <li>{t('benefits2_block_point3')}</li>
                            <li>{t('benefits2_block_point4')}</li>
                            <li>{t('benefits2_block_point5')}</li>
                        </BenefitsListBlock>
                        <BenefitBlockBottom>
                            <ButtonLink label={t("benefits_register_student")} link={REGISTER_ROUTE}/>
                        </BenefitBlockBottom>
                    </Col>
                </Row>
            </Container>
        </RowAnt>
    );
}
