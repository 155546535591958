import axios from '../utilities/Axios';

export default {
  getInstruments: async () => (await axios.get('/instruments')).data.data,
  searchInstruments: async (instrument) => (
    await axios.get('/instruments/list',
      {
        params: {
          name: instrument
        }
      }
    )
  ).data.data,
}

