import TeacherBackground from "./images/teacher-background.jpg";
import LocationIcon from "./images/location-icon.png";
import CameraIcon from "./images/camera-icon.png";
import TeacherPhoto from "./images/teacher-photo.jpg";
import Unsplash3 from "./images/unsplash/unsplash3.jpg";
import Unsplash2 from "./images/unsplash/unsplash2.jpg";
import Unsplash from "./images/unsplash/unsplash.jpg";
import Icon1 from "./images/howit/howit1.png";
import Icon2 from "./images/howit/howit2.png";
import Icon3 from "./images/howit/howit3.png";
import Lamp from "./images/lamp-icon.png";
import ConcertImg from "./images/concert-img.png";
import HeartImage from "./images/heart-icon.png";
import EduLogo from "./images/edu-logo.png";
import userPhoto from "./images/user-photo.png";
import MenuIcon from "./images/menu-icon.png";
import UserIcon from "./images/user-icon.png";
import HeartImageBlack from "./images/heart-icon-black.png";
import TagIconBlack from "./images/tag-icon-black.png";
import EmailIconBlack from "./images/email-icon-black.png";
import SearchIcon from "./images/search-icon.png";
import TopMenuBackground from "./images/menu-background.png";
import CloseIcon from "./images/close-icon.png";
import GoToIcon from "./images/goto-icon.png";
import ClockIcon from "./images/clock-icon.png";
import CheckIcon from "./images/check-icon.png";
import FolderSecondIcon from "./images/folder2-icon.png";
import QuestionIcon from "./images/question-icon.png";
import FolderIcon from "./images/folder-icon.png";
import NotesIcon from "./images/notes-icon.png";
import MedalIcon from "./images/medal-icon.png";
import Logo321 from "./images/logo.png";
import LogoWhite from "./images/logo_white.png";
import ListItemIcon from "./images/list-item-icon.png";

import AndoraFlag from "./images/flags/andora-flag.png";
import ArgentinaFlag from "./images/flags/argentina-flag.png";
import ArmeniaFlag from "./images/flags/armenia-flag.png";
import AustriaFlag from "./images/flags/austria-flag.png";
import BelarusFlag from "./images/flags/belarus-flag.png";
import BelgiumFlag from "./images/flags/belgium-flag.png";
import BrasilFlag from "./images/flags/brasil-flag.png";
import BulgariaFlag from "./images/flags/bulgaria-flag.png";
import CanadaFlag from "./images/flags/canada-flag.png";
import CroatiaFlag from "./images/flags/croatia-flag.png";
import CyprusFlag from "./images/flags/cyprus-flag.png";
import CzechFlag from "./images/flags/czech-flag.png";
import DenmarkFlag from "./images/flags/denmark-flag.png";
import EnglishFlag from "./images/flags/english-flag.png";
import EstoniaFlag from "./images/flags/estonia-flag.png";
import FinlandFlag from "./images/flags/finland-flag.png";
import FranceFlag from "./images/flags/france-flag.png";
import GeorgiaFlag from "./images/flags/georgia-flag.png";
import GermanyFlag from "./images/flags/germany-flag.png";
import GreeceFlag from "./images/flags/greece-flag.png";
import HungaryFlag from "./images/flags/hungary-flag.png";
import IcelandFlag from "./images/flags/iceland-flag.png";
import IndiaFlag from "./images/flags/india-flag.png";
import IndonesiaFlag from "./images/flags/indonesia-flag.png";
import IrelandFlag from "./images/flags/ireland-flag.png";
import IsraelFlag from "./images/flags/israel-flag.png";
import ItalyFlag from "./images/flags/italy-flag.png";
import JapanFlag from "./images/flags/japan-flag.png";
import LatviaFlag from "./images/flags/latvia-flag.png";
import LiechtensteinFlag from "./images/flags/liechtenstein-flag.png";
import LithuaniaFlag from "./images/flags/lithuania-flag.png";
import LuxembourgFlag from "./images/flags/luxembourg-flag.png";
import MaltaFlag from "./images/flags/malta-flag.png";
import MoldovaFlag from "./images/flags/moldova-flag.png";
import MonacoFlag from "./images/flags/monaco-flag.png";
import MontenegroFlag from "./images/flags/montenegro-flag.png";
import NetherlandsFlag from "./images/flags/netherlands-flag.png";
import NewzealandFlag from "./images/flags/newzealand-flag.png";
import NorwayFlag from "./images/flags/norway-flag.png";
import PortugalFlag from "./images/flags/portugal-flag.png";
import RomaniaFlag from "./images/flags/romania-flag.png";
import SanmarinoFlag from "./images/flags/sanmarino-flag.png";
import SlovakiaFlag from "./images/flags/slovakia-flag.png";
import SloveniaFlag from "./images/flags/slovenia-flag.png";
import SpainFlag from "./images/flags/spain-flag.png";
import SwedenFlag from "./images/flags/sweden-flag.png";
import SwitzerlandFlag from "./images/flags/switzerland-flag.png";
import TurkeyFlag from "./images/flags/turkey-flag.png";
import UkraineFlag from "./images/flags/ukraine-flag.png";
import UsaFlag from "./images/flags/usa-flag.png";
import VaticanFlag from "./images/flags/vatican-flag.png";

import London from "./images/city/london.jpeg";
import Berlin from "./images/city/berlin.jpeg";
import Paris from "./images/city/paris.jpg";
import Kyiv from "./images/city/kyiv.jpg";

export const Images = {
    Kyiv,
    Paris,
    Berlin,
    London,
    Logo321,
    LogoWhite,
    MedalIcon,
    NotesIcon,
    FolderIcon,
    QuestionIcon,
    FolderSecondIcon,
    CheckIcon,
    TeacherBackground,
    LocationIcon,
    CameraIcon,
    TeacherPhoto,
    Unsplash3,
    Unsplash2,
    Unsplash,
    Icon1,
    Icon2,
    Icon3,
    Lamp,
    ConcertImg,
    HeartImage,
    EduLogo,
    userPhoto,
    MenuIcon,
    UserIcon,
    SearchIcon,
    HeartImageBlack,
    TagIconBlack,
    EmailIconBlack,
    TopMenuBackground,
    CloseIcon,
    GoToIcon,
    ClockIcon,
    ListItemIcon,
    AndoraFlag,
    ArgentinaFlag,
    ArmeniaFlag,
    AustriaFlag,
    BelarusFlag,
    BelgiumFlag,
    BrasilFlag,
    BulgariaFlag,
    CanadaFlag,
    CroatiaFlag,
    CyprusFlag,
    CzechFlag,
    DenmarkFlag,
    EnglishFlag,
    EstoniaFlag,
    FinlandFlag,
    FranceFlag,
    GeorgiaFlag,
    GermanyFlag,
    GreeceFlag,
    HungaryFlag,
    IcelandFlag,
    IndiaFlag,
    IndonesiaFlag,
    IrelandFlag,
    IsraelFlag,
    ItalyFlag,
    JapanFlag,
    LatviaFlag,
    LiechtensteinFlag,
    LithuaniaFlag,
    LuxembourgFlag,
    MaltaFlag,
    MoldovaFlag,
    MonacoFlag,
    MontenegroFlag,
    NetherlandsFlag,
    NewzealandFlag,
    NorwayFlag,
    PortugalFlag,
    RomaniaFlag,
    SanmarinoFlag,
    SlovakiaFlag,
    SloveniaFlag,
    SpainFlag,
    SwedenFlag,
    SwitzerlandFlag,
    TurkeyFlag,
    UkraineFlag,
    UsaFlag,
    VaticanFlag,
};
