import styled from 'styled-components';

export const User = styled.div`
    display: flex;
    margin: 0 10px 15px;
    padding: 0 10px 10px;
    justify-content: space-around;
    flex-direction: row;
    min-height: 54px;

    &:not(:last-child) {
        border-bottom: 1px solid #ccc;
    }

    .ant-comment-content-detail {
        display: flex;
        width: 90%;
        font-size: 14px;
        font-family: 'PoppinsRegular', sans-serif;
        color: #333;
        margin: 0;
        line-height: 1.4;
        justify-content: flex-start;
        align-items: center; 
    }

    .ant-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5%;
        min-width: 54px;
        height: 54px;
        font-size: 28px;
        font-family: 'PoppinsBold', sans-serif;
    }
`;

