const initialState = {
  page: 1,
  users: [],
  total: 1,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USERS':
      return {...state, ...action.payload};
    case 'SET_USERS_FILTERS':
      return {
        ...state,
        ...action.payload
      };
    case 'USERS_ITEM_BAN':
      return {
        ...state,
        users: state.users.map((item) => {
          if (item._id === action.payload._id) {
            item.ban = !item.ban
          }
          return item
        })
      };
    case 'DELETE_USERS_ITEM':
      return {
        ...state,
        users: state.users.filter((item) => item._id !== action.payload)
      };
    default:
      return state;
  }
};

export default usersReducer;

