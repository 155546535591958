import React from 'react';
import { Teacher, Usertitle } from "./style";

export function ItemUser({rockstar}) {
    return (
        <Teacher to={`/user/${rockstar._id}`}>
            <div className="user-photo">
                <img src={process.env.REACT_APP_PUBLIC_FILE_URL + '/' + rockstar._id + '/' + rockstar.avatar} alt={'user-name'} />
            </div>
            <Usertitle>{rockstar.first_name} {rockstar.last_name[0]}</Usertitle>
        </Teacher>
    );
}
