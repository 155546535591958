import React, {useEffect, useRef, useState} from "react";
import { Message } from "../";
import { useTranslation } from 'react-i18next';
import { Input } from "antd";
import { Button } from "../../../atoms";
import { MessageAreaRow, MessageInput, Messages } from "./style";

export const MessageArea = (props) => {
  const { t } = useTranslation();
  const {messages, sendMessage, handleChangeMessage, message, readMessage, user, member} = props;
  const { TextArea } = Input;
  const [ref] = useState(useRef(null));

  useEffect(() => {
    ref.current.scrollIntoView({behavior: 'smooth', block: 'start'})
  }, [props, ref]);

  return (
    <MessageAreaRow>
      <div className="chatHeader"
           style={{
             display: 'flex',
             flexDirection: 'row',
             height: 65,
             zIndex: 2,
             borderBottom: '1px solid rgb(245, 245, 245)'
           }}
      >
        <div className="name"
             style={{
               fontSize: 22,
               lineHeight: '50px',
               marginTop: 7,
               marginLeft: 10,
             }}
        >
          {member.first_name} {member.last_name}
        </div>
      </div>
      <Messages>
        {
          messages && messages.map((item, index) => {
            return (
              <Message message={item} user={user._id} key={index}/>
            )
          })
        }
        <div ref={ref} />
      </Messages>
      <MessageInput>
        <TextArea
          placeholder={t('chat_message_placeholder')}
          autosize={{minRows: 3, maxRows: 4}}
          value={message}
          onChange={handleChangeMessage}
          onFocus={readMessage}
        />
        <Button label={t('chat_button_send')} onClick={() => {sendMessage(message)}} />
      </MessageInput>
    </MessageAreaRow>
  )
}
