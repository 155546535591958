//import { Images } from "../../../assets/images";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { device } from "../../../constants/device";
import { colGreen } from '../../../constants/variables';

export const UserMenu = styled(Link)`
  width: 35px;
  height: 35px;
  display: block;
  cursor: pointer;
  background: #c0c0c0;
  border-radius: 50%;
  position: relative;
  margin-left: 20px;
  margin-top: -5px;

  svg {
    width: 50%;
    height: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    left: 0;
  }
`;

export const MenuButton = styled.div`
  cursor: pointer;
  border: none;
  padding: 5px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  margin: 0;
  overflow: visible;

  &:hover {
    opacity: 0.5;
  }
  &.is-active {
    &:hover {
      opacity: 0.5;
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: #000;
    }

    .hamburger-inner {
      transform: translate3d(0, 10px, 0) rotate(45deg);

      &::before {
        transform: rotate(-45deg) translate3d(-5.7px, -6px, 0);
        opacity: 0;
      }

      &::after {
        transform: translate3d(0, -20px, 0) rotate(-90deg);
      }
    }
  }
  &.hamburger--slider {
    .hamburger-inner {
      top: 2px;

      &::before {
        top: 10px;
        transition-property: transform, opacity;
        transition-timing-function: ease;
        transition-duration: 0.15s;
      }

      &::after {
        top: 20px;
      }
    }
  }
  
  .hamburger-box {
    width: 30px;
    height: 24px;
    display: inline-block;
    position: relative;
  }
  
  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  
    &,
    &::before,
    &::after {
      width: 30px;
      height: 4px;
      background-color: #000;
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }
  
    &::before,
    &::after {
      content: "";
      display: block;
    }
  
    &::before {
      top: -10px;
    }
  
    &::after {
      bottom: -10px;
    }
  }
`;

export const Logo = styled(Link)`
  img {
    width: 150px;
    height: auto;
    margin-right: 30px;

    @media ${device.mobileL} {
      width: 180px;
      margin-right: 0;
    }

    @media ${device.laptop} {
      width: 200px;
    }
  }
`;

export const SearchLogo = styled.span`
  background: ${colGreen};
  height: 30px;
  width: 30px;
  border-radius: 50%;
  left: 25px;
  position: relative;
  cursor: pointer;

  @media ${device.mobileL} {
    height: 40px;
    width: 40px;
  }

  .anticon svg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    font-size: 22px;
    font-weight: bold;
    line-height: 2;
    color: #fff;
  }
`;

export const Wrapper = styled.div`
  background: #fff;
  min-height: 142px;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0 10px;
  z-index: 9;

  .show-search {
    //height: 100%;
    overflow: hidden;
    max-height: 420px;
    
    // -webkit-animation: slide-down .4s ease-out;
    // -moz-animation: slide-down .4s ease-out;

    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    & > .hide{
      height: 45vh !important;
      position: absolute;
      width: 100vw;
      top: 493px;
      z-index: 999;
      left: 0px;
    }
  }

  .hide-search {
    //height: 0;
    overflow: hidden;
    max-height: 0;
    
    // -webkit-animation: slide-up .4s ease-in;
    // -moz-animation: slide-up .4s ease-in;
  }

  @media ${device.laptop} {
    padding: 0;
  }

  @keyframes slide-down {
    0% {
      height: 0;
      transform: translateY(-100%);
    } 
    100% {
      height: 100%;
      transform: translateY(0);
    } 
  }

  @keyframes slide-up {
    0% {
      height: 100%;
      transform: translateY(0);
    } 
    100% {
      height: 0;
      transform: translateY(-100%);
    } 
  }
`;

export const HotLink = styled.div`
  justify-content: flex-end;
  display: flex;
`;

export const LinkMenu = styled(Link)`
  font-size: 16px;
  color: #7c7c7c;

  &:not(:last-child) {
    margin-right: 10px;
    padding-top: 5px;
  }
`;