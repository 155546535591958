import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  RetingItem,
  RowAnt,
  SliderWrapper,
  Feedback,
  BlockHeader,
  BlockText,
  FeedbackUserInfo,
  FeedbackUser,
  RatingList
} from "./style";

function retingList() {
  let row = [];

  for (let i = 0; i < 5; i++) {
      row.push(<RetingItem type="star" theme="filled" key={i} />);
  }
  return row;
}

export function DashboardFeedback(props) {
    const { t } = useTranslation();

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <RowAnt type="flex" className="pl-mr-1">
            <SliderWrapper {...settings}>
                <Feedback>
                    <BlockHeader type="flex" justify="space-between">
                        <p>{t('your_feedback')}</p>
                    </BlockHeader>
                    <BlockText>{t('your_feedback_text1')}</BlockText>
                    <FeedbackUserInfo>
                        <FeedbackUser>
                            <p>{t('testimonial_user_info3')}</p>
                            <RatingList>{retingList()}</RatingList>
                        </FeedbackUser>
                    </FeedbackUserInfo>
                </Feedback>
                <Feedback>
                    <BlockHeader type="flex" justify="space-between">
                        <p>{t('your_feedback')}</p>
                    </BlockHeader>
                    <BlockText>{t('your_feedback_text2')}</BlockText>
                    <FeedbackUserInfo>
                        <FeedbackUser>
                            <p>{t('testimonial_user_info3')}</p>
                            <RatingList>{retingList()}</RatingList>
                        </FeedbackUser>
                    </FeedbackUserInfo>
                </Feedback>
            </SliderWrapper>
        </RowAnt>
    );
}
