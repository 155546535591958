import styled from 'styled-components';
import { Form, Checkbox, Row } from 'antd';
import { Images } from '../../../assets/images';
import { Link } from 'react-router-dom';
import { colGreen, colLightBlueText } from '../../../constants/variables';
import { GoogleLogin } from 'react-google-login';

export const FormInput = styled(Form.Item)`
  font-family: 'PoppinsBold', sans-serif;
  font-size: 14px;
  text-align: center;
  width: 250px;

  .ant-input-affix-wrapper {
    overflow: hidden;
    border-radius: 30px;
  }

  .ant-input {
    background: none;
    border-radius: 30px;
    height: 40px;
    padding-left: 30px !important;
  }
  .ant-form-item{
    marging-bottom: 20px !important;
  }
`;

export const LogoItem = styled.span`
  background: url(${Images.Logo321}) no-repeat;
  display: block;
  height: 80px;
  width: 210px;
  background-size: contain;
  margin: 0 auto 30px;
`;

export const CheckboxRemember = styled(Checkbox)`
  font-family: 'OdinRounded', sans-serif;
  font-size: 12px;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent;
    border-color: ${colGreen};

    &:after {
      border-color: #5e5e5d;
    }
  }
`;

export const RegistrationLink = styled(Row)`
  font-size: 15px;
  margin-bottom: 20px;

  a {
    color: ${colGreen};
    padding: 0px 22px;
    border-radius: 50px;
    border: 1px solid ${colGreen};
    font-size: 12px;
  }
`;

export const LoginForm = styled(Form)`

  .login-form-button {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
`;

export const ForgotLink = styled.p`
  border: none;
  margin-top: 10px;
  cursor: pointer;
  background-color: #fff;
  font-size: 14px;
  color: #72726f;
  font-weight: 600;
`;

export const AdditionalRow = styled(Row)``;

export const RegHeader = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 8px;
`;

export const Terms = styled(Link)`
  color: ${colLightBlueText};
  font-family: 'OdinRounded', sans-serif;
  font-size: 12px;
  display: block;
`;

export const SocialLink = styled.div`
  display: block;
  display: block;
  width: 100%;
  margin: auto;
  text-align: center;

  a {
    color: #72726f;

    &:not(:last-child) {
      margin: 0 10px 0 0;
    }
  }
`;

export const EnterViaSocial = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Gbutton = styled(GoogleLogin)`
  display: flex;
  background-color: white;
  align-items: center;
  border-radius: 20px;  
  font-size: 11px !important;
  line-height: normal !important;

  & > * {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  div {
    padding: 5px !important;
  }

  span {
    padding: 5px 10px 5px 0 !important;
  }
`;

export const FBbutton = styled.span`
  cursor: pointer;
  display: flex;
  align-item: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px 4px;
  font-size: 11px !important;
  background-color: #397ed1;
  color: #fff;
  border-radius: 3px;
  border: none;
  margin-left: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  height: 30px;
  
  i.anticon {
    line-height:18px;
  }

  span {
    line-height: 1.7;
  }
`;

export const AntRow = styled(Row)``;
