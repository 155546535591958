import React from 'react';
import { HeaderLite } from '../../components/molecules';
import { withRouter } from "react-router-dom";

export const SimpleLayout = withRouter((props) => {
   return (
      <>
         <HeaderLite />
         {props.children}
      </>
   )
});
