import {useDispatch, useMappedState} from "redux-react-hook";
import {TeacherFirstUpdatePage} from "./TeacherFirstUpdatePage";
import React, {useCallback, useEffect} from "react";
import {StudentFirstUpdatePage} from "./StudentFirstUpdatePage";

const FirstUpdatePage = () => {
  const user = useMappedState(state => state.auth).user;
  const dispatch = useDispatch();

  const onSaveChanges = useCallback((data, e) => {
    e.preventDefault();
    dispatch({
      type: 'FIRST_UPDATE_USER',
      payload: {
        data: data
      }
    })
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: 'GET_INSTRUMENTS'
    });
    dispatch({
      type: 'GET_LANGUAGES'
    });
    dispatch({
      type: 'GET_PROFICIENCIES'
    });
    dispatch({
      type: 'GET_LOCATIONS'
    });
    dispatch({
      type: 'GET_CLASS_TYPES'
    })
  }, [dispatch]);

  return (
    user && user.role_id.role === 'teacher' ?
      <TeacherFirstUpdatePage
        data={user}
        onSaveChanges={onSaveChanges}
      /> :
      <StudentFirstUpdatePage
        data={user}
        onSaveChanges={onSaveChanges}
      />
  )
};

export default FirstUpdatePage;