import React, {useCallback, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {Input, Col, Row, Select} from "antd";
import {
  Container,
  Button as ButtonCore
} from "../../components";
import {
  UserPhoto,
  SideContent,
  CentralContent,
  TeacherSmallInfo,
  TeacherName,
  EditItems,
  ItemTitle,
  TeacherRow,
} from "./style";
import { UploadAvatar } from "../../components/molecules/EditProfilePageComponents";
import {useMappedState} from "redux-react-hook";

const {Option} = Select;

const EditStudentPage = ({onSaveChanges}) => {
  const { t } = useTranslation();
  const { TextArea } = Input;

  const {user, proficiencies, languages} = useMappedState(state => state);

  const [userData, setUserData] = useState({});

  useEffect(() => {
    setUserData({
      _id: user._id,
      first_name: user.first_name,
      last_name: user.last_name,
      metadata: {
        languages: user.languages.map((item) => item._id),
        proficiency: user.proficiency,
        about: user.about,
        motivation: user.motivation,
        future_goals: user.future_goals,
        hobbies: user.hobbies,
        location: user.location
      }
    })
  }, [user]);

  const onChange = useCallback((field, value, meta=false) => {
    if (meta) {
      setUserData(userData => ({...userData, metadata: {...userData.metadata, [field]: value }}) )
    } else {
      setUserData({...userData, [field]: value})
    }
  },[userData]);

  return (
    <TeacherRow type="flex" justify="center" className="pl-pt-6">
      <Container>
        <UserPhoto>
          <div className="user-photo">
            <img src={process.env.REACT_APP_PUBLIC_FILE_URL + '/' + user._id + '/' + user.avatar} alt={user.first_name + 'avatar'}/>
          </div>
        </UserPhoto>
        <SideContent />
        <CentralContent>
          <TeacherSmallInfo type="flex">
            <TeacherName>{userData.first_name + ' ' + userData.last_name}</TeacherName>
          </TeacherSmallInfo>
          {userData && Object.keys(userData).length > 0 &&
          <form>
            <EditItems>
              <ItemTitle>{t("edit_text1")}</ItemTitle>
              <UploadAvatar/>
            </EditItems>
            <EditItems>
              <ItemTitle>{t("edit_text2")}</ItemTitle>
              <Row gutter={8}>
                <Col span={12}>
                  <Input
                    placeholder="You Name"
                    onChange={(value) => onChange('first_name', value.target.value)}
                    value={userData.first_name}
                  />
                </Col>
                <Col span={12}>
                  <Input
                    placeholder="You Surname"
                    value={userData.last_name}
                    onChange={(value) => onChange('last_name', value.target.value)}
                  />
                </Col>
              </Row>
            </EditItems>
            <EditItems>
              <ItemTitle>{t("edit_text9")}</ItemTitle>
              <TextArea
                placeholder={t("edit_text9")}
                rows={4}
                value={userData.metadata.about}
                onChange={(value) => onChange('about', value.target.value, true)}
              />
            </EditItems>
            <EditItems >
              <ItemTitle>{t("edit_text14")}</ItemTitle>
              <TextArea
                placeholder={t("edit_text14")}
                rows={4}
                value={userData.metadata.future_goals}
                onChange={(value) => onChange('future_goals', value.target.value, true)}
              />
            </EditItems>
            <EditItems >
              <ItemTitle>{t("edit_text15")}</ItemTitle>
              <TextArea
                placeholder={t("edit_text15")}
                rows={4}
                value={userData.metadata.hobbies}
                onChange={(value) => onChange('hobbies', value.target.value, true)}
              />
            </EditItems>
            <EditItems >
              <ItemTitle>{t("edit_text16")}</ItemTitle>
              <TextArea
                placeholder={t("edit_text16")}
                rows={4}
                value={userData.metadata.motivation}
                onChange={(value) => onChange('motivation', value.target.value, true)}
              />
            </EditItems>
            <EditItems>
              <ItemTitle>{t("edit_text5")}</ItemTitle>
              <Col span={24}>
                <Select
                  mode="multiple"
                  placeholder={'Choose languages'}
                  value={userData.metadata.languages}
                  onChange={(value) => onChange('languages', value, true)}
                >
                  {
                    languages && languages.length > 0 && languages.map((item) => {
                      return (
                        <Option
                          value={item._id}
                          key={item._id}
                        >{item.language}</Option>
                      )
                    })
                  }
                </Select>
              </Col>
            </EditItems>
            <EditItems>
              <ItemTitle>{t("edit_text17")}</ItemTitle>
              <Col span={24}>
                <Select
                  placeholder={'Choose your skill'}
                  value={userData.metadata.proficiency}
                  style={{
                    width: '100%'
                  }}
                  onChange={(value) => onChange('proficiency', value, true)}
                >
                  {
                    proficiencies && proficiencies.length > 0 && proficiencies.map((item) => {
                      return (
                        <Option
                          value={item._id}
                          key={item._id}
                        >
                          {item.level}
                        </Option>
                      )
                    })
                  }
                </Select>
              </Col>
            </EditItems>
            <ButtonCore
              label={t("save_button")}
              onClick={(e) => {onSaveChanges(userData, e)}}
            />
          </form>
          }
        </CentralContent>
      </Container>
    </TeacherRow>
  );
};

export default EditStudentPage;
