import { Row, Table } from 'antd';
// import { device } from "../../constants/device";
import styled from "styled-components";
import {Link} from "react-router-dom";

export const PageTitle = styled(Row)`
    width: 100%
    margin: 0;
    padding: 10px 20px;
`;

export const TableAnt = styled(Table)`
    width: 100%;

    .pl-user-name {
        cursor: pointer;
        color: blue;

        &.pl-banned {
            color: grey;
        }
    }

    .pl-action-button {
        span {
            margin: 0 0 0 10px;
        }
    }
`;

export const Action = styled.span`
    font-size: 20px;
    color: blue;
    cursor: pointer;

    &.pl-banned {
        color: red;
    }

    &:hover {
        color: grey;
    }
`;

export const LinkAddUser = styled(Link)`
`;

export const ActionLink = styled(Link)`
    font-size: 20px;
    color: blue;
    cursor: pointer;

    &:hover {
        color: grey;
    }
`;