import styled from 'styled-components';
import { Row } from 'antd';
import { colGreen } from 'constants/variables';
import { device } from '../../../../constants/device';

export const SelectWrapper = styled(Row)`
    position: relative;
    top: 35%;
    height: 48px;
    z-index: 3;
    margin: 0 25px;
    width: 80%;

    @media ${device.laptop} {
        width: 70%;
        margin: 0 35px;
    }

    .ant-select {
        width: 100%;
    }

    .ant-select-selection--single {
        height: 100%;
        border-color: ${colGreen};
        border-radius: 25px;
        overflow: hidden;

        .ant-select-arrow {
            background: ${colGreen};
            height: 100%;
            width: 50px;
            right: 0;
            top: 6px;

            @media ${device.laptop} {
                width: 80px;
            }


            .ant-select-arrow-icon {
                position: relative;
                top: 40%;
                color: #fff;
            }
        }
    }
    
    .ant-select-selection__rendered {
        height: 100%;
        line-height: 45px;
        margin: 0;

        .ant-select-selection-selected-value {
            padding: 0 20px;
            font-size: 14px;

            @media ${device.laptop} {
                font-size: 16px;
            }
        }

        .ant-select-selection__placeholder {
            padding: 0 20px;
            font-size: 14px;

            @media ${device.laptop} {
                font-size: 16px;
            }
        }
    }
`;