const initialState = [

];

const lessonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LESSONS':
      return action.payload;
    default:
      return state;
  }
};

export default lessonsReducer;
