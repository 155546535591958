import { Row } from 'antd';
import { device } from "../../constants/device";
import styled from "styled-components";

export const RowAnt = styled(Row)`
    background: #fff;
    padding: 0 15px 50px;
    position: relative;

    h3 {
        margin-top: 30px;
        width: 100%;
    }
`;

export const PageTitle = styled.h2`
    text-align: center;
    color: #222;
    font-size: 36px;
    font-family: 'OdinRounded', sans-serif;
    width: 100%;
    line-height: 1;
    margin-top: 60px;
    margin-bottom: 250px;

    @media ${device.tablet} {
        font-size: 46px;
        margin-top: 100px;
        margin-bottom: 40px;
    }
`;

export const CustomList = styled.ul`
    margin: 0;
    padding: 0 0 0 20px;
    list-style: none;
    width: 100%;

    h4 {
        margin-bottom: 5px;
        line-height: 1.2;
        width: 100%;
    }

    li:not(:last-child) {
        margin-bottom: 20px;
        width: 100%;
    }
`