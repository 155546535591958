import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from "../../../../components/atoms";
import { Images } from "../../../../assets/images";

import { 
    SliderWrapper,
    EduIcon,
    SliderContainer,
    SlickSlider,
    Testimonial,
    TestimonialText,
    TestimonialUserInfo,
    PageTitle
} from "./style";

import { REGISTER_ROUTE } from "../../../../constants/routes";
import { ReactComponent as EduSvg } from "../../../../assets/svg/eduicon.svg";
import { ButtonLink } from "../../../atoms";

export function TestimonialsSlider() {
    const { t } = useTranslation();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <SliderWrapper type="flex" justify="center">
            <EduIcon>
                <EduSvg/>
            </EduIcon>
            <SliderContainer>
                <PageTitle>{t('testimonial_title')}</PageTitle>
                <SlickSlider {...settings}>
                    <Testimonial type="flex" justify="center">
                        <Container>
                            <TestimonialText>{t('testimonial_text1')}</TestimonialText>
                            <TestimonialUserInfo><img src={Images.userPhoto} alt={'user-name'}/>{t('testimonial_user_info1')}</TestimonialUserInfo>
                            <ButtonLink link={REGISTER_ROUTE} label={t("login_form_registration")}/>
                        </Container>
                    </Testimonial>
                    <Testimonial type="flex" justify="center">
                        <Container>
                            <TestimonialText>{t('testimonial_text2')}</TestimonialText>
                            <TestimonialUserInfo><img src={Images.userPhoto} alt={'user-name'}/>{t('testimonial_user_info2')}</TestimonialUserInfo>
                            <ButtonLink link={REGISTER_ROUTE} label={t("login_form_registration")}/>
                        </Container>
                    </Testimonial>
                    <Testimonial type="flex" justify="center">
                        <Container>
                            <TestimonialText>{t('testimonial_text3')}</TestimonialText>
                            <TestimonialUserInfo><img src={Images.userPhoto} alt={'user-name'}/>{t('testimonial_user_info3')}</TestimonialUserInfo>
                            <ButtonLink link={REGISTER_ROUTE} label={t("login_form_registration")}/>
                        </Container>
                    </Testimonial>
                </SlickSlider>
            </SliderContainer>
        </SliderWrapper>
    );
}

