import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  ModalAnt,
  ModalContainer,
  ModalSchedule,
  RedButton
} from "./style";
import { useDispatch } from "redux-react-hook";
import { Table, Tooltip, Icon, Popconfirm } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

export function StudentEventModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const cancelLessonItem = useCallback((_id) => {
    dispatch({
      type: 'CANCEL_LESSON_ITEM',
      payload: _id
    })
  }, [dispatch]);

  const columns = [
    {
      title: t('request_page_start_time'),
      dataIndex: 'start_time',
      key: 'start_time',
      render: item => (
        <>
          {moment(item).format('LT')}
        </>
      )
    },
    {
      title: t('request_page_end_time'),
      dataIndex: 'start_time',
      key: 'end_time',
      render: item => (
        <>
          {moment(item).add(50, 'm').format('LT')}
        </>
      )
    },
    {
      title: t('request_page_instrument'),
      dataIndex: 'instrument',
      key: 'instrument',
      render: item => (
        <>
          {item.name}
        </>
      )
    },
    {
      title: t('request_page_class_type'),
      dataIndex: 'class_type',
      key: 'class_type',
      render: item => (
        <>
          {item.type}
        </>
      )
    },
    {
      title: t('request_page_teacher'),
      dataIndex: 'teacher',
      key: 'teacher',
      render: item => (
        <>
          <Link
            to={`/user/${item._id}`}
            style={{
              color: '#383838'
            }}
          >
            <img
              src={process.env.REACT_APP_PUBLIC_FILE_URL + '/' + item._id + '/' + item.avatar}
              alt={item.first_name}
              style={{
                height: 40,
                width: 40,
                borderRadius: '50%',
                marginRight: 10
              }}

            />{item.first_name}
          </Link>
        </>
      )
    },
    {
      title: t('request_page_action'),
      key: 'action',
      render: (item) => {
        return (
          <>
            {
              moment(props.date) >= moment().hours(0).minutes(0) &&
              <Popconfirm
                placement="top"
                title={t('Are you sure to decline book?')}
                onConfirm={() => cancelLessonItem(item._id)}
                okText={t('decline_yes')} cancelText={t('decline_no')}
              >
                <Tooltip title={t('request_cancel')}>
                  <RedButton>
                    <Icon type="close"/>
                  </RedButton>
                </Tooltip>
              </Popconfirm>
            }
          </>

        )},
    },
  ];

  return (
    <ModalAnt
      title={props.date.format('LL').toString()}
      visible={props.isShowModal}
      onOk={() => props.hideModal(!props.isShowModal)}
      onCancel={() => props.hideModal(!props.isShowModal)}
      okText={t("customize_lesson_modal_ok")}
      cancelText={t("customize_lesson_modal_cancel")}
    >
      <ModalContainer>
        <ModalSchedule>
          <Table columns={columns} dataSource={props.events} />
        </ModalSchedule>
      </ModalContainer>
    </ModalAnt>
  );
}
