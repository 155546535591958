import React, {useCallback, useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import {
    BlockText,
    FirstBlock,
    StudentPhoto,
    SecondBlock,
    StudentName,
    OtherInfo,
    ThirdBlock,
    EditCheckbox,
    CancelCheckbox
} from "./style";
import { Icon, Tooltip, Modal } from "antd";
import {
    STUDENT_ROUTE
} from "../../../../constants/routes";
import { EditRequestModal } from "../";
import {useDispatch, useMappedState} from "redux-react-hook";
import moment from "moment";


export function StudentRequestBlock({data}) {
    const { t } = useTranslation();
    const [isShowModal, setShowModal] = useState(false);
    const { confirm } = Modal;
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDateEvents, setSelectedDateEvents] = useState([]);

    const schedule = useMappedState(state => state.schedule);
    const disabledDates = useCallback((date) => {
        // eslint-disable-next-line
        const  daySchedule = schedule.filter((item) => {
            if (moment(item.start_time).date() === moment(date).date() && moment(item.start_time).month() === moment(date).month()) {
                return item
            }
        });
        return daySchedule.length === 0;
    }, [schedule]);

    const onSelectDate = useCallback((date) => {
      console.log(date);
      setSelectedDate(date);
        setSelectedDateEvents(
          // eslint-disable-next-line
          schedule.filter((item) => {
            if (moment(item.start_time).startOf('day').format('LLL') === moment(date).startOf("day").format('LLL')) {
                return item
            }
          })
        )
    }, [schedule]);

    useEffect(() => {
      console.log(selectedDateEvents);
    }, [selectedDateEvents]);

    function hideModal() {
        setShowModal(false)
    };

    const onDecline = useCallback((_id) => {
        dispatch({
            type: 'DECLINE_REQUEST',
            payload: _id
        })
    }, [dispatch]);

    const showModal = useCallback(() => {
        dispatch({
            type: 'GET_SCHEDULE',
            payload: {id: data.teacher._id, start_time: moment().startOf('month').toDate(), end_time: moment().endOf('month').toDate()}
        });
        setShowModal(true)
    } ,[data.teacher._id, dispatch]);

    function showConfirm() {
        confirm({
            title: t('request_cancel_text'),
            content: '',
            okText: t('button_yes'),
            okType: 'danger',
            cancelText: t('button_no'),
            onOk() {
                onDecline(data._id)
            },
        });
    };

    return (
        <BlockText type="flex" justify="space-between">
            <FirstBlock>
                <StudentPhoto to={STUDENT_ROUTE}>
                    <img src={process.env.REACT_APP_PUBLIC_FILE_URL + '/' + data.teacher._id + '/' + data.teacher.avatar} alt="StudentPhoto" />
                </StudentPhoto>
            </FirstBlock>
            <SecondBlock type="flex" justify="space-between">
                <StudentName>
                    {data.teacher.first_name + ' ' + data.teacher.last_name}
                </StudentName>
                <OtherInfo type="flex" justify="start">
                    <span><b>{t('request_datetime')}:</b> {data.start_time}</span>
                    <span><b>{t('request_instrument')}:</b> {data.instrument.name}</span>
                    <span><b>{t('request_lessonplace')}:</b> {data.class_type.type}</span>
                </OtherInfo>
            </SecondBlock>
            <ThirdBlock type="flex">
                <Tooltip title={t('request_cancel')}>
                    <CancelCheckbox onClick={() => showConfirm()}><Icon type="close-circle" /></CancelCheckbox>
                </Tooltip>
                <Tooltip title={t('request_edit')}>
                    <EditCheckbox onClick={() => showModal()}><Icon type="edit" /></EditCheckbox>
                </Tooltip>
            </ThirdBlock>
            <EditRequestModal
                isShowModal={isShowModal} 
                hideModal={hideModal}
                onSelectDate={onSelectDate}
                disabledDates={disabledDates}
                selectedDate={selectedDate}
                selectedDateEvents={selectedDateEvents}
                data={data}
            />
        </BlockText>
    );
}
