import React from 'react';
import { Select } from "antd";
import { SelectWrapper } from './style';

export function BookingSelect(props) {
   const { Option } = Select;

  return (
      <SelectWrapper type='flex'>
         <Select 
            placeholder={props.placeholder}
            onSelect={(e) => props.onSelect(e, props.type)}
         >
           { props.options &&
             props.options.map((item) => {
               return (
                 <Option value={item._id} key={item._id}>{item.name || item.type}</Option>
               )
             })
           }
         </Select>
      </SelectWrapper>
   )
}
