import React, {useCallback, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {Input, Col, Row, Tooltip, Select} from "antd";
import {
  CentralContent,
  EditItems,
  ItemTitle,
  TeacherRow,
  IconAnt
} from "./style";
import { Button as ButtonCore } from "../../components";
import { UploadAvatar } from "../../components/molecules/EditProfilePageComponents";
import {useMappedState} from "redux-react-hook";
const {Option} = Select;

export function TeacherFirstUpdatePage({onSaveChanges}) {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const [userMetadata, setUserMetadata] = useState({
    instruments: [],
    languages: [],
    class_types: [],
    background: null,
    proficiency_id: null,
    hour_rate: 0,
    location: null,
    title: null,
    philosophy: null,
    prices: null,
    pupil_proficiencies: [],
    about: null
  });
  const [isFilled, setIsFilled] = useState(true);

  const onChange = useCallback((type, value) => {
    setUserMetadata({...userMetadata, [type]: value})
  }, [userMetadata]);

  useEffect(() => {
    console.log(!userMetadata.about ||
      !userMetadata.title ||
      userMetadata.languages.length === 0 ||
      userMetadata.pupil_proficiencies.length === 0 ||
      userMetadata.class_types.length === 0 ||
      !userMetadata.hour_rate ||
      !userMetadata.background ||
      !userMetadata.location ||
      !userMetadata.philosophy ||
      !userMetadata.prices ||
      !userMetadata.title ||
      !userMetadata.proficiency_id);
    return setIsFilled(
      !userMetadata.about ||
      !userMetadata.title ||
      userMetadata.languages.length === 0 ||
      userMetadata.pupil_proficiencies.length === 0 ||
      userMetadata.class_types.length === 0 ||
      !userMetadata.hour_rate ||
      !userMetadata.background ||
      !userMetadata.location ||
      !userMetadata.philosophy ||
      !userMetadata.prices ||
      !userMetadata.title ||
      !userMetadata.proficiency_id
    );
  }, [userMetadata]);

  useEffect(() => {
    console.log(userMetadata);
  }, [userMetadata])
  console.log(userMetadata);

  const {proficiencies, languages, locations, instruments, class_types} = useMappedState(state => state);

  return (
    <TeacherRow type="flex" justify="center">
        <CentralContent>
            <form action="">
                <EditItems>
                    <ItemTitle>{t("edit_text1")}</ItemTitle>
                    <UploadAvatar/>
                </EditItems>
                <EditItems>
                    <ItemTitle>{t("edit_text7")}<Tooltip title={t("edit_text8")}><IconAnt type="question-circle" /></Tooltip></ItemTitle>
                    <Row gutter={8}>
                        <Col span={5}>
                          <Input
                            placeholder="Lesson Price"
                            type="number" min="0"
                            value={userMetadata.hour_rate}
                            onChange={(value) => {onChange('hour_rate', value.target.value)}}
                          />
                        </Col>
                    </Row>
                </EditItems>
                <EditItems>
                    <ItemTitle>{t("edit_text12")}</ItemTitle>
                    <Row gutter={8}>
                        <Col span={24}>
                            <Select
                              onSelect={(value) => onChange('location', value)}
                              placeholder={'Choose your city'}
                              style={{
                                width: '100%'
                              }}
                            >
                              {
                                locations && locations.length > 0 && locations.map((item) => {
                                  return (
                                    <Option
                                      value={item._id}
                                      key={item._id}
                                    >
                                      {item.location + ' ' + item.country}
                                    </Option>
                                  )
                                })
                              }
                            </Select>
                        </Col>
                    </Row>
                </EditItems>
                <EditItems >
                    <ItemTitle>{t("edit_text9")}</ItemTitle>
                    <TextArea 
                        placeholder={t("edit_text9")}
                        rows={4}
                        value={userMetadata.about}
                        onChange={(value) => onChange('about', value.target.value)}
                    />
                </EditItems>
                <EditItems >
                    <ItemTitle>{t("edit_text10")}</ItemTitle>
                    <TextArea 
                        placeholder={t("edit_text10")}
                        rows={4}
                        value={userMetadata.philosophy}
                        onChange={(value) => onChange('philosophy', value.target.value)}
                    />
                </EditItems>
                <EditItems >
                  <ItemTitle>Teaching background</ItemTitle>
                  <TextArea
                    placeholder="Teaching background"
                    rows={4}
                    value={userMetadata.background}
                    onChange={(value) => onChange('background', value.target.value)}
                  />
                </EditItems>
                <EditItems >
                  <ItemTitle>Title</ItemTitle>
                  <TextArea
                    placeholder="TTitle"
                    rows={4}
                    value={userMetadata.title}
                    onChange={(value) => onChange('title', value.target.value)}
                  />
                </EditItems>
                <EditItems >
                    <ItemTitle>{t("edit_text11")}</ItemTitle>
                    <TextArea 
                        placeholder=""
                        rows={4}
                        value={userMetadata.prices}
                        onChange={(value) => onChange('prices', value.target.value)}
                    />
                </EditItems>
                <EditItems>
                    <ItemTitle>{t("edit_text4")}</ItemTitle>
                    <Col span={24}>
                      <Select
                        mode="multiple"
                        onSelect={(value) => onChange('instruments', value)}
                        placeholder={'Choose instruments'}
                      >
                        {
                          instruments && instruments.length > 0 && instruments.map((item) => {
                            return (
                              <Option
                                value={item._id}
                                key={item._id}
                              >{item.name}</Option>
                            )
                          })
                        }
                      </Select>
                    </Col>
                </EditItems>
                <EditItems>
                    <ItemTitle>{t("edit_text6")}</ItemTitle>
                    <Col span={24}>
                      <Select
                        mode="multiple"
                        onSelect={(value) => onChange('class_types', value)}
                        placeholder={'Choose type of educations'}
                      >
                        {
                          class_types && class_types.length > 0 && class_types.map((item) => {
                            return (
                              <Option
                                value={item._id}
                                key={item._id}
                              >{item.type}</Option>
                            )
                          })
                        }
                      </Select>
                    </Col>
                </EditItems>
                <EditItems>
                    <ItemTitle>{t("edit_text5")}</ItemTitle>
                    <Col span={24}>
                      <Select
                        mode="multiple"
                        onSelect={(value) => onChange('languages', value)}
                        placeholder={'Choose languages'}
                      >
                        {
                          languages && languages.length > 0 && languages.map((item) => {
                            return (
                              <Option
                                value={item._id}
                                key={item._id}
                              >{item.language}</Option>
                            )
                          })
                        }
                      </Select>
                    </Col>
                </EditItems>
                <EditItems>
                    <ItemTitle>{t("edit_text17")}</ItemTitle>
                    <Col span={24}>
                      <Select
                        onSelect={(value) => onChange('proficiency_id', value)}
                        placeholder={'Choose your skill'}
                        style={{
                          width: '100%'
                        }}
                      >
                        {
                          proficiencies && proficiencies.length > 0 && proficiencies.map((item) => {
                            return (
                              <Option
                                value={item._id}
                                key={item._id}
                              >
                                {item.level}
                              </Option>
                            )
                          })
                        }
                      </Select>
                    </Col>
                </EditItems>
                <EditItems>
                    <ItemTitle>Students levels</ItemTitle>
                    <Col span={24}>
                      <Select
                        placeholder={'Choose your skill'}
                        mode="multiple"
                        style={{
                          width: '100%'
                        }}
                        onSelect={(value) => onChange('pupil_proficiencies', value)}
                      >
                        {
                          proficiencies && proficiencies.length > 0 && proficiencies.map((item) => {
                            return (
                              <Option
                                value={item._id}
                                key={item._id}
                              >
                                {item.level}
                              </Option>
                            )
                          })
                        }
                      </Select>
                    </Col>
                </EditItems>
                <Row type="flex" justify="center" align="middle">
                  <ButtonCore disabled={isFilled} onClick={(e) => onSaveChanges(userMetadata, e)} label={t("save_button")}/>
                </Row>
            </form>
        </CentralContent>
    </TeacherRow>
  );
}
