import React, {useCallback} from "react";
import InfiniteScroll from 'react-infinite-scroller';
import {
  Container,
  RadioButton,
  TeacherBlock,
} from "../../components";
import { Images } from "../../assets/images";
import {
  TeacherRow,
  SideContent,
  FilterBlock,
  List,
  CentralContent,
  ShowHideSidebarmobile,
  MenuButton,
  Loader
} from "./style";
import useTeacherList from "../../hooks/TeachersListPage";
import {useTranslation} from "react-i18next";
import {Select, Empty} from "antd";
const { Option } = Select;

// const marks = {
//   0: {
//     style: {
//       color: '#333',
//     },
//     label: <strong>0 km</strong>,
//   },
//   100: {
//     style: {
//       color: '#333',
//     },
//     label: <strong>100 km</strong>,
//   },
// };


export function TeachersList() {
  const { t } = useTranslation();

  const {
    loadFunc,
    handleChangeSort,
    Languages,
    Instruments,
    Proficiencies,
    handleChangeFilters,
    isShowSearchList,
    teachers
  } = useTeacherList();

  const handleChekedAllFilters = useCallback((type) => {
    if (type === 'instruments') {
      if (!teachers.filters.array.one_of['instruments.name']) {
        return true
      } else {
        return false
      }
    } else if (type === 'languages') {
      if (!teachers.filters.array.one_of['languages.language']) {
        return true
      } else {
        return false
      }
    } else if (type === 'proficiencies') {
      if (!teachers.filters.item.condition['proficiencies.level']) {
        return true
      } else {
        return false
      }
    } else if (type === 'sort') {
      if (!teachers.sort)  {
        return true
      } else {
        return false
      }
    }
  }, [teachers.filters, teachers.sort]);

  const filtersDefaultValues = useCallback((type, value) => {
    if (type === 'instruments') {
      return teachers.filters.array.one_of['instruments.name'] && teachers.filters.array.one_of['instruments.name'][0] === value;
    }
  }, [teachers.filters]);


  return (
    <TeacherRow type="flex" justify="center" className={isShowSearchList ? 'pl-open-mobile' : ''} >
      <Container>
        <ShowHideSidebarmobile sm={4} md={0}>
          <MenuButton 
            onClick={() => {}}
            className={isShowSearchList ? 'pl-open-filters' : ''}
          >
            {t('open_filters')}
          </MenuButton>
        </ShowHideSidebarmobile>        
        <SideContent className={"pl-sidebar-filters " + isShowSearchList ? 'pl-open' : ''}>
          {/*<FilterBlock>*/}
            {/*<p>{t('teacher_list_filter_title1')}</p>*/}
            {/*<Slider marks={marks} defaultValue={35} />*/}
          {/*</FilterBlock>*/}
          <FilterBlock>
            <p>{t('teacher_list_filter_title2')}</p>
            <List>
              {/*<li>*/}
                {/*<RadioButton*/}
                  {/*id={"None"}*/}
                  {/*label={"None"}*/}
                  {/*onChange={() => {handleChangeSort('sortTypeList', 'None')}}*/}
                  {/*nameGroup="sortTypeList"*/}
                  {/*checked={handleChekedAllFilters('sort')}*/}
                {/*/>*/}
              {/*</li>*/}

              <li>
                <span
                  style={{
                    fontWeight: 'bold'
                  }}
                >Sort by price</span>
                <Select
                  onChange={(value) => {handleChangeSort('sortTypeList', 'hour_rate', value)}}
                  defaultValue={'none'}
                  style={{
                    width: 200,
                    padding: '10px 0'
                  }}
                >
                  <Option value="none">
                    None
                  </Option>
                  <Option value="1">
                    Lowest
                  </Option>
                  <Option value="-1">
                    Highest
                  </Option>
                </Select>
              </li>

              <li>
                <span
                  style={{
                    fontWeight: 'bold',
                    padding: '10px 0'
                  }}
                >Sort by rating</span>
                <Select
                  onChange={(value) => {handleChangeSort('sortTypeList', 'rating', value)}}
                  defaultValue={'none'}
                  style={{
                    width: 200
                  }}
                >
                  <Option value="none">
                    None
                  </Option>
                  <Option value="1">
                    Lowest
                  </Option>
                  <Option value="-1">
                    Highest
                  </Option>
                </Select>
              </li>
              {/*{*/}
                {/*sortType.map((item, i) => {*/}
                  {/*return (*/}
                    {/*<li key={i}>*/}
                      {/*<RadioButton*/}
                        {/*id={i}*/}
                        {/*label={item.item}*/}
                        {/*onChange={() => handleChangeSort('sortTypeList', item.id)}*/}
                        {/*nameGroup="sortTypeList"*/}
                      {/*/>*/}
                    {/*</li>*/}
                  {/*)*/}
                {/*})*/}
              {/*}*/}
            </List>
          </FilterBlock>
          <FilterBlock>
            <p>{t('teacher_list_filter_title5')}</p>
            {Instruments.length > 0 && <List>
              <li>
                <RadioButton
                  id={'All instruments'}
                  label={'All instruments'}
                  nameGroup="instrumentList"
                  checked={handleChekedAllFilters('instruments')}
                  onChange={() => handleChangeFilters('instrumentList', 'All instruments')}
                />
              </li>
              {
                Instruments.map((item, i) => {
                  return (
                    <li key={i}>
                      <RadioButton
                        id={item.name}
                        label={item.name}
                        nameGroup="instrumentList"
                        checked={filtersDefaultValues('instruments', item.name)}
                        onChange={() => handleChangeFilters('instrumentList', item.name)}
                      />
                    </li>
                  )
                })
              }
            </List>}
          </FilterBlock>
          <FilterBlock>
            <p>{t('teacher_list_filter_title3')}</p>
            {Proficiencies.length > 0 && <List>
              <li>
                <RadioButton
                  id={'All levels'}
                  label={'All levels'}
                  nameGroup="levelList"
                  checked={handleChekedAllFilters('proficiencies')}
                  onChange={() => handleChangeFilters('levelList', 'All levels')}
                />
              </li>
              {
                Proficiencies.map((item, i) => {
                  return (
                    <li key={i}>
                      <RadioButton
                        id={item.level}
                        label={item.level}
                        nameGroup="levelList"
                        onChange={() => handleChangeFilters('levelList', item.level)}
                      />
                    </li>
                  )
                })
              }
            </List>}
          </FilterBlock>
          <FilterBlock>
            <p>{t('teacher_list_filter_title4')}</p>
            {
              Languages.length > 0 && <List>
                <li>
                  <RadioButton
                    id={'All languages'}
                    label={'All languages'}
                    checked={handleChekedAllFilters('languages')}
                    nameGroup="languageList"
                    onChange={() => handleChangeFilters('languageList', 'All languages')}
                  />
                </li>
              {
                Languages.map((item, i) => {
                  return (
                    <li key={i}>
                    <RadioButton
                      id={item.language}
                      label={item.language}
                      nameGroup="languageList"
                      onChange={() => handleChangeFilters('languageList', item.language)}
                    />
                    </li>
                  )
                })
              }
            </List>
            }
          </FilterBlock>
        </SideContent>
        <CentralContent className="pl-search-result">
          {teachers.data && teachers.data.length && teachers.data[0]._id ? <InfiniteScroll
            pageStart={0}
            loadMore={loadFunc}
            hasMore={true}
            loader={<Loader className={teachers.skip + teachers.data.limit <= teachers.data.length ? '' : 'hide'} key={0}>Loading ...</Loader>}
          >
            {teachers.data.map((item, index) => {
              const avatarUrl = `${process.env.REACT_APP_PUBLIC_FILE_URL}/${item._id}/${item.avatar}`;
              return (
                <TeacherBlock
                  _id={item._id}
                  key={index}
                  teacherPhoto={(!!item._id && !!item.avatar ? avatarUrl : '')}
                  title={(item.first_name + ' ' + item.last_name.slice(0, 1)) || ''}
                  title2={item.title || ''}
                  text={item.background || ''}
                  rating={item.rating || ''}
                  price={item.user_metadata.hour_rate || ''}
                  city={item.location.location || ''}
                  age={(new Date().getFullYear() - new Date(item.age).getFullYear()) || ''}
                  // distance={item.distance}
                  country={Images[`${item.location.country}Flag` || 'GermanyFlag']}
                />
              )
            })}
          </InfiniteScroll> : <Empty/>}
        </CentralContent>
      </Container>
    </TeacherRow>
  );
}
