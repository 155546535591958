import React, {useEffect} from "react";
import { Layout } from "antd";
import {
   Description,
   Freedom,
   HowItWorks,
   OurMission,
   Rockstar,
   TestimonialsSlider,
} from "../../components";
import {useDispatch, useMappedState} from "redux-react-hook";
import InstrumentList from "../../components/molecules/HomeComponents/InstrumentList/InstrumentList";

export function Home() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'GET_ROCKSTARS'
    })
  }, [dispatch]);

  const rockstars = useMappedState((state) => state.rockstars);

  return (
    <Layout>
      <Rockstar rockstars={rockstars}/>
      <OurMission />
      <TestimonialsSlider />
      <HowItWorks />
      <Description />
      <InstrumentList />
      <Freedom />
    </Layout>
  );
}
