import styled from "styled-components";
import { device } from "../../../constants/device";
import { colGreen } from '../../../constants/variables';

export const Button = styled.button`
  font-family: 'PoppinsBold', sans-serif;
  font-size: 12px;
  color: #fff;
  line-height: 1;
  background-color: ${colGreen};
  border: none;
  border-radius: 25px;
  padding: 10px;
  cursor: pointer;
  min-height: 32px;

  &:hover {
    color: #40a9ff;
  }

  @media ${device.laptop} {
    padding: 10px 30px;
  }
`;