import styled from 'styled-components';
import {
   colDisabledButton,
   colLightBlueText,
} from '../../../../constants/variables';
import { Row } from 'antd';

export const StepBlock = styled(Row)`
   height: 150px;
   width: 100%;
   background-color: #fff;
   position: relative;
   
   &:after {
      position: absolute;
      content: '';
      width: 100%;
      z-index: 1;
      border: 1px solid ${colDisabledButton};
      top: 50%;
   }

   &.left {
      flex-direction: row-reverse;
   }

   &.rigth {
      flex-direction: row;
   }

   &.top {
      height: auto;

      &:after {
         top: 7px;
      }
   }
`;

export const StepNumber = styled.p`
   background-color: ${colLightBlueText};
   border-radius: 50%;
   font-family: 'OdinRounded', sans-serif;
   color: white;
   height: 20px;
   width: 20px;
   padding: 4px 0;
   margin: 0;
   text-align: center;
   line-height: 1;
   position: relative;
   top: 45%;
   z-index: 3;

   &.left {
      right: 15px;
   }

   &.right {
      left: 15px;
   }

   &.top {
      left: 15px;
      top: 40%;
   }
   
`;