import styled from 'styled-components';
import { device } from "../../../../constants/device";
import { colGreen } from '../../../../constants/variables';

export const PageTitle = styled.h2`
    font-family: 'OdinRounded', sans-serif;
    font-size: 38px;
    color: ${colGreen};
    line-height: 0.8;
    margin-bottom: 30px;

    @media ${device.laptop} {
        margin-bottom: 100px;
        font-size: 60px;
    }
`;

export const TextInstruments = styled.div`
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 16px;
    color: #333;
    line-height: 1.3;
    margin: 0 auto 40px;
    width: 90vw;

    @media ${device.laptop} {
        line-height: 1.2;
        margin-bottom: 100px;
        font-size: 20px;
        width: 90%;
    }
`;

export const ItemImage = styled.div`
    display: inline-block;
    margin-bottom: 20px;
    
    img {
        height: 48px;
        width: 48px;
        border-radius: 50%;

        @media ${device.mobileL} {
            height: 64px;
            width: 64px;
        }
    }
`;

export const ItemInstrument = styled.div`
    background-color: #f9f9f9;
    color: #333;
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 14px;
    padding: 4px 10px;
    display: inline-block;
    line-height: 0.75;
    border-radius: 25px;
    margin-bottom: 16px;
    cursor: pointer
    @media ${device.laptop} {
        margin-bottom: 36px;
        font-size: 18px;
        padding: 8px 18px;
    }
    
    &:not(:last-child) {
        margin-right: 8px;
    }
`;
