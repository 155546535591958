import React, {useCallback, useState} from 'react';
import { useTranslation } from 'react-i18next';

import {
   Row,
   Title,
   SearchDropdown,
   Wrapper,
   SubmitSearch,
   IconSearch
} from './style';
import { Container } from "../..";
import {Route, withRouter} from 'react-router-dom';
import {useDispatch, useMappedState} from "redux-react-hook";

export const MainSearch = withRouter(({history, toggleSearch}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const Search = useMappedState((state => state.search));
  const LocationList = Search.locations.map((item) => ({text: item.location, value: item.location, children:[]}));
  const InstrumentList = Search.instruments.map((item) => ({text: item.name, value: item.name, children:[]}));
  const [location, setLocation] = useState('');
  const [instrument, setInstrument] = useState('');

    const onSelect = useCallback((value, type) => {

      if (type === 'locationValue') {
        setLocation(value);
      } else {
        setInstrument(value);
      }

      dispatch({
        type: 'SET_SEARCH',
        payload: {
          [type]: value
        }
      })
    }, [dispatch]);



    const onSearch = useCallback((value = null, type) => {
      if (type === 'GET_LOCATIONS_SEARCH') {
        setLocation(value);
      } else {
        setInstrument(value)
      }
      dispatch({
        type,
        payload: value
      })
    }, [dispatch]);


    const handleSearch = useCallback(() => {
      dispatch({
        type: 'SET_FILTERS',
        payload: {
          array: (Search.instrumentValue ? {
            one_of: (!Search.locationValue  ?
                {
                  'instruments.name': [Search.instrumentValue],
                  'class_types.type': ['web'],
                } :
                {
                  'instruments.name': [Search.instrumentValue],
                }
            )
          }: {}
          ),
          item: (Search.locationValue ? {
            condition: {
              'location.location': Search.locationValue
            }
          }: {})
        }
      });
      history.push('teacherslist');
    }, [dispatch, Search, history]);

    return (
        <Route render={() => (
            <Row>
                <Container>
                    <Title>{t('search_main_title')}</Title>
                    <Wrapper>
                      <SearchDropdown
                        dataSource={LocationList}
                        onSelect={(e) => {onSelect(e, 'locationValue')}}
                        onSearch={(e) => {onSearch(e, 'GET_LOCATIONS_SEARCH')}}
                        value={location}
                        placeholder={t('search_dropdown_text1')}
                      />
                      <SearchDropdown
                        className="pl-select-instrument"
                        dataSource={InstrumentList}
                        onSelect={(e) => {onSelect(e, 'instrumentValue')}}
                        onSearch={(e) => {onSearch(e, 'GET_INSTRUMENTS_SEARCH')}}
                        value={instrument}
                        placeholder={t('search_dropdown_text2')}
                      />
                      <SubmitSearch
                        disabled={!Search.locationValue || !Search.instrumentValue}
                        onClick={handleSearch}
                      >
                        <IconSearch type="search" />
                      </SubmitSearch>
                    </Wrapper>
                </Container>
            </Row>
        )} />
    );
})
