import styled from 'styled-components';
// import { Row } from "antd";
import { Link } from 'react-router-dom';

export const MenuList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        padding-right: 10px;

        &:not(:last-child) {
            margin-right: 10px;
            border-right: 1px solid #fff;
        }
    }
`;

export const MenuItem = styled(Link)`
    color: #fff;

    &:hover {
        text-decoration: underline;
    }

    i {
        margin-right: 5px;        
    }
`;

export const AMenuItem = styled.a`
  color: #fff;

    &:hover {
        text-decoration: underline;
    }

    i {
        margin-right: 5px;        
    }
`;
