import { Row, Icon } from 'antd';
import styled from "styled-components";
import { device } from "../../../constants/device";
import { colGreen } from '../../../constants/variables';
import { Link } from "react-router-dom";

export const RatingIconAnt = styled(Icon)`
    color: ${colGreen};
    height: 12px;
    width: 13px;

    &:not(:last-child) {
        margin-right: 3px;
    }
`;

export const RowAnt = styled(Row)`
    background-color: #fff;
    margin-bottom: 20px;
    width: 100%; 
    animation: fadeIn .5s ease-in


    @keyframes fadeIn {
        from {
            opacity: 0
        }
        
        to {
            opacity: 1
        }
    }
`;

export const BlockHeader = styled(Row)`
    width: 100%;
    padding: 20px 25px 15px;
    border-bottom: 2px solid #f9f9f9;
    justify-content: space-between;
    
    p {
        font-family: 'OdinRounded', sans-serif;
        font-size: 20px;
        margin-bottom: 0;
        line-height: 1;
    }
`;

export const FirstBlock = styled.div`
    width: 15%;
    max-width: 110px;
    min-width: 102px;
`;

export const SecondBlock = styled(Row)`
    width: 70%;
`;

export const ThirdBlock = styled(Row)`
    width: 22%;
    justify-content: flex-end;
    align-content: space-between;
    max-width: 100px;

    @media ${device.tablet} {
        width: 18%;
    }

    @media ${device.laptop} {
        width: 15%;
    }
`;

export const BlockText = styled(Row)`
    font-family: 'PoppinsRegular', sans-serif;
    padding: 20px;
    align-content: space-between;
    width: 100%;
`;

export const LessonTime = styled.div`
    color: #d7d7d7;
    font-size: 12px;
    font-family: 'PoppinsRegular', sans-serif;
    text-align: right;
    margin-bottom: 18px;

    @media ${device.tablet} {
        margin-bottom: 18px;
    }

    @media ${device.laptop} {
        margin-bottom: 8px;
    }
`;

export const HireButton = styled(Link)`
    font-family: 'PoppinsBold', sans-serif;
    color: #fff;
    line-height: 1;
    background-color: ${colGreen};
    border: none;
    border-radius: 25px;
    text-transform: uppercase;
    cursor: pointer;
    min-height: 32px;
    margin: 10px 0;
    padding: 10px 12px;
    font-size: 12px;
    height: 27px;
    order: 2;

    @media ${device.laptop} {
        padding: 10px 30px;
        order: 1;
        margin: 0;
    }

    @media ${device.tablet} {
        font-size: 14px;
        padding: 10px 20px;
        min-height: 36px;
    }
`;

export const TeacherPhoto = styled(Link)`
    display: inline-block;
    width: 102px;
    height: 102px;
    border-radius: 50%;
    overflow: hidden;
    
    img {
        object-fit: cover;
        transform: scale(1.2);
        width: 100%;
        height: 100%;
    }
`;

export const TeacherPrice = styled.div`
    color: #b3e830;
    font-size: 28px;
    font-family: 'PoppinsBold', sans-serif;
    line-height: 1;
    
    @media ${device.tablet} {
        font-size: 36px;
        text-align: right;
    }
`;

export const TeacherText = styled.div`
    font-size: 12px;
    font-family: 'PoppinsRegular', sans-serif;
    color: #333;
    margin-bottom: 20px;
`;

export const Additional = styled.div`
    color: #d7d7d7;
    font-size: 12px;
    font-family: 'PoppinsRegular', sans-serif;
    order: 1;

    @media ${device.laptop} {
        order: 2;
    }
`;

export const TeacherAdditional = styled(Row)`
    width: 100%;
    align-content: flex-end;
    align-items: baseline;
`;

export const TeacherName = styled.p`
    color: #333;
    font-family: 'OdinRounded', sans-serif;
    font-size: 18px;
    min-width: 14%;
    margin: 0 5px 0 0;
`;

export const Rating = styled.div`
    min-width: 90px;
    width: 10%;

    @media ${device.laptopL} {
        min-width: 130px;
        width: 15%;
    }
`;

export const SecondTitle = styled.p`
    font-family: 'OdinRounded', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #d7d7d7;    
    margin: 0;
    width: 70%;

    @media ${device.tablet} {        
        font-size: 20px;
        line-height: 1;
    }

    @media ${device.laptopL} {
        width: 56%;        
    }
`;

export const TeacherFunction = styled.div`
    width: 45px;
    display: none;

    img {
        max-height: 21px;
        max-width: 17px;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }
`;

export const TeacherCountry = styled.div`
    max-width: 52px;
    width: 12%;
    text-align: right;

    @media ${device.laptopL} {
        max-width: 42px;
    }
`;
