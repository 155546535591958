import { Row, Select, TimePicker } from "antd";
import styled from "styled-components";

export const RowAnt = styled(Row)`
    width: 90%;
    margin: 10px 0 0;
    padding: 10px 0 0;
    
    &:not(:last-child) {
        border-top: 1px solid #e8e8e8;
    }
`

export const LessonSetupSelect = styled(Select)`
    margin: 0 0 10px;
    width: 100%;
`

export const SelectTimeWrapper = styled(Row)`
    width: 100%;
    align-items: baseline;
    line-height: 1;
`

export const TimePickerAnt = styled(TimePicker)`
    max-width: 100px;
`
