import styled from "styled-components";
import {Button, Row} from 'antd';
import { device } from "../../../../constants/device";
import { colGreen } from '../../../../constants/variables';

export const RowAnt = styled(Row)`
    background-color: #fff;
    width: 100%;
`;

export const BlockHeader = styled(Row)`
  width: 100%;
  padding: 20px 25px 15px;
  border-bottom: 2px solid #f9f9f9;
  color: ${colGreen};

  p {
    font-family: 'OdinRounded', sans-serif;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1;
    
    @media ${device.laptop} {
      font-size: 20px;
    }
  }
`;

export const InfoWrapper = styled.div`
  padding: 20px;
  width: 100%;
  
  p,
  span {
    display: block;
    font-size: 18px;
    width: 100%;
    text-align: center;
  }

  span {
    font-size: 24px;
  }
`;

export const LinkProfile = styled(Button)`
  border: none !important;
  border-radius: 25px !important;
  background: linear-gradient(rgb(179, 232, 48), rgb(179, 232, 48) 85%, rgb(179, 232, 48)) !important;
  overflow: visible !important;

  &:not(:last-child) {
    margin: 0 !important;
    @media ${device.tablet} {
      margin: 0 0 10px !important;
    }
  }

  span {
    font-family: 'PoppinsBold', sans-serif !important;
    color: #fff !important;
    margin: 0 !important;
    line-height: 1 !important;
    box-shadow: none !important;
    background-image: linear-gradient(rgb(179, 232, 48), rgb(179, 232, 48) 85%, rgb(179, 232, 48)) !important;
    text-shadow: none !important;
    border: none !important;
    border-radius: 25px !important;
    text-transform: uppercase !important;
    cursor: pointer !important;
    height: auto !important;
    min-height: 32px !important;
    display: inline-block !important;
    vertical-align: top !important;
    width: 100% !important;
    text-align: center !important;
    font-size: 12px !important;
    padding: 10px 14px !important;
    min-width: 120px !important;
  
    @media ${device.laptop} {
      width: auto !important;
      font-size: 12px !important;
      padding: 10px 14px !important;
    }
  
    @media ${device.laptopL} {
      margin: auto !important;
    }
  }
`;