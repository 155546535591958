import React, {useCallback, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import {
    TextInstruments,
    ItemInstrument,
    ItemImage,
    PageTitle
} from "./style";
import { REGISTER_ROUTE } from "../../../../constants/routes";
import { useDispatch, useMappedState } from "redux-react-hook";
import { withRouter } from "react-router-dom";
import { ButtonLink } from "../../../atoms";

function createImageInstruments() {
    let row = [];
    for (let i = 1; i < 13; i++) {
        let imgsrc = `images/instruments/${i}.jpg`;

        row.push(<Col xs={4} md={3} lg={2} key={i}><ItemImage>
            <img src={imgsrc} alt='imgsrc' />
        </ItemImage></Col>)
    }
    return row;
}

const InstrumentList = withRouter(({history}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const instruments = useMappedState(state => state.instruments);
    useEffect(() => {
        dispatch({
            type: 'GET_INSTRUMENTS'
        })
    }, [dispatch]);

    const chooseInstrument = useCallback((name) => {
        dispatch({
            type: 'SET_FILTERS',
            payload: {
                array: {
                    one_of: {
                      'instruments.name': [name],
                    }
                }
            }
        });
        history.push('/teacherslist')
    }, [dispatch, history]);

    return (
        <Row type="flex" justify="center" className="pl-mt-21">
            <div className='pl-container pl-text-center'>
                <PageTitle>{t('instrument_title')}</PageTitle>
                <TextInstruments>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</TextInstruments>
                <Row type="flex" justify="center" className='pl-mb-8'>{createImageInstruments()}</Row>
                <div className='pl-mb-35'>
                    {
                        instruments.map((item) => {
                            return(
                              <ItemInstrument key={item._id} onClick={() => chooseInstrument(item.name)}>{item.name}</ItemInstrument>
                            )
                        })
                    }
                </div>
                <ButtonLink link={REGISTER_ROUTE} label={t("login_form_registration")}/>
            </div>
        </Row>
    );
});

export default InstrumentList;
