const initialState = {
  canBook: false,
  bookSuccess: false
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CAN_BOOK':
      return {...state, ...action.payload};
    default:
      return state;
  }
};

export default bookingReducer;
