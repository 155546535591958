import React from 'react';
import styled from "styled-components";
import { Row } from 'antd';

const RowAnt = styled(Row)`
    background-color: #fff;
    margin-bottom: 20px;
`;

const BlockHeader = styled(Row)`
    width: 100%;
    padding: 20px 25px 15px;
    border-bottom: 2px solid #f9f9f9;
    p {
        font-family: 'OdinRounded', sans-serif;
        font-size: 20px;
        margin-bottom: 0;
        line-height: 1;
    }
`;

const BlockText = styled(Row)`
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 18px;
    padding: 25px;
`;

const SecondTitle = styled.p`
    color: #d8d8d8;
    font-family: 'OdinRounded', sans-serif;
    font-size: 18px;
`;

export function TextBlock(props) {
    return (
        <RowAnt type="flex">
            <BlockHeader type="flex" justify="space-between">
                <p>{props.title}</p>
                <SecondTitle>{props.title2}</SecondTitle>
            </BlockHeader>
            <BlockText>{props.text}</BlockText>
        </RowAnt>
    );
}
