import React from 'react';
import styled from "styled-components";
import { Row } from 'antd';
import { colGreen } from '../../../../constants/variables';

const RowAnt = styled(Row)`
    background-color: #fff;
    margin-bottom: 20px;
`;

const BlockHeader = styled(Row)`
      width: 100%;
      padding: 20px 25px 15px;
      border-bottom: 2px solid #f9f9f9;
      p {
        font-family: 'OdinRounded', sans-serif;
        font-size: 20px;
        margin-bottom: 0;
        line-height: 1;
        color: ${colGreen};
      }
    `;

const BlockText = styled(Row)`
      font-family: 'PoppinsRegular', sans-serif;
      font-size: 18px;
      padding: 25px;
    `;

export function DashboardGraph() {
    return (
        <RowAnt type="flex">
            <BlockHeader type="flex" justify="space-between">
            </BlockHeader>
            <BlockText />
        </RowAnt>
    );
}
