import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  REGISTER_ROUTE,
  DASHBOARD_ROUTE,
  FORUM_ROUTE,
  FAQ_ROUTE,
  CONTACTS_ROUTE,
  CHAT_ROUTE,
  ADMIN_PANEL_ROUTE,
  BENEFITS_ROUTE
} from '../../../constants/routes';
import {
  TopMenu,
  CloseButton,
  ListMenu,
  MenuLink,
  MenuDirectLink,
  ListSubMenu,
  SubMenuLink,
  TopMenuWrapper,
  LoginHeader, MenuBlockButton,
} from './style';
import useAuth from "../../../hooks/useAuth";
import { withRouter } from 'react-router-dom';
import { LoginFormMenu } from '../../molecules';
import {useDispatch} from "redux-react-hook";

export const MainMenu = withRouter(props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {user} = useAuth([], true);

  const logOut = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      localStorage.removeItem('token');
      props.history.push('/login');
      dispatch({
        type: 'REMOVE_AUTH_DATA'
      })
    }, [props, dispatch]);

  return (
    <>
      <TopMenuWrapper onClick={() => props.toogleMenu(!props.isShowMenu)}/>
      <TopMenu>
        <CloseButton onClick={() => props.toogleMenu(!props.isShowMenu)} />
        <ListMenu>
          <MenuLink to={DASHBOARD_ROUTE}>{t('top_menu_item1')}</MenuLink>
          {!localStorage.getItem('token') && (
            <MenuLink to={REGISTER_ROUTE}>{t('top_menu_item2')}</MenuLink>
          )}
          {localStorage.getItem('token') && (
            <MenuLink to={CHAT_ROUTE}>{t('menu_chat')}</MenuLink>
          )}
          {localStorage.getItem('token') && (
            <MenuDirectLink href={FORUM_ROUTE}>{t('forum')}</MenuDirectLink>
          )}
          <MenuLink to={FAQ_ROUTE}>{t('top_menu_item3')}</MenuLink>
          <MenuLink to={BENEFITS_ROUTE}>{t('top_menu_item5')}</MenuLink>
          <MenuLink to={CONTACTS_ROUTE}>{t('top_menu_item4')}</MenuLink>
          { user && user.role_id && user.role_id.role === 'admin' &&
            <MenuLink to={ADMIN_PANEL_ROUTE}>Admin panel</MenuLink>
          }
        </ListMenu>
        <ListSubMenu>
          {localStorage.getItem('token') && <SubMenuLink to="" onClick={logOut}>{t("log_out")}</SubMenuLink>}
          {!localStorage.getItem('token') &&
          <>
            <LoginHeader>{t('login_form_login_button')}</LoginHeader>
            <LoginFormMenu history={props.history} />
          </>
          }
        </ListSubMenu>
        <MenuBlockButton>{t('how_it_works_button')} &rarr;</MenuBlockButton>
      </TopMenu>
    </>
  );
});
