import styled from "styled-components";
import { Row, Checkbox, Icon } from "antd";
import { device } from "../../constants/device";
import { ButtonLink } from "../../components/atoms/ButtonLink/ButtonLink";

export const TeacherRow = styled(Row)`
  position: relative;
  padding-top: 0;

  @media ${device.laptopL} {
    padding-top: 70px;
  }
`;

export const SideContent = styled.div`
  width: 100%;
  display: block;
  vertical-align: top;
  padding: 0 10px;

  @media ${device.tablet} {
    display: inline-block;
    width: 35vw;
    padding: 220px 30px 0 0;
  }

  @media ${device.laptop} {
    width: 405px;
    padding: 370px 115px 0 0;
  }
`;

export const CentralContent = styled.div`
  width: 100%;
  display: block;
  vertical-align: top;
  padding: 0 10px 40px;
  background: #fff;

  @media ${device.tablet} {
    width: 65vw;
    display: inline-block;
  }

  @media ${device.laptop} {
    width: calc(100% - 405px);
  }
`;

export const TeacherSmallInfo = styled(Row)`
  padding: 15px 20px;
  margin-bottom: 20px;
`;

export const TeacherName = styled.h2`
  font-family: 'OdinRounded', sans-serif;
  font-size: 36px;
  line-height: 1;

  @media ${device.laptop} {
    font-size: 56px;
  }
`;

export const UserPhoto = styled.div`
  height: 200px;
  width: 200px;
  background-color: #fff;
  border-radius: 50%;
  border: 8px solid #fff;
  position: relative;
  margin-bottom: 15px;

  @media ${device.tablet} {
    border-radius: 0 50% 50% 0;
    border: 8px solid #fff;
    position: absolute;
    left: 0;
    margin-bottom: 0;
    top: 0;
  }

  @media ${device.laptop} {
    height: 340px;
    width: 340px;
  }

  .user-photo {
    right: 0;
    margin: auto;
    border-radius: 50%;
    height: 185px;
    width: 185px;
    position: relative;
    z-index: 1;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    left: 0;
    overflow: hidden;

    @media ${device.tablet} {
      height: 180px;
      width: 180px;
      position: absolute;
    }
    
    img {
      object-fit: cover;
      transform: scale(1.2);
      width: 100%;
      height: 100%;
    }
  }
`;

export const ListCheck = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;

    &:not(:last-child) {
      margin: 0 15px 0 0;
    }
  }
`;

export const EditItems = styled(Row)`
  padding: 0 0 20px 0;
  margin: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid #c0c0c0;
  }
`;

export const LessonsItem = styled(Checkbox)`
  background-color: #fff;
  color: #333;
  font-family: 'PoppinsRegular', sans-serif;
  font-size: 12px;
  line-height: 0.75;

  &:hover,
  &.active {
    color: #76d9ff;
  }
`;

export const ItemTitle = styled.h3`
  font-family: 'PoppinsBold', sans-serif;
  color: #7c7c7c;
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.2;
`;

// TODO стили не применяются

export const LinkProfile = styled(ButtonLink)`
  margin: 0 0 0 20px;
`;

// TODO end

export const IconAnt = styled(Icon)`
  cursor: pointer;
  font-size: 14px;
  vertical-align: top;
  margin: 0 5px;

  &:hover {
    color: #76d9ff;
  }
`;