import styled from 'styled-components';
import { colGreen } from "../../../../constants/variables";
import { device } from "../../../../constants/device";

export const UserMessageBlock = styled.div`
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;

    @media ${device.tablet} {
        padding-left: 30px;
    }
`;

export const Row = styled.div`
    display: flex;
    padding-top: 10px;
    width: 50px;
`;

export const UserPhoto = styled.img`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
`;

export const UserName = styled.p`
    display: flex;
    font-family: 'PoppinsBold', sans-serif;
    font-size: 12px;
    margin: 0px
    color: ${colGreen};
    
    @media ${device.tablet} {
        font-size: 14px;
    }
`;

export const MessageWrapper = styled.div`
    display: flex
    font-size: 12px;
    padding-right: 40px;
    position: relative;

    @media ${device.tablet} {
        font-size: 14px;
    }
`;

export const MessageDate = styled.span`
    position: absolute;
    right: 0;
    top: 0px;
    font-size: 8px;
    color: #a5a5a5;

    @media ${device.tablet} {
        font-size: 12px;
        right: -10px;
    }
`;

export const SecondRow = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    position: relative;
`;