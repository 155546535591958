import React, {useCallback, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {Col, DatePicker, Select} from "antd";
import {
    ModalAnt,
    RequestInfo,
    EditItems
} from "./style";
import moment from "moment";
import {useDispatch} from "redux-react-hook";
const { Option } = Select;

export function EditRequestModal({
   isShowModal,
   hideModal,
   disabledDates,
   onSelectDate,
   selectedDateEvents,
   data,
   selectedDate
 }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [requestItem, setRequestItem] = useState(null);
  const [scheduleItem, setScheduleItem] = useState(null);

  useEffect(() => {
    if (isShowModal) {
      setRequestItem({
        _id: data._id,
        schedule: data.schedule,
        instrument: data.instrument._id,
        lesson_type: data.class_type._id
      });
      onSelectDate(data.start_time);
      }
  }, [isShowModal, data, onSelectDate]);

  useEffect(() => {
    if (selectedDateEvents && selectedDateEvents.length > 0) {
      setScheduleItem(selectedDateEvents.filter((item) => item._id === data.schedule)[0]);
    }
  }, [selectedDateEvents, data.schedule]);

  const onChangeScheduleItem = useCallback((id) => {
    setScheduleItem(selectedDateEvents.filter((item) => item._id === id)[0])
  }, [selectedDateEvents]);

  const onSaveChanges = useCallback(() => {
    dispatch({
      type: 'UPDATE_BOOK_REQUEST',
      payload: requestItem
    });
    hideModal(!isShowModal)
  }, [isShowModal, dispatch, requestItem, hideModal]);

  return (
    <ModalAnt
        title={t("customize_lesson")}
        visible={isShowModal}
        onOk={onSaveChanges}
        onCancel={() => hideModal(!isShowModal)}
        okText={t("customize_lesson_modal_ok")}
        cancelText={t("customize_lesson_modal_cancel")}
    >
        <RequestInfo type="flex" justify="start">
            <EditItems gutter={8}>
                <Col span={12}>
                  {
                    requestItem &&
                    <DatePicker
                      defaultValue={moment(data.start_time)}
                      disabledDate={disabledDates}
                      onChange={onSelectDate}
                      value={moment(selectedDate)}
                      placeholder='choose available date'
                    />
                  }
                </Col>
                <Col span={12}>
                  { requestItem && requestItem.schedule && selectedDateEvents && selectedDateEvents.length > 0 &&
                    <Select
                      placeholder='choose available time'
                      disabled={!selectedDateEvents || selectedDateEvents.length === 0}
                      style={{
                        width: 220
                      }}
                      value={requestItem.schedule}
                      onSelect={onChangeScheduleItem}
                    >
                      { selectedDateEvents.map((item) => {
                        return (
                          <Option
                            key={item._id}
                            value={item._id}
                          >
                            {moment(item.start_time).format('HH: mm')}
                          </Option>
                        )
                      })}
                    </Select>
                  }
                    </Col>
            </EditItems>
            <EditItems gutter={8}>
                <Col span={12}>
                  { requestItem && requestItem.lesson_type && scheduleItem && scheduleItem.class_types.length > 0 &&
                    <Select
                      placeholder='choose lesson type'
                      style={{
                        width: 220
                      }}
                      value={requestItem.lesson_type}
                    >
                      {
                        scheduleItem.class_types.map((item) => {
                          return (
                            <Option
                              key={item._id}
                              value={item._id}
                            >
                              {item.type}
                            </Option>
                          )
                        })
                      }
                    </Select>
                  }
                </Col>
                <Col span={12}>
                  {requestItem && requestItem.instrument && scheduleItem && scheduleItem.instruments.length > 0 &&
                    <Select
                      placeholder='choose instrument'
                      style={{
                        width: 220
                      }}
                      value={requestItem.instrument}
                    >
                      {
                        scheduleItem.instruments.map((item) => {
                          return (
                            <Option
                              key={item._id}
                              value={item._id}
                            >
                              {item.name}
                            </Option>
                          )
                        })
                      }
                    </Select>
                  }
                </Col>
            </EditItems>
        </RequestInfo>
    </ModalAnt>
  );
}
