import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from "antd";
import { Container, ButtonLink } from "../../../../components";
import {
    BlockImg,
    BlockTitle,
    FirstBlock,
    Message,
    SubMessage
} from "./style";
import { REGISTER_ROUTE } from "../../../../constants/routes";
import {useDispatch, useMappedState} from "redux-react-hook";

export function Description() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: "GET_TEACHERS_COUNT"
        })
    }, [dispatch]);


    const {total} = useMappedState(state => state.teachers)

    return (
        <Row type="flex" className='pl-userlist pl-text-center' justify="center">
            <Container>
                <BlockTitle className="pl-text-left" marginTop="0">{total + t('tutorial_block_title')}</BlockTitle>
                <Row type="flex" justify="space-between">
                    <Col xs={24} sm={12} md={12}>
                        <FirstBlock>
                            <Message>{t('tutorial_block_message_1') + total + t('tutorial_block_message_2')}</Message>
                            <SubMessage>{t('tutorial_block_submessage')}</SubMessage>
                            <ButtonLink link={REGISTER_ROUTE} label={t("login_form_registration")}/>
                        </FirstBlock>
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                        <BlockImg />
                    </Col>
                </Row>
                <BlockTitle className="pl-text-right" marginBottom="0">{t('tutorial_block_title2')}</BlockTitle>
            </Container>
        </Row>
    );
}
