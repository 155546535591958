import {
  AdditionalInfo, BookingButton,
  CentralContent, ContactButton, Country, IconAnt, LessonsItem,
  List, LocationImage, Price, RatingList,
  RowAnt,
  SideContent,
  TeacherName,
  TeacherSmallInfo,
  UserPhoto, VideoContent, VideoIcon, VideoSlider, VideoBlock
} from "./style";
import {BackgroundRow, Container} from "../../components/atoms";
import {Col, Row} from "antd";
import {TextBlock} from "../../components/molecules";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {FeedbackItem} from "../../components/molecules/FeedbackItem/FeedbackItem";
import {useDispatch, useMappedState} from "redux-react-hook";
import {withRouter} from "react-router-dom";


const TeacherContent = ({data, match, history}) => {

  const { t } = useTranslation();

  const settings = {
    customPaging: function(i) {
      return (
        <span>{i+1}</span>
      );
    },
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const dispatch = useDispatch();
  const user = useMappedState((state) => state.auth).user;

  function ratingList(rating) {
    let row = [];
    for (let i = 0; i < rating; i++) {
      row.push(<IconAnt type="star" theme="filled" key={i} />);
    }
    return row;
  }

  const addDialog = useCallback(() => {
    dispatch({
      type: 'ADD_NEW_DIALOG',
      payload: [user._id, match.params.id]
    });
    history.push('/chat')
  }, [dispatch, user, match, history]);

  return (
    <BackgroundRow type="flex" justify="center" background={data.profile_img}>
      <Container>
        <UserPhoto>
          <div className="user-photo">
            <img src={process.env.REACT_APP_PUBLIC_FILE_URL + '/' + data._id + '/' + data.avatar} alt='Teacher'/>
          </div>
        </UserPhoto>
        <SideContent>
          <List>
            <p>{data.first_name} {t('lessons_in')}</p>
            <div className="pl-mb-35">
              {
                data.instruments &&
                data.instruments.map((item) => {
                  return (
                    <LessonsItem key={item._id}>{item.name}</LessonsItem>
                  )
                })
              }
            </div>
          </List>
          <List>
            <p>{data.first_name} {t('offers_levels')}</p>
            <div className="pl-mb-35">
              {
                data.pupil_proficiencies &&
                data.pupil_proficiencies.map((item) => {
                  return (
                    <LessonsItem key={item._id}>{item.level}</LessonsItem>
                  )
                })
              }
            </div>
          </List>
          <List>
            <p>{data.first_name} {t('lessons_place')}</p>
            <div className="pl-mb-35">
              {
                data.class_types &&
                data.class_types.map((item) => {
                  return (
                    <LessonsItem key={item._id}>{item.type}</LessonsItem>
                  )
                })
              }
            </div>
          </List>
          <List>
            <p>{data.first_name} {t('language_skills')}</p>
            <div>
              {
                data.languages &&
                data.languages.map((item) => {
                  return (
                    <LessonsItem key={item._id}>{item.language}</LessonsItem>
                  )
                })
              }
            </div>
          </List>
        </SideContent>
        <CentralContent>
          <TeacherSmallInfo type="flex">
            <RowAnt type="flex" justify="space-between">
              <TeacherName span={12}>
                {data.first_name} {data.last_name.slice(0, 1)}
              </TeacherName>
              <AdditionalInfo span={12}>
                <RatingList>{ratingList(data.rating)}</RatingList>
                { data.class_types.find((item) => item.type === 'location') &&  <LocationImage />}
                { data.class_types.find((item) => item.type === 'web') && <VideoIcon />}
                <Country country={data.location.country}/>
              </AdditionalInfo>
            </RowAnt>
            <RowAnt type="flex" justify="space-between" align="bottom">
              <Col span={12}>
                <Price>
                  {data.hour_rate} € <span>55 {t("time_minutes")}</span>
                </Price>
              </Col>
              <Col span={12} className="pl-text-right">
                <RowAnt type="flex" justify="space-between" align="middle" className="pl-additional-button">
                  <ContactButton
                    class='ContactButton'
                    onClick={addDialog}
                    disabled={user._id === match.params.id}
                    label={t("contact_button")}
                  />
                  <BookingButton
                    class='BookingButton'
                    link={'/booking/' + match.params.id}
                    label={t('book_now_button')}
                    disabled={user._id === match.params.id}
                  />
                </RowAnt>
              </Col>
            </RowAnt>
          </TeacherSmallInfo>
          <TextBlock
            title={t('about_me')}
            text={data.background}
          />
          <TextBlock
            title={t('teaching_philosophy')}
            text={data.teaching_philosophy}
          />
          { data.custom_article &&
            <TextBlock
              title={data.custom_article.title}
              text={data.custom_article.text}
            />
          }
          <TextBlock
            title={t('my_prices')}
            text={data.prices}
          />
          {
            data.videos &&
            <VideoContent type="flex">
              <h3>Videos of {data.first_name}'s lesson</h3>
              {/*<p>{t('video_lessons_text')}</p>*/}
              <VideoSlider {...settings}>
                {
                  data.videos && data.videos.map((item) => {
                    return (
                      <Row type="flex" justify="center">
                        <Container>
                          <VideoBlock dangerouslySetInnerHTML={{__html: item}}/>
                        </Container>
                      </Row>
                    )
                  })
                }
              </VideoSlider>
            </VideoContent>
          }

          <VideoBlock className="comments">
            {
              data.comments && data.comments.map((item) => {
                return (<FeedbackItem data={item}/>)
              })
            }

          </VideoBlock>

        </CentralContent>
      </Container>
    </BackgroundRow>
  );
};

export default withRouter(TeacherContent);
