import React from 'react';
import { useTranslation } from "react-i18next";
import { Row, Icon } from 'antd';
import { Container } from "../../..";
import {
    LeftBlock,
    LeftBlockText,
    RightBlock,
    HowBlock,
    HowBlockIcon,
    HowBlockTitle,
    HowBlockText,
    HowBlockLink,
    RowAnt
} from "./style";
import {
    REGISTER_ROUTE,
    TEACHERSLIST_ROUTE
} from "../../../../constants/routes";

import { ReactComponent as VouteSvg } from "../../../../assets/svg/voute.svg";
import { ReactComponent as HeartSvg } from "../../../../assets/svg/heart.svg";


export function HowItWorks() {
    const {t} = useTranslation();

    return (
        <RowAnt type="flex" justify="start" className="pl-mb-21 pl-mt-21">
            <Row type="flex" align="middle" className="pl-container">
                <LeftBlock span={12}>
                    <LeftBlockText>{t('how_it_title')}</LeftBlockText>
                </LeftBlock>
                <RightBlock span={12} />
            </Row>
            <Container>
                <HowBlock xs={24} md={8}>
                    <HowBlockIcon>
                        <VouteSvg/>
                    </HowBlockIcon>
                    <HowBlockTitle>{t('how_it_works_title1')}</HowBlockTitle>
                    <HowBlockText>{t('how_it_works_text1')}</HowBlockText>
                    <HowBlockLink link={REGISTER_ROUTE} label={t('how_it_works_button')} />
                </HowBlock>
                <HowBlock xs={24} md={8}>
                    <HowBlockIcon>
                        <Icon type="search" />
                    </HowBlockIcon>
                    <HowBlockTitle>{t('how_it_works_title2')}</HowBlockTitle>
                    <HowBlockText>{t('how_it_works_text2')}</HowBlockText>
                    <HowBlockLink link={TEACHERSLIST_ROUTE} label={t('how_it_works_button')} />
                </HowBlock>
                <HowBlock xs={24} md={8}>
                    <HowBlockIcon>
                        <HeartSvg/>
                    </HowBlockIcon>
                    <HowBlockTitle>{t('how_it_works_title3')}</HowBlockTitle>
                    <HowBlockText>{t('how_it_works_text3')}</HowBlockText>
                    <HowBlockLink link={REGISTER_ROUTE} label={t('how_it_works_button')} />
                </HowBlock>
            </Container>
        </RowAnt>
    );
}
