import React from 'react';
import { useTranslation } from "react-i18next";
import {
    BlockText,
    FirstBlock,
    StudentPhoto,
    SecondBlock,
    StudentName,
    OtherInfo,
    ThirdBlock,
    SearchCheckbox,
    CancelCheckbox
} from "./style";
import { Icon, Tooltip } from "antd";



export function TeacherRequestBlock({_id ,datetime, instrument, user_id, studentPhoto, lessonplace, studentlanguage, studentname, onAccept, onDecline}) {
    const { t } = useTranslation();
    return (
        <BlockText type="flex" justify="space-between">
            <FirstBlock>
                <StudentPhoto to={'/user/' + user_id}>
                    <img src={studentPhoto} alt="StudentPhoto" />
                </StudentPhoto>
            </FirstBlock>
            <SecondBlock type="flex" justify="space-between">
                <StudentName>
                    {studentname}
                </StudentName>
                <OtherInfo type="flex" justify="start">
                    <span><b>{t('request_datetime')}:</b> {datetime}</span>
                    <span><b>{t('request_instrument')}:</b> {instrument}</span>
                    <span><b>{t('request_lessonplace')}:</b> {lessonplace}</span>
                    <span><b>{t('request_studentlanguage')}:</b> {studentlanguage}</span>
                </OtherInfo>
            </SecondBlock>
            <ThirdBlock type="flex">
                <Tooltip title={t('request_accept')}>
                    <SearchCheckbox onClick={() => {onAccept(_id)}}/>
                </Tooltip>
                <Tooltip title={t('request_decline')}>
                    <CancelCheckbox onClick={() => {onDecline(_id)}}>
                        <Icon type="close-circle" />
                    </CancelCheckbox>
                </Tooltip>
            </ThirdBlock>
        </BlockText>
    );
}
