import React from 'react';
import { Select } from 'antd';
import { Images } from '../../../assets/images';
import i18n from '../../../i18n';
import { SelectAnt, ModalText, ModalAnt } from "./style";

function handleChange(value) {
    i18n.changeLanguage(value)
}

function currentLang() {
    switch (i18n.language) {
        case 'de':
            return <img src={Images.GermanyFlag} alt='GermanyFlag' />;                
        case 'en':
            return <img src={Images.EnglishFlag} alt='EnglishFlag' />;
        case 'fr':
            return <img src={Images.FranceFlag} alt='FranchFlag' />;
        case 'ua':
            return <img src={Images.FranceFlag} alt='UkraineFlag' />;
        default:
            return <img src={Images.EnglishFlag} alt='EnglishFlag' />;
    }
}

export function LanguageModal(props) {

    const { Option } = Select;
    let isShowModalLang = false;

    function checkLocale() {
        if (localStorage.getItem("siteLang") === null) {
            if (window.navigator.language !== localStorage.getItem("siteLang")) {
                isShowModalLang = true 
            } else {
                isShowModalLang = false
            }
        }
        document.body.classList.remove("site-lang-" + i18n.language)
        document.body.classList.add("site-lang-" + localStorage.getItem("siteLang"))
    }

    function setSiteLang() {
        localStorage.setItem("siteLang", i18n.language)
        window.location.reload()
    }

    return (
        <ModalAnt
            {...checkLocale()}
            visible={isShowModalLang}
            onCancel={() => {}}
            onOk={() => setSiteLang()}
        >
            <ModalText>Your current language on that site is {currentLang()}</ModalText>
            <ModalText>But you can switch to language what you prefer:
                <SelectAnt 
                    defaultValue="english"                 
                    onChange={handleChange}
                    showArrow={false}
                >
                    <Option value="de"><img src={Images.GermanyFlag} alt='GermanyFlag' /></Option>
                    <Option value="en"><img src={Images.EnglishFlag} alt='EnglishFlag' /></Option>
                    <Option value="fr"><img src={Images.FranceFlag} alt='FranchFlag' /></Option>
                    <Option value="ua"><img src={Images.UkraineFlag} alt='UkraineFlag' /></Option>
                </SelectAnt>
            </ModalText>
        </ModalAnt>            
    );
}
