import React from 'react'
import {
  RoleButtonContainer,
  Container,
  // RoleButton,
  RoleText,
  CheckboxButtonCustom,
  Subtitle
} from "./style";
import { withTranslation } from "react-i18next";

function registerRole({t, handleChoosRole}) {

  return (
    <Container>
      <Subtitle>{t('registration_page_subtitle')}</Subtitle>
      <RoleText>{t('registration_question')}</RoleText>
      <RoleButtonContainer>
        <CheckboxButtonCustom label={t('registration_role1')} />
        <CheckboxButtonCustom label={t('registration_role2')} />
        {/* <RoleButton onClick={() => handleChoosRole('pupil')}>{t('registration_role1')}</RoleButton>
        <RoleButton onClick={() => handleChoosRole('teacher')}>{t('registration_role2')}</RoleButton> */}
      </RoleButtonContainer>
    </Container>
  )
}

export default withTranslation()(registerRole);