import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  ModalAnt,
  ModalContainer,
  RedButton,
  GreenButton
} from "./style";
import { useDispatch } from "redux-react-hook";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Icon,
  Tooltip,
  Table,
  Divider
} from "antd";

export function RequestModal(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const onAccept = useCallback((_id) => {
    dispatch({
      type: 'ACCEPT_REQUEST',
      payload: _id
    });
    props.hideModal(!props.isShowModal)
  }, [dispatch, props]);

  const onDecline = useCallback((_id) => {
    dispatch({
      type: 'DECLINE_REQUEST',
      payload: _id
    });
    props.hideModal(!props.isShowModal)
  }, [dispatch, props]);

  const columns = [
    {
      title: t('request_page_start_time'),
      dataIndex: 'start_time',
      key: 'start_time',
      render: item => (
        <span>
          {moment(item).format('LT')}
        </span>
      )
    },
    {
      title: t('request_page_end_time'),
      dataIndex: 'start_time',
      key: 'end_time',
      render: item => (
        <span>
          {moment(item).add(50, 'm').format('LT')}
        </span>
      )
    },
    {
      title: t('request_page_student_name'),
      dataIndex: 'user',
      key: 'user',
      render: item => (
        <span>
          <Link to={'/user/' + item._id}>{item.first_name}</Link>
        </span>
      )
    },
    {
      title: t('request_page_class_type'),
      dataIndex: 'class_type',
      key: 'class_type',
      render: item => (
        <span>
          {item.type}
        </span>
      )
    },
    {
      title: t('request_page_instrument'),
      dataIndex: 'instrument',
      key: 'instrument',
      render: item => (
        <span>
          {item.name}
        </span>
      )
    },

    {
      title: t('request_page_action'),
      key: 'action',
      dataIndex: '_id',
      render: (item) => (
        <span>
          <Tooltip title={t('request_accept')}>
            <GreenButton onClick={() => onAccept(item)}>
              <Icon type="check" />
            </GreenButton>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title={t('request_decline')}>
            <RedButton onClick={() => onDecline(item)}>
              <Icon type="close" />
            </RedButton>
          </Tooltip>
      </span>
      ),
    },
  ];


  return (
    <ModalAnt
      title={new moment(props.date).format('LL').toString()}
      visible={props.isShowModal}
      onOk={() => props.hideModal(!props.isShowModal)}
      onCancel={() => props.hideModal(!props.isShowModal)}
      okText={t("customize_lesson_modal_ok")}
      cancelText={t("customize_lesson_modal_cancel")}
    >
      <ModalContainer>
          <Table columns={columns} dataSource={props.data} />
      </ModalContainer>
    </ModalAnt>
  );
}
